import "./ColorInput.css";


function ColorInput(props) {
  
  return (
    <div className="color-input-container">
      <div style={{"background": props.selectedColor}} className="color-input-standin">
        <input className="color-input-picker" type="color" name={props.name} value={props.selectedColor} onChange={(e) => props.handleInputChange(e)}/>
      </div>
      <input className="color-input-text" type="text" name={props.name} value={props.selectedColor} onChange={(e) => props.handleInputChange(e)}/>

    </div>
  
  );
}

export default ColorInput;
