import "./Global.css";
import "./ConfirmPassword.css";
import "./Login.css";
import logo from "./images/weely_logo.png";
import print from "./images/login_print.png";
import { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

function ConfirmPassword(props) {
  const history = useHistory();
  const [prompt, setPrompt] = useState("");
  const [positive, setPositive] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [tokenValid, setTokenValid] = useState(true);
  const clearPrompt = () => setPrompt("");

  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");

  console.log(token);

  useEffect(() => {
    async function fetchData() {
      const request = {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
        }),
      };

      let response = await fetch(props.url + "/reset/check", request);
      let jsonResponse = await response.json();
      if (!jsonResponse.success) {
        setTokenValid(false);
        setPositive(false);
        setPrompt(
          "Your password reset link is invalid. Please request a new one."
        );
        return;
      }
    }
    fetchData();
  }, [token, props.url]);

  async function confirmPassword() {
    if (password.length === 0) {
      setPositive(false);
      setPrompt("Please enter a new password");
      return;
    }

    if (passwordConfirm.length === 0) {
      setPositive(false);
      setPrompt("Please confirm your new password");
      return;
    }

    if (password !== passwordConfirm) {
      setPositive(false);
      setPrompt("Make sure both passwords match.");
      return;
    }

    const request = {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newPassword: password,
        token: token,
      }),
    };

    let response = await fetch(props.url + "/reset/update", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      setPositive(true);
      setPrompt("Your password was successfully reset.");
      setPasswordConfirm("");
      setPassword("");
      setTimeout(() => {
        history.push("/login");
      }, 2000);
    }
  }

  return (
    <div id="holder">
      <div id="login-navbar">
        <img src={logo} id="login-logo" alt="logo of weely"></img>
        <div id="login-holder">
          {tokenValid ? (
            <h2>Set a new password.</h2>
          ) : (
            <h2>Reset link is invalid</h2>
          )}
          <label>
            New password
            <input
              type="password"
              className="text-input"
              placeholder="•••••••"
              name="password"
              onChange={(e) => {
                clearPrompt();
                setPassword(e.target.value);
              }}
              value={password}
              disabled={tokenValid ? "" : "disabled"}
            ></input>
          </label>
          <label>
            Confirm new password
            <input
              type="password"
              className="text-input"
              placeholder="•••••••"
              name="password"
              onChange={(e) => {
                clearPrompt();
                setPasswordConfirm(e.target.value);
              }}
              disabled={tokenValid ? "" : "disabled"}
              value={passwordConfirm}
            ></input>
          </label>
          <h5 id="login-prompt" className={positive ? "positive" : "negative"}>
            {prompt}
          </h5>
          <button
            className="primary-button"
            id="login"
            onClick={confirmPassword}
            disabled={tokenValid ? "" : "disabled"}
          >
            Confirm change
          </button>

          <h5 className="sublogin-link">
            <Link to="/login">Back to login</Link>
          </h5>
        </div>
      </div>
      <div id="page-holder">
        <img id="login-print" src={print} alt="illustration of user"></img>
      </div>
    </div>
  );
}

export default ConfirmPassword;
