const french = {
    dateFormat: "dd/mm/yy",
    units: {
        day: "jour",
        days: "jours",
        hour: "heure",
        hours: "heures",
        minutes: "minutes"
    },
    links:{
        tc: "https://weely.io/tc-fr/",
        rules: "https://weely.io/rules-fr/"
    },
    texts: {
        login:{
            header: "Se Connecter A Votre Compte",
            inputLabelEmail: "Email",
            inputLabelPassword: "Mot De Passe",
            buttonLabelLogin: "Se Connecter",
            newToWeely: "Première Fois Sur weely.io? ",
            linkSignUp: "Inscription",
            linkForgotPassword: "Oublié Mon Mot De Passe"
        },
        signUp:{
            freeTrial: "Essayer Weely gratuitement",
            withWeely: "AVEC WEELY, OBTENEZ:",
            repeatBusiness: "Affaires répétées",
            userFeedback: "Retours des utilisateurs",
            socialFollowers: "Abonnés sur les réseaux sociaux",
            brandEngagement: "Engagement de la marque",
            mailing: "Abonnés à votre mailing list",
            testimonial: "« Weely révolutionne les règles du jeu, c’est la nouvelle manière de fidéliser vos clients. »",
            testName: "Daniel",
            testTitle: "GROWTH HACKER",
            welcome: "BIENVENUE AU CLUB",
            header1: "Créer un nouveau compte",
            firstName: "Prénom",
            lastName: "Nom",
            businessName: "Nom de l'entreprise",
            email: "Email",
            password: "Mot de passe",
            country: "Pays",
            selectCountry: "Sélectionner Pays",
            language: "Langue",
            selectLanguage: "Sélectionner Langue",
            next: "Suivant",
            back: "Retour",
            createAccount: "Créer un compte",
            alreadyHave: "Vous avez déjà un compte?",
            details: "DÉTAILS",
            goals: "OBJECTIFS",
            confirm: "CONFIRMER",
            header2: "Aidez-nous à améliorer Weely!",
            businessType: "Quel type d'entreprise avez-vous?",
            selectBusiness: "Sélectionner",
            interests: "Que souhaitez-vous obtenir en utilisant Weely?",
            TC1: "J'ai lu et j'accepte les ",
            TC2: "Conditions générales",
            header3: "Compte créé avec succès!",
            confirmationEmail: "Un email de confirmation a été envoyé à :",
            openLink: "Veuillez ouvrir le lien pour valider votre compte et commencer à utiliser Weely",
            backToLogin: "Retour à la connexion",
            didntReceive1: "Vous n'avez pas reçu d'e-mail ? ",
            didntReceive2: "Renvoyer",
        
            errFirstName: "Veuillez saisir le prénom",
            errLastName: "Veuillez saisir le nom de famille",
            errBusinessName: "Veuillez saisir le nom de l'entreprise",
            errEmail: "Veuillez saisir l'email",
            errPassword: "Veuillez saisir le mot de passe",
            errPassword6char: "Le mot de passe doit comporter au moins 6 caractères",
            errCountry: "Veuillez sélectionner le pays",
            errLanguage: "Veuillez sélectionner la langue",
            errBusinessType: "Veuillez saisir le type d'entreprise",
            errTag: "Veuillez sélectionner au moins une étiquette d'intérêt",
            errTCs: "Veuillez accepter les conditions générales",
        
            restaurant: "Restaurant",
            cafe: "Café",
            cafeteria: "Cafétéria",
            store: "Magasin",
            event: "Événement",
            otherBusiness: "Autre",
        
            returningCustomers: "Clients fidèles",
            increasedRevenue: "Augmentation du chiffre d'affaires",
            engageCustomers: "Engager avec les clients",
            googleReviews: "Avis sur Google",
            instaFollowers: "Abonnés sur Instagram",
            fbFollowers: "Abonnés sur Facebook",
            mailingList: "Liste de diffusion",
            customerFeedback: "Retours des clients",
            otherInterest: "Autre"
        },
        nav: {
            wheels: "Roues",
            account: "Compte",
            support: "Support",
            logout: "Se Déconnecter"
        },
        wheels:{
            header: "Tes Roues",
            subHeader: "Analyse et configure tes roues.",
            more: "Plus",
            lastWeek: "Dernière Semaine",
            spins: "Spins de la roue",
            prizes: "Prix réclamés"
        },
        wheel:{
            nav: {
                analytics: "Statistiques",
                settings: "Paramètres",
                qr: "QR Code",
                mailing: "Vos Abonnés"
            },
            analytics: {
                horizonToday: "Aujourd'hui",
                horizon7: "Derniers 7 Jours",
                horizon30: "Derniers 30 Jours",
                horizonAll: "Total",
                overview: "Aperçu Général",
                overviewSpins: "Spins",
                overviewPrizesWon: "Prix Gagnés",
                overviewPrizesClaimed: "Prix Réclamés",
                overviewSubscribers: "Nouveaux Abonnés",
                actions: "Nombre d'actions ",
                prizesDetail: "Aperçu des Prix",
                prizeName: "Prix",
                wins: "Gagné",
                claims: "Réclamé",
                conversion: "Conversion",
                questions: "Sondages",
                numberOfAnswers: "Nombre de Réponses:",
                averageRating: "Note Moyenne:",
                comments: "Commentaires",
                oops: "Oups...",
                noActions: "Pas de données sur les actions pour les dates sélectionnées",
                noQuestions: "Pas de données sur vos sondages pour les dates sélectionnées"
            },
            settings: {
                saveSettings: "Enregistrer",
                simulator: "Simulateur de Roue",
                tryYourLuck: "Tentez votre chance, et gagnez un prix de chez ",
                lost: "Perdu",
                spinWheel: "Tourner la roue",
                colorSettings: "Paramètres Couleurs",
                dialA1: "Cadran A1",
                dialA2: "Cadran A2",
                dialB1: "Cadran B1",
                dialB2: "Cadran B2",
                background: "Arrière-Plan",
                button: "Bouton",
                fontA: "Texte A",
                fontB: "Texte B",
                header: "Titre",
                name: "Nom Roue",
                wheelRim: "Cadre Roue",
                prizeSettings: "Paramètres Couleurs",
                wheelPrizes: "Ma Roue",
                existingPrizes: "Prix Existants",
                newPrize: "Nouveau Prix",
                addPrize: "Ajouter",
                prizeName: "Nom Du Prix",
                prizeDescription: "Description Du Prix",
                promoCode: "Code Promo",
                error4char: "Veuillez entrer un nom de plus de 3 lettres",
                error40char: "Veuillez entrer un nom de moins de 40 lettres",
                error10char: "Veuillez entrer une description de plus de 10 lettres",
                createPrize: "Créer Prix",
                promoType: "Type Code Promo",
                promoMulti: "Réutilisable",
                promoSingle: "Usage Unique",
                promoMultiLabel: "Code Promo",
                promoSingleLabel: "Codes Promos au Format CSV (ex. promo1, promo2, promo3, ...)",
                addCode: "Ajouter Code",
                cancel: "Annuler",
                deletePrizeConfirm: "Supprimer un prix est une action permanente. Êtes-vous sûr de vouloir supprimer le prix?",
                deleteConfirm: "Confirmer",
                deleteCancel: "Annuler",
                questionSettings: "Paramètres Questions",
                wheelQuestions: "Ma Roue",
                existingQuestions: "Questions Existantes",
                smiley: "Smiley",
                tags: "Tags",
                addQuestion: "Ajouter",
                newQuestion: "Nouvelle Question",
                questionType: "Type de Question",
                selectType: "Sélectionner",
                questionText: "Texte Question",
                questionTags: "Tags Question",
                addTag: "Ajouter",
                removeTag: "Remove Tag",
                createQuestion: "Créer Question",
                errQType: "Veuillez séléctionner un type",
                errQValid: "Question doit faire au moins 10 lettres",
                errQTags: "Veuillez ajouter au moins deux tags",
                deleteQuestionConfirm: "Supprimer une question est une action permanente. Êtes-vous sûr de vouloir supprimer la question?",
                actionSettings: "Paramètres Actions",
                insta: "FOLLOWERS INSTAGRAM",
                facebook: "ABONNÉS FACEBOOK",
                google: "GOOGLE REVIEWS",
                tiktok: "FOLLOWERS TIKTOK",
                customAction: "ACTION CUSTOM",
                instaUrl: "URL INSTAGRAM:",
                facebookUrl: "URL FACEBOOK:",
                googleUrl: "URL GOOGLE REVIEWS:",
                tiktokUrl: "URL TIKTOK:",
                customText: "TEXTE BOUTON CUSTOM:",
                customUrl: "URL CUSTOM:",
                generalSettings: "Paramètres Généraux",
                wheelName: "Nom roue",
                wheelLogo: "Logo roue",
                language: "Langue roue",
                timeBetween: "Temps min. pour rejouer (jours)",
                timeToClaim: "Temps min. pour réclamer prix (jours)",
                timeToExpire: "Temps max. pour réclamer prix (jours)",
                disableWheel: "Désactiver roue",
                deleteWheel: "Supprimer",
                days: "jours",
                deleteWheelButton: "Supprimer roue",
                deletePromptHeader: "Supprimer cette roue?",
                deleteText1: "La suppression d'une roue est irréversible. Si vous êtes sûr de votre choix, veuillez écrire ",
                deleteText2: " et appuyer sur Supprimer pour la supprimer.",
                deleteWheelConfirm: "Supprimer" 
            },
            qr: {
                header: "Personalisation QR",
                colorA: "Couleur Motif A",
                colorB: "Couleur Motif B",
                colorC: "Couleur Motif C",
                colorD: "Couleur Motif D",
                background: "Arrière-Plan",
                colorLogo: "Couleur Logo",
                colorNumbers: "Couleur Chiffres",
                colorLabels: "Couleur Textes",
                language: "Langue",
                disable: "Cacher Motifs",
                hideLogo: "Cacher Logo",
                save: "Sauvegarder",
                reset: "Réinitialiser",
                downloadHeader: "Télécharger Code QR",
                qrOnly: "QR Uniquement",
                fullSticker: "Autocollant Complet",
                qrText: "Télécharger uniquement le code QR. Peut-être utiliser sur des prospectifs et autres formats.",
                stickerText: "Télcharger l'autocollant complet. Peut-être utiliser sur des prospectifs et autres formats.",
                download: "Télécharger" 
            },
            mailing: {
                header: "Exporter Votre Mailing List",
                paragraph: "Appuyer sur le bouton pour télécharger votre mailing list. Le document est au format CSV et est compatible avec Microsoft Excel et la plupart des CRMs.",
                button: "Télécharger"        
            }
        },
        account: {
            header: "Paramètres du compte",
            planDetails: "Détails de l'abonnement",
            monthlySpins: "Spins mensuels",
            currentPlan: "Abonnement actuel",
            maxSpins: "Max. Spins",
            maxWheels: "Max Roues",
            planStatus: "Status de l'abonnement",
            nextBilling: "Prochaine facturation le",
            validTo: "Valide jusqu'au",
            changePlan: "Upgrader",
            cancelPlan: "Annuler l'abo",
            accountDetails: "Votre profil",
            firstName: "Prénom",
            lastName: "Nom",
            businessName: "Nom du commerce",
            email: "E-mail",
            country: "Pays",
            selectCountry: "Sélectionner pays",
            language: "Langue",
            selectLanguage: "Sélectionner langue",
            saveChanges: "Sauvegarder",
            cancel: "Annuler",
            receipts: "Reçus",
            cancelHeader: "Annuler votre abonnement",
            cancelPrompt: "Êtes-vous sûr de vouloir annuler votre abonnement?",
            planSuccessfullyCancelled: "Abonnement Annulé",
            planSuccessfullyCancelledText: "Votre abonnement a été annulé. Votre compte et roues resteront actifs jusqu'au ",
            confirm: "Confirmer",
            back: "Retour",
            tryAgain: "Réessayer",
        },
        upgrade: {
            header: "Changer D'Abonnement",
            text: "Modifier votre abonnement. Avec la facturation annuelle vous économisez 30%",
            trialText: "300 spins gratuits pour commencer avec Weely !",
            growthText: "Idéal pour les petites entreprises qui veulent l'essentiel.",
            boostText: "Parfait pour les entreprises en croissance.",
            currentPlan: "Abo Actuel",
            upgrade: "Upgrader",
            month: "mois",
            monthlyBilling: "Facturation mensuelle",
            annualBilling: "Facturation annuelle",
            trialPerk1: "1 roue",
            trialPerk2: "300 spins",
            trialPerk3: "2 prix par roue",
            trialPerk4: "Aucune carte de crédit requise",
            trialPerk5: "Toutes les fonctionnalités de Growth",
            trialPerk6: "Accès hebdomadaire à l'Académie",
            growthPerk1: "1 roue",
            growthPerk2: "1000 spins mensuels",
            growthPerk3: "4 prix par roue",
            growthPerk4: "Tableau de bord d'analyse",
            growthPerk5: "Générateur d'autocollants QR",
            growthPerk6: "Abonnés au courrier électronique opt-in",
            growthPerk7: "Action relative aux avis sur Google",
            growthPerk8: "Suivez l'action sur Instagram",
            growthPerk9: "Suivez l'action sur TikTok",
            growthPerk10: "Action « J'aime » sur Facebook",
            growthPerk11: "Interrogations relatives à l'enquête",
            growthPerk12: "Prix des chèques-cadeaux",
            growthPerk13: "1 wheel",
            boostPerk1: "2 roues",
            boostPerk2: "spins tours mensuels",
            boostPerk3: "4 prix par roue",
            boostPerk4: "Personnalisation complète de la roue",
            boostPerk5: "Tableau de bord d'analyse",
            boostPerk6: "Générateur d'autocollants QR",
            boostPerk7: "Abonnés au courrier électronique opt-in",
            boostPerk8: "Action relative aux avis sur Google",
            boostPerk9: "Suivez l'action sur Instagram",
            boostPerk10: "Suivez l'action sur TikTok",
            boostPerk11: "Action « J'aime » sur Facebook",
            boostPerk12: "Interrogations relatives à l'enquête",
            boostPerk13: "Prix des chèques-cadeaux",
            boostPerk14: "Rapports de performance",
            boostPerk15: "Assistance à la clientèle",
            boostPerk16: "Action d'URL personnalisée",
            boostPerk17: "Étude de cas mensuels",
            boostPerk18 : "Rapports de performance",
            updatePlan: "Modifier Abonnement",
            updatePrompt: "Êtes-vous sûr de vouloir modifier votre à abonnement à ",
            confirm: "Confirmer",
            cancel: "Annuler",
            planUpdated: "Abonnement Modifié!",
            planUpdatedText: "Votre abonnement à été modifié à ",
            back: "Retour"
        },
        newWheel: {
            header: "Créer Nouvelle Roue",
            name: "NOM",
            nameHeader: "Nom de la roue",
            nameText: "Cela apparaîtra sur votre roue et dans les e-mails que les utilisateurs reçoivent lorsqu'ils gagnent un prix. Vous pouvez le modifier à tout moment",
            placeholder: "Ma Nouvelle Roue",
            theme: "THÈME",
            themeHeader: "Sélectionner un thème de départ",
            themeText: "Toutes les couleurs peuvent être entièrement personnalisées dans la section des paramètres de la roue. Cette fonctionnalité n'est disponible que pour les abos Weely Growth",
            createWheel: "Créer Roue"
        }
    }
}

export default french;