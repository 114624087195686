const englishEU = {
    dateFormat: "dd/mm/yy",
    units: {
        day: "day",
        days: "days",
        hour: "hour",
        hours: "hours",
        minutes: "minutes"
    },
    links:{
        tc: "https://weely.io/tc-en/",
        rules: "https://weely.io/rules-en/"
    },
    texts: {
        login:{
            header: "Login to my account.",
            inputLabelEmail: "Email",
            inputLabelPassword: "Password",
            buttonLabelLogin: "Login",
            newToWeely: "New To Weely? ",
            linkSignUp: "Sign Up",
            linkForgotPassword: "Forgot Password"
        },
        signUp:{
            freeTrial: "Your free trial starts now",
            withWeely: "WITH WEELY START GETTING:",
            repeatBusiness: "Repeat business",
            userFeedback: "User feedback",
            socialFollowers: "Social media followers",
            brandEngagement: "Brand engagement",
            mailing: "Mailing list subscriptions",
            testimonial: "“ Weely is a game changer, it's the new way to engage with your customers. “",
            testName: "Daniel",
            testTitle: "GROWTH HACKER",
            welcome: "WELCOME TO THE CLUB",
            header1: "Create New Account",
            firstName: "First Name",
            lastName: "Last Name",
            businessName: "Business Name",
            email: "Email",
            password: "Password",
            country: "Country",
            selectCountry: "Select Country",
            language: "Language",
            selectLanguage: "Select Language",
            next: "Next",
            back: "Back",
            createAccount: "Create Account",
            alreadyHave: "Already have an account?",
            details: "DETAILS",
            goals: "GOALS",
            confirm: "CONFIRM",
            header2: "Help Us Improve Weely!",
            businessType: "What kind of business are you?",
            selectBusiness: "Select Business",
            interests: "What would you like to get out of using Weely?",
            TC1: "I have read and accept the ",
            TC2: "Terms and Conditions",
            header3: "Account Successfully Created!",
            confirmationEmail: "A confirmation email was sent to:",
            openLink: "Please open the link to validate your account and start using Weely",
            backToLogin: "Back to Login",
            didntReceive1: "Didn't receive an email? ",
            didntReceive2: "Send Again",

            errFirstName: "Please enter first name",
            errLastName: "Please enter last name",
            errBusinessName: "Please enter business name",
            errEmail: "Please enter email",
            errPassword: "Please enter password",
            errPassword6char: "Password must be at least 6 characters",
            errCountry: "Please select country",
            errLanguage: "Please select language",
            errBusinessType: "Please enter business type",
            errTag: "Please select at least one interest tag",
            errTCs: "Please accept the terms and conditions",

            restaurant: "Restaurant",
            cafe: "Café",
            cafeteria: "Cafeteria",
            store: "Store",
            event: "Event",
            otherBusiness: "Other",

            returningCustomers: "Returning Customers",
            increasedRevenue: "Increased Revenue",
            engageCustomers: "Engage with Customers",
            googleReviews: "Google Reviews",
            instaFollowers: "Followers on Instagram",
            fbFollowers: "Followers on Facebook",
            mailingList: "Mailing List",
            customerFeedback: "Feedback from Customers",
            otherInterest: "Other"
        },
        nav: {
            wheels: "Wheels",
            account: "Account",
            support: "Support",
            logout: "Logout"
        },
        wheels:{
            header: "Wheels",
            subHeader: "Select a wheel to configure and analyse",
            more: "More",
            lastWeek: "Last week",
            spins: "Wheel spins",
            prizes: "Claimed prizes"
        },
        wheel:{
            nav: {
                analytics: "Analytics",
                settings: "Wheel Settings",
                qr: "QR Code",
                mailing: "Mailing List"
            },
            analytics: {
                horizonToday: "Today",
                horizon7: "Last 7 Days",
                horizon30: "Last 30 Days",
                horizonAll: "All Time",
                overview: "General Overview",
                overviewSpins: "Spins",
                overviewPrizesWon: "Prizes Won",
                overviewPrizesClaimed: "Prizes Claimed",
                overviewSubscribers: "Mailing Contacts",
                actions: "Actions Breakdown",
                prizesDetail: "Prizes Detail",
                prizeName: "Prize Name",
                wins: "Wins",
                claims: "Claims",
                conversion: "Conversion",
                questions: "Questions",
                numberOfAnswers: "Number of Answers:",
                averageRating: "Average Rating:",
                comments: "Comments",
                oops: "Oops...",
                noActions: "No action data to display for given period",
                noQuestions: "No question data retrieved for given period"
            },
            settings: {
                saveSettings: "Save Settings",
                simulator: "Wheel Simulator",
                tryYourLuck: "Try your luck, spin the wheel, and win a prize from ",
                lost: "Lost",
                spinWheel: "Spin Wheel",
                colorSettings: "Color Settings",
                dialA1: "Dial A1",
                dialA2: "Dial A2",
                dialB1: "Dial B1",
                dialB2: "Dial B2",
                background: "Background",
                button: "Button",
                fontA: "Font A",
                fontB: "Font B",
                header: "Header",
                name: "Wheel Name",
                wheelRim: "Wheel Rim",
                prizeSettings: "Prize Settings",
                wheelPrizes: "Wheel Prizes",
                existingPrizes: "Existing Prizes",
                newPrize: "New Prize",
                addPrize: "Add Prize",
                prizeName: "Prize Name",
                prizeDescription: "Prize Description",
                promoCode: "Promo Code",
                error4char: "Name must be longer than 3 characters",
                error40char: "Name must be shorter than 40 characters",
                error10char: "Description must be longer than 10 characters",
                createPrize: "Create New Prize",
                promoType: "Promo Code Type",
                promoMulti: "Multi-Use",
                promoSingle: "Single-Use",
                promoMultiLabel: "Promo Code Value",
                promoSingleLabel: "Promo Codes in CSV format (eg: promo1,promo2,promo3, ...)",
                addCode: "Add Code",
                cancel: "Cancel",
                deletePrizeConfirm: "Deleting a prize is permanent. Are you sure you wish to proceed?",
                deleteConfirm: "Confirm",
                deleteCancel: "Cancel",
                questionSettings: "Question Settings",
                wheelQuestions: "Wheel Questions",
                existingQuestions: "Existing Questions",
                smiley: "Smiley",
                tags: "Tags",
                addQuestion: "Add",
                newQuestion: "New Question",
                questionType: "Question Type",
                selectType: "Select",
                questionText: "Question Text",
                questionTags: "Question Tags",
                addTag: "Add",
                createQuestion: "Create New Question",
                errQType: "Please select a question type",
                errQValid: "Question needs to be at least 10 characters",
                errQTags: "Please enter at least two answer tags",
                deleteQuestionConfirm: "Deleting a question is permanent. Are you sure you wish to proceed?",
                actionSettings: "Action Settings",
                insta: "INSTAGRAM FOLLOWERS",
                facebook: "FACEBOOK SUBSCRIBERS",
                google: "GOOGLE REVIEWS",
                tiktok: "TIK TOK FOLLOWERS",
                customAction: "CUSTOM ACTION",
                instaUrl: "INSTAGRAM URL:",
                facebookUrl: "FACEBOOK URL:",
                googleUrl: "GOOGLE REVIEWS URL:",
                tiktokUrl: "TIKTOK URL:",
                customText: "CUSTOM BUTTON TEXT:",
                customUrl: "CUSTOM URL:",
                generalSettings: "General Settings",
                wheelName: "Wheel name",
                wheelLogo: "Wheel logo",
                language: "Wheel language",
                timeBetween: "Min. time to play again (days)",
                timeToClaim: "Min. time to claim a prize (days)",
                timeToExpire: "Max. time to claim a prize (days)",
                disableWheel: "Temporarily disable wheel",
                deleteWheel: "Permanently delete wheel",
                days: "days",
                deleteWheelButton: "Delete Wheel",
                deletePromptHeader: "Delete Wheel?",
                deleteText1: "Deleting a wheel is permanent. If you wish to proceed, please type in ",
                deleteText2: " and press delete to proceed.",
                deleteWheelConfirm: "Delete" 
            },
            qr: {
                header: "QR Customization",
                colorA: "Pattern Color A",
                colorB: "Pattern Color B",
                colorC: "Pattern Color C",
                colorD: "Pattern Color D",
                background: "Background Color",
                colorLogo: "Logo Color",
                colorNumbers: "Numbers Color",
                colorLabels: "Labels Color",
                language: "Language",
                disable: "Disable Patterns",
                hideLogo: "Hide Logo",
                save: "Save",
                reset: "Reset",
                downloadHeader: "Download QR Code",
                qrOnly: "QR Only",
                fullSticker: "Full Sticker",
                qrText: "Download a small format version of your QR code. Can be used for flyers stickers and more.",
                stickerText: "Download a large format version of your QR code and sticker. Can be used for flyers stickers and more.",
                download: "Download" 
            },
            mailing: {
                header: "Export Mailing List",
                paragraph: "Click the following button to download all users subscribed to this wheel’s mailing list. The document is in CSV format and is compatible with Microsoft Excel and most CRMs.",
                button: "Download List"        
            }
        },
        account: {
            header: "Account Settings",
            planDetails: "Plan Details",
            monthlySpins: "Monthly Spins",
            currentPlan: "Current Plan",
            maxSpins: "Max. Spins",
            maxWheels: "Max Wheels",
            planStatus: "Plan Status",
            nextBilling: "Next Billing On",
            validTo: "Valid To",
            changePlan: "Upgrade",
            cancelPlan: "Cancel Plan",
            accountDetails: "Account Details",
            firstName: "First Name",
            lastName: "Last Name",
            businessName: "Business Name",
            email: "Email",
            country: "Country",
            selectCountry: "Select Country",
            language: "Language",
            selectLanguage: "Select Language",
            saveChanges: "Save Changes",
            cancel: "Cancel",
            receipts: "Receipts",
            cancelHeader: "Cancel Plan",
            cancelPrompt: "Are you sure you wish to cancel your plan?",
            planSuccessfullyCancelled: "Plan Successfully Cancelled",
            planSuccessfullyCancelledText: "Your plan was successfuly cancelled. Your account and wheels will remain active until the ",
            confirm: "Confirm",
            back: "Back",
            tryAgain: "Try Again"
        },
        upgrade: {
            header: "Change Plan",
            text: "Upgrade or downgrade your plan. If you choose annual billing, you will save 30%",
            trialText: "300 free spins to get you started with Weely!",
            growthText: "Best for small businesses that want the essentials.",
            boostText: "Perfect for growing businesses with ambitions to match. Perfect for growing businesses with ambitions to match",
            currentPlan: "Current Plan",
            upgrade: "Upgrade",
            month: "month",
            monthlyBilling: "Monthly Billing",
            annualBilling: "Annual Billing",
            trialPerk1: "1 wheel",
            trialPerk2: "300 spins",
            trialPerk3: "2 prizes per wheel",
            trialPerk4: "No credit card needed",
            trialPerk5: "All features in Growth",
            trialPerk6: "Weely Academy access",
            growthPerk1: "1 wheel",
            growthPerk2: "1000 monthly spins",
            growthPerk3: "4 prizes per wheel",
            growthPerk4: "Analytics dashboard",
            growthPerk5: "QR sticker generator",
            growthPerk6: "Opt-in mail subscribers",
            growthPerk7: "Google review action",
            growthPerk8: "Instagram follow action",
            growthPerk9: "TikTok follow action",
            growthPerk10: "Facebook like action",
            growthPerk11: "Survey questions action",
            growthPerk12: "Gift voucher prizes",
            growthPerk13: "Customer support",
            boostPerk1: "2 wheels",
            boostPerk2: "2500 monthly spins",
            boostPerk3: "4 prizes per wheel",
            boostPerk4: "Full wheel customization",
            boostPerk5: "Analytics dashboard",
            boostPerk6: "QR sticker generator",
            boostPerk7: "Opt-in mail subscribers",
            boostPerk8: "Google review action",
            boostPerk9: "Instagram follow action",
            boostPerk10: "TikTok follow action",
            boostPerk11: "Facebook like action",
            boostPerk12: "Survey questions action",
            boostPerk13: "Gift voucher prizes",
            boostPerk14: "Performance reports",
            boostPerk15: "Customer support",
            boostPerk16: "Custom URL action",
            boostPerk17: "Monthly case studies",
            boostPerk18 : "Performance reports",
            updatePlan: "Update Plan",
            updatePrompt: "Are you sure you wish to update your plan to ",
            confirm: "Confirm",
            cancel: "Cancel",
            planUpdated: "Plan Successfully Updated!",
            planUpdatedText: "Your plan was successfully updated to ",
            back: "Back"
        },
        newWheel: {
            header: "Create New Wheel",
            name: "NAME",
            nameHeader: "Select wheel name",
            nameText: "This will appear on your wheel, and on the emails users receive when they win a prize. You can change this at any time",
            placeholder: "My New Wheel",
            theme: "THEME",
            themeHeader: "Select a starting template",
            themeText: "All colors can be fully customized in the wheel settings section. This feature is only available to Weely Growth plans",
            createWheel: "Create Wheel"
        }
    }
}

export default englishEU;