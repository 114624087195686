import React from "react";
import ImageUploading from "react-images-uploading";
import uploadLogo from "../images/file-upload.png";

import "./UploadLogo.css";

function UploadLogo(props) {
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = async(imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
    let formData = new FormData();

    if(imageList.length > 0){
      formData.append("isUpdating", true);
      let image = imageList[0];
      formData.append("logo", image.file);


      const request = {
        method: "put",
        headers: {
          Authorization: "Bearer " + props.user.token        
        },
        body: formData,
      };
      
      let response = await fetch(props.url + "/wheel/" + props.wheel + "/logo", request);
      let jsonResponse = await response.json();
      if (jsonResponse.success) {
        // this.getWheelSettings();
        // this.setState({
        //   updated: false,
        // });
      }
    }else{
      formData.append("isUpdating", false);
      const request = {
        method: "put",
        headers: {
          Authorization: "Bearer " + props.user.token        
        },
        body: formData,
      };
      
      let response = await fetch(props.url + "/wheel/" + props.wheel + "/logo", request);
      let jsonResponse = await response.json();
      if (jsonResponse.success) {
        // this.getWheelSettings();
        // this.setState({
        //   updated: false,
        // });
      }
    }
    
  };

  const imageOrPlaceholder = (props, imageList,isDragging,onImageUpload,dragProps) => {
    // console.log(props.imageUrl)
    if(props.imageUrl !== ""){
      return(
        <div className="image-item">
                <img src={props.imageUrl} alt="logo" className="logo-image-thumbnail" />
      </div>
      )
    } else if(imageList.length>0){
      return(
        <div className="image-item">
          <img src={imageList[0].data_url} alt="logo" className="logo-image-thumbnail"/>
        </div>
      )
    } else {
      return(
        <div
          className="upload-button"
          style={isDragging ? { color: "red" } : null}
          onClick={onImageUpload}
          {...dragProps}
          >
            <img src={uploadLogo} className="upload-logo-icon" alt="upload-logo"/>
            <div>Drag file or click to select</div>
        </div>
      )

    }
  }



  return (
    <div className="upload-container">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            {imageOrPlaceholder(props, imageList,isDragging,onImageUpload,dragProps)}
            {imageList.length>0 || props.imageUrl !== ""  ?
              <div className="image-item__btn-wrapper">
                    <div className="image-remove-button" onClick={()=> {
                      onImageRemove();
                      props.removeLogo();
                    }}>x</div>
              </div>
            : ""}
            
          </div>
        )}
      </ImageUploading>
    </div>
  );
}

export default UploadLogo;