import "./Global.css";
import "./ResetPassword.css";
import "./VerifyEmail.css";
import logo from "./images/weely_logo.png";
import print from "./images/login_print.png";
import { useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";

let search = window.location.search;
let params = new URLSearchParams(search);
const token = params.get('token');

function VerifyEmail(props) {
  const [positive, setPositive] = useState(false);




  const verifyToken = async(token) => {
    const request = {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
      }),
    };

    let response = await fetch(props.url + "/verify/check", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log("Success");
      setPositive(true);
      return;
    } else {
      alert("OOPS");
      console.log(jsonResponse.message)
    }
  }

  useLayoutEffect(() => {
    verifyToken(token);
    // Your code here
  });

  return (
    <div id="holder">
      <div id="login-navbar">
        <img src={logo} id="login-logo" alt="logo of weely"></img>
        <div id="login-holder">
          {positive ? 
              <h2>Your account has been verified!</h2>
            :
              <h2>Oops. Something went wrong ...</h2>
          }
          
          <Link to="/login">
            <button className="primary-button verify-button" id="login" onClick={console.log("Lol")}>
              Back to login
            </button>
          </Link>

         

        </div>
      </div>
      <div id="page-holder">
        <img id="login-print" src={print} alt="illustration of user"></img>
      </div>
    </div>
  );
}

export default VerifyEmail;
