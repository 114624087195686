import { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import countries from "./Countries";
import NewAccountProgress from "./components/NewAccountProgress";
import weelyLogo from "./images/weelyLogoWhite.svg";
import illustrationRegister1 from "./images/illustrationRegister1.png";
import illustrationRegister2 from "./images/illustrationRegister2.png";

import registrationComplete from "./images/registrationComplete.png";

import eyePassword1 from "./images/eyePassword1.png";
import eyePassword2 from "./images/eyePassword2.png";



import pattern1 from "./images/createAccountPattern1.png";
import pattern2 from "./images/pattern2.png";
import pattern3 from "./images/pattern3.png";
import pattern4 from "./images/pattern4.png";
import pattern5 from "./images/pattern5.png";
import pattern6 from "./images/pattern6.png";
import pattern7 from "./images/pattern7.png";
import pattern8 from "./images/pattern8.png";
import pattern9 from "./images/pattern9.png";
import pattern10 from "./images/pattern10.png";

import witness from "./images/witness.jpg";

import dunkinLogo from "./images/dunkin.png";
import holycowLogo from "./images/holycow.png";
import impressionlogo from "./images/impression.png";

import mandala from "./images/mandala.svg";

import * as languages from "./languages";




import "./Global.css";
import "./CreateAccount.css";
// import { Link } from "react-router-dom";

class CreateAccount extends Component {
  constructor(props){
    console.log(languages[props.language])
    super(props);
    this.state = {
      stage: 0,
      firstName: "",
      lastName: "",
      businessName: "",
      phone: "", 
      email: "",
      password: "",
      country: "",
      language: "",
      businessType: "",
      interestsTags: [],
      TCAccepted: false,
      errorPrompt: "",
      showPassword: false,
      passwordFocus: false,
      requestPending: false,
      texts: languages[props.language].texts.signUp
    }
   
    // const wheelId = params.get('id');
  }

  componentDidMount(){
    let search = window.location.search;
    let params = new URLSearchParams(search);
    console.log(params.size)
    if(params.size > 0){
      let lang = params.get('lang');
      console.log(lang);
      this.props.setLanguage(lang);
      this.setState({
        texts: languages[lang].texts.signUp
      })
    }
    this.animateIn();
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    if(name === "language"){
      console.log(this.props.language)
      console.log(value);
      this.props.setLanguage(value)
      console.log(this.props.language)
      this.setState({
        texts: languages[value].texts.signUp
      })
    }
  }

  checkTC = () => {
    this.setState({
      TCAccepted: !this.state.TCAccepted
    })
  }

  animateIn = () => {
    setTimeout(() =>{
      this.setState({
        stage: 1
      })
    }, 400)
    setTimeout(() =>{
      this.setState({
        stage: 2
      })
    }, 1000)
  }

  next = () => {
    if(this.state.firstName === ""){
      this.logPrompt(this.state.texts.errFirstName);
      return;
    }
    if(this.state.lastName === ""){
      this.logPrompt(this.state.texts.errLastName);
      return;
    }
    if(this.state.businessName === ""){
      this.logPrompt(this.state.texts.errBusinessName);
      return;
    }
    if(this.state.email === ""){
      this.logPrompt(this.state.texts.errEmail);
      return;
    }
    if(this.state.password === ""){
      this.logPrompt(this.state.texts.errPassword);
      return;
    }
    if(this.state.password.length < 6){
      this.logPrompt(this.state.texts.errPassword6char);
      return;
    }
    if(this.state.country === ""){
      this.logPrompt(this.state.texts.errCountry);
      return;
    }
    if(this.state.language === ""){
      this.logPrompt(this.state.texts.errLanguage);
      return;
    }
    this.setState({
      stage:3
    })
    setTimeout(() =>{
      this.setState({
        stage: 4
      })
    }, 1000)
    setTimeout(() =>{
      this.setState({
        stage: 5
      })
    }, 1500)

  }

  register = async() => {
    if(this.state.requestPending){
      return;
    }
    if(this.state.businessType === ""){
      this.logPrompt(this.state.texts.errBusinessType);
      return;
    }
    if(this.state.interestsTags.length < 1){
      this.logPrompt(this.state.texts.errTag);
      return;
    }
    if(!this.state.TCAccepted){
      this.logPrompt(this.state.texts.errTCs);
      return;
    }

    const request = {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        name: this.state.businessName,
        email: this.state.email,
        password: this.state.password,
        country: this.state.country,
        language: this.state.language,
        businessType: this.state.businessType,
        interests: this.state.interestsTags
      }),
    };
    this.setState({requestPending: true})
    let response = await fetch(this.props.url + "/client", request);
    let jsonResponse = await response.json();
    // let jsonResponse = {success: true}
    if (jsonResponse.success) {
      this.setState({requestPending: false})
      this.setState({
        stage: 6
      })
      setTimeout(() =>{
        this.setState({
          stage: 7
        })
      }, 1000)
      setTimeout(() =>{
        this.setState({
          stage: 8
        })
      }, 1100)
      this.props.sendVerificationEmail(this.state.email);
    } else{ console.log(jsonResponse);
      this.setState({requestPending: false})
      this.logPrompt("Oops, something went wrong: " + jsonResponse.message)
    } 
  }

  // const sendVerification = async() => {
  //   if(verificationEmailSent){
  //     return;
  //   }
  //   props.sendVerificationEmail(email);
  //   setVerificationEmailSent(true);
  //   setNeedToVerify(false);
  //   setPrompt("An email was sent to " + email);
  // }

  back = () => {
    this.setState({
      stage:6
    })
    setTimeout(() =>{
      this.setState({
        stage: 1
      })
      
      this.animateIn();

    }, 800)
  }

  backToLogin = () => {
    this.props.history.push('/login');
    // useHistory.push("/login");
  }

  logPrompt = (prompt) => {
    this.setState({errorPrompt: prompt})
    setTimeout(() => {
      this.setState({errorPrompt: ""})
    }, 2000)
  }

  selectTag = (tag) => {
    let hasTag = false;
    let selectedTags = this.state.interestsTags;
    this.state.interestsTags.forEach((selectedTag, i) => {
      if(tag === selectedTag){
        hasTag = true;
        selectedTags.splice(i, 1);
      }
    })
    if(!hasTag){
      selectedTags.push(tag);
    }
    this.setState({interestsTags: selectedTags})
  }
  
  renderTags = () => {
    const tags = ["Returning Customers", "Increased Revenue", "Engage with Customers", "Reviews on Google", "Followers on Instagram", "Followers on Facebook", "Mailing List", "Feedback from Customers", "Other"];
    return(
      <div className="tag-container">
        { tags.map((tag) =>{
          let classTag = "tag";
          this.state.interestsTags.forEach((selectedTag) => {
            if(selectedTag === tag){
              classTag = "tag tag-selected";
            }
          })
          return <div onClick={() => {this.selectTag(tag)}} className={classTag}>{tag}</div>
        })}
      </div>
    )
  }

  renderEye = () => {
    if(this.state.passwordFocus && this.state.showPassword){
      return(
        <img  alt="eye-pw" onClick={() => {this.showPassword()}}className="eye-password" src={eyePassword1}/>
      )
    }  else if (this.state.passwordFocus){
      return(
        <img alt="eye-pw2" onClick={() => {this.showPassword()}}className="eye-password" src={eyePassword2}/>
      )
    }
  }

  showPassword = () => {
    this.setState({showPassword: !this.state.showPassword})
  }

  showEye = () => {
    this.setState({passwordFocus: true})
  }

  hideEye = () => {
    this.setState({passwordFocus: false})
  }

  render(){
    // const myElement = document.getElementById('password');
    // if(!this.state.passwordFocus && myElement === document.activeElement){
    //   this.setState({passwordFocus: true})
    // } else if(passwordFocus){
    //   this.setState({passwordFocus: false})
    // }
    let classPattern1 = this.state.stage === 0 ? "pattern1" : "pattern1 pattern1-in";
    let classPattern2 = this.state.stage === 2 ? "pattern2 pattern2-in": "pattern2";
    let classPattern3 = this.state.stage === 2 ? "pattern3 pattern3-in": "pattern3";
    let classPattern4 = this.state.stage === 2 ? "pattern4 pattern4-in": "pattern4"
    let classPattern5 = this.state.stage === 2 ? "pattern5 pattern5-in": "pattern5"
    let classPattern6 = this.state.stage === 2 ? "pattern6 pattern6-in": "pattern6"
    let classPattern7 = this.state.stage === 0 || this.state.stage > 2 ? "pattern7": "pattern7 pattern7-in"

    let classFormContent1 = this.state.stage === 2 ? "opacity-in" : ""
    let classFormContent2 = this.state.stage === 5 ? "opacity-in" : ""
    let classFormContent3 = this.state.stage === 8 ? "opacity-in" : ""


    let classIllustration1 = this.state.stage === 2 ? "illustration1 illustration1-in" : "illustration1"

    let classMandala = this.state.stage > 3 && this.state.stage < 6 ? "mandala mandala-positioned" : "mandala";
    
    let classIllustration2 = this.state.stage > 3 && this.state.stage < 6 ? "illustration1 illustration1-in" : "illustration1"
    let classPattern8 = this.state.stage > 3 && this.state.stage < 6 ? "pattern8 pattern8-in": "pattern8"
    let classPattern9 = this.state.stage === 5 ? "pattern9 pattern9-in": "pattern9"
    let classPattern10 = this.state.stage === 5 ? "pattern10 pattern10-in": "pattern10"

   


    return(
      <div id="create-account-container">
        <img alt="pattern1" className={classPattern1}  src={pattern1}/>
        <img alt="pattern2" className={classPattern1}  src={pattern1}/>
        <div id="create-account-main-window">
          <img alt="pattern3" className={classMandala} src={mandala}/>
          <div id="left-column">
            <div id="weely-logo-container">
             <a href="https://weely.io">
               <img alt="logo" id="logo" src={weelyLogo}/>
             </a>

            </div>
            {
            this.state.stage < 4 ? 
            <img alt="illustration1" className={classIllustration1} src={illustrationRegister1}/>
            :
            <img alt="illustration1" className={classIllustration2} src={illustrationRegister2}/>
          }
            
            <div id="sidebar-header">
            🚀 {this.state.texts.freeTrial}
            </div>
            <div id="sidebar-perks-container">
              <div id="sidebar-perks-header">
                  {this.state.texts.withWeely}
              </div>
              <div className="sidebar-perk sidebar-perk-1">
                💰 {this.state.texts.repeatBusiness}
              </div>
              <div className="sidebar-perk sidebar-perk-2">
                📢 {this.state.texts.userFeedback}
              </div>
              <div className="sidebar-perk sidebar-perk-3">
                📱 {this.state.texts.socialFollowers}
              </div>
              <div className="sidebar-perk sidebar-perk-4">
                 💗 {this.state.texts.brandEngagement}
              </div>
              <div className="sidebar-perk sidebar-perk-5">
                 ✉️ {this.state.texts.mailing}
              </div>
            </div>
            <div id="sidebar-testimonial-container">
                <div id="sidebar-testimonial-text">
                {this.state.texts.testimonial}
                </div>
                <div id="sidebar-testimonial-witness">
                  <img src={witness} alt="witness" id="sidebar-witness-photo"/>
                  <div id="sidebar-witness-text-container">
                    <div id="sidebar-witness-name">
                    {this.state.texts.testName}
                    </div>
                    <div id="sidebar-witness-title">
                    {this.state.texts.testTitle}
                    </div>
                  </div>
                </div>
            </div>
            <div id="welcome-club-container">
                  <div id="welcome-club-text">
                   👋 {this.state.texts.welcome}
                  </div>
                  <div id="welcome-logos-container">
                    <img src={dunkinLogo} className="welcome-logo" alt="company"/>
                    <img src={holycowLogo} className="welcome-logo" alt="company"/>
                    <img src={impressionlogo} className="welcome-logo" alt="company"/>
                  </div>
                </div>

          </div>
          <div id="right-column">
            <img className={classPattern2} src={pattern2} alt="pattern"/>
            <img className={classPattern3} src={pattern3} alt="pattern1"/>
            <img className={classPattern4} src={pattern4} alt="pattern1"/>
            <img className={classPattern5} src={pattern5} alt="pattern1"/>
            <img className={classPattern6} src={pattern6} alt="pattern1"/>
            <img className={classPattern7} src={pattern7} alt="pattern1"/>
            <img className={classPattern8} src={pattern8} alt="pattern1"/>
            <img className={classPattern9} src={pattern9} alt="pattern1"/>
            <img onClick={this.back} className={classPattern10} src={pattern10} alt="pattern"/>


          


              {
                this.state.stage < 4 ? 

                <div id="form-content" className={classFormContent1}>

                  <div className="form-row form-header">{this.state.texts.header1}</div>
                  <div className="form-row">
                    <div className="form-text-input-container half-length">
                      <div className="form-input-label">
                       {this.state.texts.firstName}
                      </div>
                      <input className="text-input" type="text" name="firstName" value={this.state.firstName} onChange={this.handleInputChange} placeHolder="John"/>
                    </div>
                    <div className="form-text-input-container half-length">
                      <div className="form-input-label">
                        {this.state.texts.lastName}
                      </div>
                      <input className="text-input" type="text" name="lastName" value={this.state.lastName} onChange={this.handleInputChange} placeHolder="Smith"/>
                    </div>
                  </div>
                  
                  <div className="form-row">
                    <div className="form-text-input-container full-length">
                      <div className="form-input-label">
                       {this.state.texts.businessName}
                      </div>
                      <input className="text-input" type="text" name="businessName" value={this.state.businessName} onChange={this.handleInputChange} placeHolder="Smith Inc."/>
                    </div>
                  </div>
                  <div className="form-row">
                  <div className="form-text-input-container half-length">
                      <div className="form-input-label">
                        {this.state.texts.email}
                      </div>
                      <input className="text-input" type="text" name="email" value={this.state.email} onChange={this.handleInputChange} placeHolder="johnsmith@mail.com"/>
                    </div>
                    <div onMouseEnter={()=>{this.showEye()}} onMouseLeave={()=>{this.hideEye()}} className="form-text-input-container half-length">
                      <div className="form-input-label">
                         {this.state.texts.password}
                      </div>
                      <input id="password"  className="text-input" type={!this.state.showPassword ? "password" : "text"} name="password" value={this.state.password} onChange={this.handleInputChange} placeHolder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"/>
                      {this.renderEye()}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-text-input-container half-length">
                      <div className="form-input-label">
                       {this.state.texts.country}
                      </div>
                      <select className={this.state.country === "" ? "form-select form-select-placeholder" : "form-select"} name="country" value={this.state.country} onChange={this.handleInputChange} placeHolder="John">
                        <option value="" disabled selected hidden>{this.state.texts.selectCountry}</option>
                        {countries.countryList.map((country)=>{
                          return(<option value={country}>{country}</option>)
                        })}
                      </select>
                    </div>
                    <div className="form-text-input-container half-length">
                      <div className="form-input-label">
                        {this.state.texts.language}
                      </div>
                      <select className={this.state.language === "" ? "form-select form-select-placeholder" : "form-select"}  name="language" value={this.state.language} onChange={this.handleInputChange} placeHolder="John">
                        <option value="" disabled selected hidden> {this.state.texts.selectLanguage}</option>
                        <option value="englishEU">English</option> 
                        {/* <option value="spanish">Español</option>  */}
                        <option value="french">Français</option> 
                        {/* <option value="german">Deutsch</option>  */}
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="center-container">
                      <button onClick={() => {this.next()}} className="form-button"> {this.state.texts.next}</button>
                    </div>
                  </div>
                  <div className="center-container">
                  <div className="form-error-prompt">{this.state.errorPrompt}</div>

                    <div className="underline"/>

                    <Link to="login" className="already-account"> {this.state.texts.alreadyHave}</Link>
                    <NewAccountProgress step={0} step1={this.state.texts.details} step2={this.state.texts.goals} step3={this.state.texts.confirm}/>
                  </div>
                </div>


                : ""
              }
              {
                this.state.stage > 3 && this.state.stage < 7 ? 


              
                <div id="form-content" className={classFormContent2}>
                  <div id="back-button">← {this.state.texts.back} </div>

                  <div className="form-row form-header"> {this.state.texts.header2}</div>
                  <div className="form-row">
                    <div className="full-length">
                      <div className="form-input-label">
                       {this.state.texts.businessType}
                      </div>
                      <select className={this.state.businessType === "" ? "form-select form-select-placeholder" : "form-select"}  name="businessType" value={this.state.businessType} onChange={this.handleInputChange} placeHolder="John">
                        <option value="" disabled selected hidden>{this.state.texts.selectBusiness}</option>
                        <option value="restaurant">{this.state.texts.restaurant}</option> 
                        <option value="cafe">{this.state.texts.cafe}</option>
                        <option value="cafeteria">{this.state.texts.cafeteria}</option> 
 
                        <option value="store">{this.state.texts.store}</option> 
                        <option value="event">{this.state.texts.event}</option> 
                        <option value="other">{this.state.texts.otherBusiness}</option> 

                      </select>
                    </div>
                  </div>
                  <div className="form-row form-row-tags">
                    <div className="form-text-input-container full-length">
                      <div className="form-input-label">
                        {this.state.texts.interests}
                      </div>
                      {this.renderTags()}
                    </div>
                  </div>
                  <div className="form-row checkbox-container">
                    <div className="TC-checkbox" onClick={() => this.checkTC()}>  
                      <div className={this.state.TCAccepted ? "check checked" : "check"}/>
                    </div>
                    <div className="form-input-label">
                      {this.state.texts.TC1} <a href="https://www.weely.io/documents/terms-conditions" target="_blank" rel="noopener noreferrer" id="TC"> {this.state.texts.TC2}</a>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="center-container">
                      <button onClick={() => {this.register()}} className="form-button">{this.state.texts.createAccount}</button>
                    </div> 
                  </div>
                  <div className="center-container">
                    <div className="form-error-prompt">{this.state.errorPrompt}</div>
                    <NewAccountProgress step={1} step1={this.state.texts.details} step2={this.state.texts.goals} step3={this.state.texts.confirm}/>

                  </div>

                </div>

                : ""
              }

              {
                this.state.stage > 6 ? 

                <div id="form-content" className={classFormContent3}>
                  <div className="center-container">

                    <img id="complete-image" src={registrationComplete} alt="complete"/>
                  </div>
                  <div className="form-row form-header form-header-complete"> {this.state.texts.header3}</div>
                  <div className="form-row form-row-short email-sent-to">
                    <div className="form-text form-row-text-email">
                    {this.state.texts.sendConfirmationMail} <b>{this.state.email}</b>
                    </div>
                  </div>
                  <div className="form-row form-row-short">
                    <div className="form-text">
                    {this.state.texts.openLink}
                    </div>
                  </div>
                  <div className="form-row form-row-button">
                    <div className="center-container">
                          <button onClick={() => {this.backToLogin()}} className="form-button">{this.state.texts.backToLogin}</button>
                    </div>
                  </div>
                  <div className="center-container">
                  <div className="form-error-prompt">{this.state.errorPrompt}</div>

                    <div className="already-account">{this.state.texts.didntReceive1} <b onClick={() => {this.sendConfirmationMail()}}id="send-confirmation-again">{this.state.texts.didntReceive2}</b></div>
                    <NewAccountProgress step={2} step1={this.state.texts.details} step2={this.state.texts.goals} step3={this.state.texts.confirm}/>
                  </div>
                </div>
                : ""
              }
            
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(CreateAccount);
