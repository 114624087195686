import "./NewAccountProgress.css";


function NewAccountProgress(props) {
  
  return (
    <div id="progress-container">
      <div id="progress-line"/>
      <div className="progress-step-container">
        <div className={"progress-step-circle progress-circle-complete"}>
          1
        </div>
        <div className={"progress-step-label progress-step-complete"}>
          {props.step1}
        </div>
      </div>
      <div className="progress-step-container">
        <div className={"progress-step-circle" + (props.step > 0 ? " progress-circle-complete" : "") }>
          2
        </div>
        <div className={"progress-step-label" + (props.step > 0 ? " progress-step-complete" : "") }>
        {props.step2}
        </div>
      </div>
      <div className="progress-step-container">
        <div className={"progress-step-circle" + (props.step > 1 ? " progress-circle-complete" : "") }>
          3
        </div>
        <div className={"progress-step-label" + (props.step > 1 ? " progress-step-complete" : "") }>
        {props.step3}
        </div>
      </div>
    </div>
  
  );
}

export default NewAccountProgress;
