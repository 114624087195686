import { Component } from "react";
import { withRouter } from "react-router-dom";
import countries from "../Countries";
import * as languages from "../languages";






import "../Global.css";
import "./ChangePlan.css";
import perkCheck from "../images/check-perk.png";
import loader from "../images/loading.gif";



class ChangePlan extends Component {
  constructor(props){
    super(props);
    this.state = {
      stage: 0,
      displayUpdatePrompt: false,
      requestPending: false,
      selectedPlan: "",
      selectedPlanId: "",
      planUpdateStatus: "", 
      monthlyBilling: true,
      renderPaddle: false,
      texts: languages[props.language].texts.upgrade
    }
  }

  componentDidMount(){
    this.animateIn();
    console.log(this.props)
    // this.renderPaddle();   
  }

  renderPaddle = () => {
    let countryCode = countries.countryCodes[this.props.country];
    let hasPaddleAccount = typeof this.props.subscription.customer_id === "string" && this.props.subscription.customer_id.length > 0 ? true : false;
    let hasPaddleSubscription = typeof this.props.subscription.subscription_id === "string" && this.props.subscription.subscription_id.length > 0 ? true : false;

    const Paddle = window.Paddle;

    console.log(this.props.subscription);

    let customerSettings = {};
    console.log(hasPaddleAccount);
    if(hasPaddleAccount){
      customerSettings = 
        {
          id: this.props.subscription.customer_id,
          // email: this.props.email,
        } // HERE
    }else{
      customerSettings = 
      {
        email: this.props.email,
        address: {
          countryCode: countryCode
        }
      }
    }

    console.log("ASD")
    console.log(customerSettings)
    let paddleSettings = {
      settings: {
        displayMode: "inline",
        theme: "light",
        allowLogout: false,
        locale: "en",
        frameTarget: "checkout-container",
        frameInitialHeight: 570,
        frameStyle: 'width:65%; min-width:500px; max-height:520px; background-color: #fff; border: none;'
      },
      customData: {
        "user_id": this.props.subscription.customer_id
      },
      customer: customerSettings
    };

    if(hasPaddleSubscription){
      paddleSettings.transactionId = this.props.subscription.transaction_id;
    }else{
      paddleSettings.items = [
        {
          priceId: this.state.selectedPlanId,
          quantity: 1
        },
      ]
    }
    console.log(paddleSettings);
    
    Paddle.Checkout.open(paddleSettings);
  }


  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  animateIn = () => {
    setTimeout(() =>{
      this.setState({
        stage: 1
      })
    }, 400)
    setTimeout(() =>{
      this.setState({
        stage: 2
      })
    }, 1000)
  }


  logPrompt = (prompt) => {
    this.setState({errorPrompt: prompt})
    setTimeout(() => {
      this.setState({errorPrompt: ""})
    }, 2000)
  }

  switchBilling = () => {
    this.setState({monthlyBilling: !this.state.monthlyBilling})
  }

  updatePlan = async() => {
    if(this.state.requestPending){
      return;
    }
    this.setState({requestPending: true});
    const request = {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.user.token,
      },
      body: JSON.stringify({
        newSubscriptionPriceId: this.state.selectedPlanId,
        currentSubscriptionPriceId: this.props.subscription.subscription_price_id 
      })
    };
    let response = await fetch(this.props.url + "/paddle/subscription-upgrade/" + this.props.user._id, request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      setTimeout(() => {
        this.setState({
          requestPending: false,
          planUpdateStatus: "updated"
        });
      }, 1000)
      
    } else {
      alert("NANI")
      this.setState({
        planUpdateStatus: "update failed",
        requestPending: false
      })
    }
  }

  openOverlay = (newPlan) => {
    let str1 = "";
    let str2 = "";
    if(newPlan === "growth" && this.state.monthlyBilling){
      str1 = "Weely Growth Monthly";
      str2 = "pri_01hc2f51e9vx5z0hq8bve7ey4n";
    }else if (newPlan === "boost" && this.state.monthlyBilling){
      str1 = "Weely Boost Monthly";
      str2 = "pri_01hc2fa8ck3mn488yckxna1krr";

    }else if (newPlan === "growth" && !this.state.monthlyBilling){
      str1 = "Weely Growth Yearly";
      str2 = "pri_01hc2f61mx71b741kj3f2g41h3";

    }else if (newPlan === "boost" && !this.state.monthlyBilling){
      str1 = "Weely Boost Yearly";
      str2 = "pri_01hc2fazzys01ycm5aax2xe3ne";
    }else{ 
      return;
    }

    if(this.props.subscription.name === "Weely Free" || this.props.subscription.status === "expired"){
      this.setState({
        planUpdateStatus: "paddle checkout",//"paddle checkout",
        selectedPlanId: str2,
        displayUpdatePrompt: true //true
      })
      setTimeout(()=> { //prevent async bullshit with selectedPlanID
        this.renderPaddle();
      },200)
      return;
    }

    this.setState({
      selectedPlan: str1,
      selectedPlanId: str2,
      displayUpdatePrompt: true
    })
  }

  closePrompt = () => {
    if(this.state.planUpdateStatus === "updated"){
      this.props.history.push('/wheels');
    }
    this.setState({
      displayUpdatePrompt: false
    })
  }

  closeCheckout = () => {
    this.setState({
      planUpdateStatus: "",
      selectedPlanId: "",
      displayUpdatePrompt: false
    })
  }



  render(){
    // let classPattern1 = this.state.stage === 0 ? "pattern1" : "pattern1 pattern1-in";
    console.log(this.props)
 
    return(
      <div id="change-plan-container">
        <div onClick={this.props.returnToAccountScreen} className="back-to-account">{"<  " + this.state.texts.back}</div>
        {this.state.displayUpdatePrompt ? 
          <div className="delete-wheel-prompt">
            <div className="background-overlay"/>
            {this.state.planUpdateStatus === "paddle checkout" ? 
              <div className="checkout-overlay-window">
                <div onClick={this.closeCheckout} className="back-to-plan-upgrade">{"<  " + this.state.texts.back}</div>
                <div className="checkout-wrapper">
                  <div className="checkout-container"/>
                  <div className="checkout-order-summary">
                  </div>
                </div>
              </div>
              :
              <div className="delete-wheel-prompt-window">
              {this.state.planUpdateStatus === "updated" ? 
                <div className="overlay-content-container">
                  <div>
                    <h4 className="delete-wheel-header">{this.state.texts.planUpdated}</h4>
                    <div className="delete-wheel-text">{this.state.texts.planUpdatedText} <b>{this.state.selectedPlan}</b>.</div>
                  </div>
                  <div className="delete-buttons-container">
                    <div onClick={() => {this.closePrompt()}} className="delete-cancel-button">{this.state.texts.back}</div>
                  </div>
                </div>
                :
                <div className="overlay-content-container">
                  <div>
                    <h4 className="delete-wheel-header">{this.state.planUpdateStatus !== "update failed" ? this.state.texts.updatePlan : "Something went wrong ..."}</h4>
                    <div className="delete-wheel-text">{this.state.planUpdateStatus !== "update failed" ? <div>{this.state.texts.updatePrompt} <b>{this.state.selectedPlan}</b>?</div> : "An error occured while trying to update your subscription. Try again, or contact us for assistance."}</div>
                  </div>
                  {this.state.requestPending ?
                    <div className="loading-icon-container">
                      <img src={loader} className="loading-icon" alt="loading-icon"/>
                    </div>
                    :
                    <div className="delete-buttons-container">
                      <div onClick={() => {this.updatePlan()}}className="delete-cancel-button">{this.state.planUpdateStatus === "update failed" ? "Try Again" : this.state.texts.confirm}</div>
                      <div onClick={() => {this.closePrompt()}} className="delete-confirm-button">{this.state.texts.cancel}</div>
                    </div>
                  }
                </div>
              }
              
            </div>
            }
            
          </div>
         : ""
        }
        {!this.state.renderPaddle ? 
          <div className="plan-select-container">
            <h1>{this.state.texts.header}</h1>
            <h4>{this.state.texts.text}</h4>
            <div className="plan-switch-billing-container">
              <div className={this.state.monthlyBilling ? "monthly-billing-text selected-billing-interval" : "monthly-billing-text"}>
              {this.state.texts.monthlyBilling}
              </div>
              <div onClick={()=> {this.switchBilling()}} className={this.state.monthlyBilling ? "billing-switch-bg" : "billing-switch-bg switch-bg-annual"}>
                <div className={this.state.monthlyBilling ? "billing-switch-ball" : "billing-switch-ball switch-ball-annual"}/>
              </div>
              <div className={this.state.monthlyBilling ? "annual-billing-text" : "annual-billing-text selected-billing-interval"}>
              {this.state.texts.annualBilling}
              </div>
            </div>
            <div className="plan-tiles-container">
              <div className="plan-tile" id="plan-tile-trial">
                <div className="plan-tile-main-content">
                  <div className="plan-price-container">
                    <div className="plan-price-text">
                    € 0
                    </div>
                    <div className="plan-price-month">
                      / {this.state.texts.month}
                    </div>
                  </div>
                  <div className="plan-name">
                    Weely Trial
                  </div>
                  <div className="plan-description">
                  {this.state.texts.trialText}
                  </div>
                  <div className="plan-perk">
                  <img src="https://assets-global.website-files.com/656ce0d0aa2b1a7a1576eaa5/65899fef80101def933c09c9_wheel.svg" loading="lazy" alt="wheel-icon" class="perk-icon"/>
                    {this.state.texts.trialPerk1}
                  </div>
                  <div className="plan-perk">
                  <img src="https://assets-global.website-files.com/656ce0d0aa2b1a7a1576eaa5/65899fef80101def933c09c6_spins.svg" loading="lazy" alt="spin-icon" class="perk-icon"/>
                    {this.state.texts.trialPerk2}
                  </div>
                  <div className="plan-perk">
                  <img src="https://assets-global.website-files.com/656ce0d0aa2b1a7a1576eaa5/65899fef90f9f61047b6eb31_prizes.svg" loading="lazy" alt="prize-icon" class="perk-icon"/>
                    {this.state.texts.trialPerk3}
                  </div>
                  <div className="perks-container">
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.trialPerk4}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.trialPerk5}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.trialPerk6}
                    </div>
                  </div>
                </div>
                {this.props.subscription.name === "trial" ?
                  <div className="current-plan-button">
                    {this.state.texts.currentPlan}
                  </div> 
                  :""
                }
                
              </div>
              <div className="plan-tile" id="plan-tile-growth">
                <div className="plan-tile-main-content">
                  <div className="plan-price-container">
                    <div className="plan-price-text plan-price-purple">
                    € {this.state.monthlyBilling ? 38 : 26}
                    </div>
                    <div className="plan-price-month">
                      / {this.state.texts.month}
                    </div>
                  </div>
                  <div className="plan-name">
                    Weely Growth
                  </div>
                  <div className="plan-description">
                  {this.state.texts.growthText}
                  </div>
                  <div className="plan-perk">
                    <img src="https://assets-global.website-files.com/656ce0d0aa2b1a7a1576eaa5/65899fef80101def933c09c9_wheel.svg" loading="lazy" alt="wheel-icon" class="perk-icon"/>
                    {this.state.texts.growthPerk1}
                  </div>
                  <div className="plan-perk">
                    <img src="https://assets-global.website-files.com/656ce0d0aa2b1a7a1576eaa5/65899fef80101def933c09c6_spins.svg" loading="lazy" alt="spin-icon" class="perk-icon"/>
                    {this.state.texts.growthPerk2}
                  </div>
                  <div className="plan-perk">
                   <img src="https://assets-global.website-files.com/656ce0d0aa2b1a7a1576eaa5/65899fef90f9f61047b6eb31_prizes.svg" loading="lazy" alt="prize-icon" class="perk-icon"/>
                    {this.state.texts.growthPerk3}
                  </div>
                  <div className="perks-container">
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.growthPerk4}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.growthPerk5}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.growthPerk6}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.growthPerk7}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.growthPerk8}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.growthPerk9}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.growthPerk10}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.growthPerk11}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.growthPerk12}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.growthPerk13}
                    </div>
            
                  </div>
                </div>
                <div onClick={() => {this.openOverlay("growth")}} className={((this.props.subscription.name === "Weely Growth Monthly" && this.state.monthlyBilling) || (this.props.subscription.name === "Weely Growth Yearly" && !this.state.monthlyBilling)) && this.props.subscription.status !== "expired"  ? "current-plan-button" : "upgradeable-plan-button"}>
                  {((this.props.subscription.name === "Weely Growth Monthly" && this.state.monthlyBilling) || (this.props.subscription.name === "Weely Growth Yearly" && !this.state.monthlyBilling))  && this.props.subscription.status !== "expired" ? this.state.texts.currentPlan : this.state.texts.upgrade}
                </div>
              </div>
              <div className="plan-tile" id="plan-tile-boost">
                <div className="plan-tile-main-content">
                  <div className="plan-price-container">
                    <div className="plan-price-text plan-price-yellow">
                    € {this.state.monthlyBilling ? 62 : 43}
                    </div>
                    <div className="plan-price-month">
                      / {this.state.texts.month}
                    </div>
                  </div>
                  <div className="plan-name">
                  Weely Boost
                  </div>
                  <div className="plan-description">
                  {this.state.texts.boostText}
                  </div>
                  <div className="plan-perk">
                    <img src="https://assets-global.website-files.com/656ce0d0aa2b1a7a1576eaa5/65899fef80101def933c09c9_wheel.svg" loading="lazy" alt="wheel-icon" class="perk-icon"/>
                    {this.state.texts.boostPerk1}
                  </div>
                  <div className="plan-perk">
                    <img src="https://assets-global.website-files.com/656ce0d0aa2b1a7a1576eaa5/65899fef80101def933c09c6_spins.svg" loading="lazy" alt="spin-icon" class="perk-icon"/>
                    {this.state.texts.boostPerk2}
                  </div>
                  <div className="plan-perk">
                    <img src="https://assets-global.website-files.com/656ce0d0aa2b1a7a1576eaa5/65899fef90f9f61047b6eb31_prizes.svg" loading="lazy" alt="prize-icon" class="perk-icon"/>
                    {this.state.texts.boostPerk3}
                  </div>
                  <div className="plan-perk">
                    <b id="emoji-perk">🔥</b>{this.state.texts.boostPerk4}
                  </div>
                  <div className="perks-container">
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.boostPerk4}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.boostPerk5}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.boostPerk6}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.boostPerk7}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.boostPerk8}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.boostPerk9}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.boostPerk10}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.boostPerk11}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.boostPerk12}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.boostPerk13}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.boostPerk14}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.boostPerk15}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.boostPerk16}
                    </div>
                    <div className="plan-perk">
                      <img className="perk-check-icon" src={perkCheck} alt="check-icon"/>
                      {this.state.texts.boostPerk17}
                    </div>
                  </div>
                </div>
                <div onClick={() => {this.openOverlay("boost")}} className={((this.props.subscription.name === "Weely Boost Monthly" && this.state.monthlyBilling) || (this.props.subscription.name === "Weely Boost Yearly" && !this.state.monthlyBilling))  && this.props.subscription.status !== "expired" ? "current-plan-button" : "upgradeable-plan-button"}>
                  {((this.props.subscription.name === "Weely Boost Monthly" && this.state.monthlyBilling) || (this.props.subscription.name === "Weely Boost Yearly" && !this.state.monthlyBilling)) && this.props.subscription.status !== "expired"  ? this.state.texts.currentPlan : this.state.texts.upgrade}
                </div>
              </div>
            </div>
            
          </div> 
          : ""
        }        
      </div>
    )
  }
}
export default withRouter(ChangePlan);
