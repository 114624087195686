import "./ActionsBreakdown.css";
import { Component } from "react";
import logoFacebook from "../images/actionFacebook.png";
import logoInstagram from "../images/actionInsta.png";
import logoGoogle from "../images/actionGoogle.png";
import logoCustom from "../images/actionCustom.png";
import logoQuestion from "../images/actionQuestion.png";
import placeholderSocial from "../images/placeholderSocial.png";


import "../../node_modules/react-vis/dist/style.css";

class ActionsBreakdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    console.log(props);
  }


 


  renderRows = () => {
    if(this.props.spins.length > 0){
      let totalCount = 0;
      let largestValue = 0;
      let fbSpins = this.props.spins.filter((spin) =>  typeof spin.action !== "undefined" && spin.action === "facebook");
      if(fbSpins.length > largestValue){largestValue = fbSpins.length};
      let instaSpins = this.props.spins.filter((spin) => typeof spin.action !== "undefined" && spin.action === "instagram");
      if(instaSpins.length > largestValue){largestValue = instaSpins.length};
      let googleSpins = this.props.spins.filter((spin) => typeof spin.action !== "undefined" && spin.action === "google");
      if(googleSpins.length > largestValue){largestValue = googleSpins.length};
      let questionSpins = this.props.spins.filter((spin) => typeof spin.action !== "undefined" && spin.action === "question");
      if(questionSpins.length > largestValue){largestValue = questionSpins.length};
      let customSpins = this.props.spins.filter((spin) => typeof spin.action !== "undefined" && spin.action === "custom");
      if(customSpins.length > largestValue){largestValue = customSpins.length};
      // customSpins = [1,2,3,4,5,6,7,8,9,2,3,5]
      //  fbSpins= [2, 3 ,4 ,5 ,6]
      totalCount = fbSpins.length + instaSpins.length + googleSpins.length + questionSpins.length + customSpins.length;
      if(totalCount > 0){
        return(
          <div className="actions-container">
            {
              fbSpins.length > 0 ? 
                <div className="actions-row">
                  <div className="actions-logo">
                    <img alt="logoFb" src={logoFacebook} className="actions-logo-img"/>
                  </div>
                  <div className="actions-value" style={{width: 8 + (fbSpins.length * 72) /largestValue  + "%"}}>
                    {fbSpins.length}
                  </div>
                </div>
              : ""
            }
            {
              instaSpins.length > 0 ? 
                <div className="actions-row">
                  <div className="actions-logo">
                    <img alt="logoInsta" src={logoInstagram} className="actions-logo-img"/>
                  </div>
                  <div className="actions-value" style={{width: 8 + (instaSpins.length * 72) /largestValue + "%"}}>
                    {instaSpins.length}
                  </div>
                </div>
              : ""
            }
            {
              googleSpins.length > 0 ? 
                <div className="actions-row">
                  <div className="actions-logo">
                    <img alt="logoGoogle" src={logoGoogle} className="actions-logo-img"/>
                  </div>
                  <div className="actions-value" style={{width: 8 + (googleSpins.length * 72) /largestValue + "%"}}>
                    {googleSpins.length}
                  </div>
                </div>
              : ""
            }
             {
              customSpins.length > 0 ? 
                <div className="actions-row">
                  <div className="actions-logo">
                    <img alt="logoCustom" src={logoCustom} className="actions-logo-img"/>
                  </div>
                  <div className="actions-value" style={{width: 8 + (customSpins.length * 72) /largestValue + "%"}}>
                    {customSpins.length}
                  </div>
                </div>
              : ""
            }
            {
              questionSpins.length > 0 ? 
                <div className="actions-row">
                  <div className="actions-logo">
                    <img alt="logoQuestion" src={logoQuestion} className="actions-logo-img"/>
                  </div>
                  <div className="actions-value" style={{width: 8 + ((questionSpins.length * 72) /largestValue) + "%"}}>
                    {questionSpins.length}
                  </div>
                </div>
              : ""
            }
          </div>
        )
      }
    } else {
      return(
        <div className="placeholder-container">
          <img alt="placeholder" className="placeholder-image" src={placeholderSocial}></img>
          <div className="placeholder-label-title">{this.props.textOops}</div>
          <div className="placeholder-label">{this.props.textNoActions}</div>
        </div>
      )
    }
    
  }


  render() {
    return (
      <div className="actions-container">
        
        {this.renderRows()}
      </div>
    );
  }
}

export default ActionsBreakdown;
