import "./Global.css";
import "./fonts.css";
import Login from "./Login";
import Home from "./Home";
import Wheels from "./Wheels";
import Wheel from "./Wheel";
import Account from "./Account";


import ResetPassword from "./ResetPassword";
import ConfirmPassword from "./ConfirmPassword";
import CreateAccount from "./CreateAccount";
import VerifyEmail from "./VerifyEmail";

import React from "react";


//test
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.api = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : process.env.REACT_APP_API_BASE_URL;
    this.state = {
      authed: false,
      user: null,
      requestPending: false,
      language: "englishEU",
      languageUpdating: false
    };

    // check if token valid before rehydrating, use lazy eval
    if (localStorage.hasOwnProperty("language") && (localStorage.getItem("language") === "french" || localStorage.getItem("language") === "englishEU")) {
      this.state.language = localStorage.getItem("language");
    } 

  

    if (localStorage.hasOwnProperty("user") && localStorage.hasOwnProperty("token")) {
      console.log(localStorage.getItem("user").split("-")[2])
      console.log("Local storage found");
      this.state.authed = true;
      let user = {};
      user._id = localStorage.getItem("user").split("-")[0];
      user.name = localStorage.getItem("user").split("-")[1];
      user.token = localStorage.getItem("token");
      this.state.user = user;
    }
  }

  loginUser = (user) => {
    localStorage.setItem("user", user._id + "-" + user.name);
    localStorage.setItem("token", user.token);

    this.setState({
      user: user,
      authed: true,
    });
  };

  setLanguage = (lang) => {
    // let langObject = {...lang}
    localStorage.setItem("language", lang);
    this.setState({
      language: lang,
      languageUpdating: true
    })
    setTimeout(() => {
      this.setState({
        languageUpdating: false
      })
    }, 300)
  }

  logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");

    this.setState({
      user: null,
      authed: false,
    });
  };

  sendVerificationEmail = async(email) => {
    if(!email.includes("@") || !email.includes(".") || email.length < 4 || this.state.requestPending){
      return;
    }
    this.setState({requestPending: true});
    const request = {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email
      }),
    };

    let response = await fetch(this.api + "/verify/email", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      this.setState({requestPending: false});

      console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
    } else {
      this.setState({requestPending: false});
      console.log("SILENTLY FAILS, LIKE A NINJA")
    }
  };

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route exact path="/">
              {this.state.authed ? <Redirect to="/home"></Redirect> : <Redirect to="/login"></Redirect>}
            </Route>
            <Route path="/login">
              <Login
                login={this.login}
                logout={this.logout}
                authed={this.state.authed}
                url={this.api}
                loginUser={this.loginUser}
                sendVerificationEmail={this.sendVerificationEmail}
                language={this.state.language}
              />
            </Route>
            <Route path="/reset-password">
              <ResetPassword url={this.api} language={this.state.language}/>
            </Route>
            <Route path="/create-account">
              <CreateAccount url={this.api} sendVerificationEmail={this.sendVerificationEmail} language={this.state.language} setLanguage={this.setLanguage} languageUpdating={this.state.languageUpdating}/>
            </Route>
            <Route path="/confirm-password">
              <ConfirmPassword url={this.api} language={this.state.language}/>
            </Route>
            <Route path="/verify-email">
              <VerifyEmail url={this.api} language={this.state.language}/>
            </Route>
            <PrivateRoute path="/home" auth={this.state.authed}>
              <Home language={this.state.language}/>
            </PrivateRoute>
            <PrivateRoute path="/wheels" auth={this.state.authed}>
              <Wheels user={this.state.user} url={this.api} logout={this.logout} key={this.state.language} language={this.state.language} setLanguage={this.setLanguage}/>
            </PrivateRoute>
            <PrivateRoute path="/account" auth={this.state.authed}>
              <Account user={this.state.user} url={this.api} logout={this.logout} key={this.state.language} language={this.state.language} setLanguage={this.setLanguage}/>
            </PrivateRoute>
            <PrivateRoute path="/wheel/:id" auth={this.state.authed}>
              <Wheel user={this.state.user} url={this.api} logout={this.logout} key={this.state.language} language={this.state.language} setLanguage={this.setLanguage}/>
            </PrivateRoute>
          </Switch>
        </div>
      </Router>
    );
  }
}

function PrivateRoute({ children, auth, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => {
        return auth === true ? children : <Redirect to="/login" />;
      }}
    />
  );
}

export default App;
