import "./Global.css";
import "./ResetPassword.css";
import "./Login.css";
import logo from "./images/weely_logo.png";
import print from "./images/login_print.png";
import { useState } from "react";
import { Link } from "react-router-dom";

function ResetPassword(props) {
  const [email, setEmail] = useState("");
  const [prompt, setPrompt] = useState("");
  const [positive, setPositive] = useState(false);

  const clearPrompt = () => setPrompt("");

  async function resetPassword() {
    console.log("Reset attempt");
    if (
      email.length === 0 ||
      email.split("@").length < 2 ||
      email.split(".").length < 2
    ) {
      setPositive(false);
      setPrompt("Please enter a valid email address.");
      return;
    }

    const request = {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    };

    let response = await fetch(props.url + "/reset/email", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log("Success");
      setPositive(true);
      setPrompt(
        "An email was sent to your email with a link to reset your password."
      );
      setEmail("");
      return;
    }
  }

  return (
    <div id="holder">
      <div id="login-navbar">
        <img src={logo} id="login-logo" alt="logo of weely"></img>
        <div id="login-holder">
          <h2>Forgot password.</h2>
          <label>
            Email
            <input
              type="text"
              className="text-input"
              placeholder="john.smith@mail.com"
              onChange={(e) => {
                clearPrompt();
                setEmail(e.target.value);
              }}
              name="email"
              value={email}
            ></input>
          </label>
          <h5 id="login-prompt" className={positive ? "positive" : "negative"}>
            {prompt}
          </h5>
          <button className="primary-button" id="login" onClick={resetPassword}>
            Send reset link
          </button>

          <h5 className="sublogin-link">
            <Link to="/login">Back to login</Link>
          </h5>
        </div>
      </div>
      <div id="page-holder">
        <img id="login-print" src={print} alt="illustration of user"></img>
      </div>
    </div>
  );
}

export default ResetPassword;
