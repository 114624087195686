import "./Global.css";
import "./Login.css";
import logo from "./images/weely_logo.png";
import print from "./images/login_print.png";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as languages from './languages';


function Login(props) {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [texts] = useState(languages[props.language].texts.login)
  const [prompt, setPrompt] = useState("");
  const [needToVerify, setNeedToVerify] = useState(false);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);


  const clearPrompt = () => setPrompt("");

  async function login() {
    try{
      console.log("Logging in attempt");
      if (
        email.length === 0 ||
        email.split("@").length < 2 ||
        email.split(".").length < 2
      ) {
        setPrompt("Please enter a valid email address.");
        return;
      }

      if (password.length === 0) {
        setPrompt("Please enter a valid password.");
        return;
      }

      const request = {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          //Authorization: 'Bearer ' + this.token
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      };

      let response = await fetch(props.url + "/login", request);
      let jsonResponse = await response.json();
      if (jsonResponse.success) {
        console.log("Success" + jsonResponse.data.token);
        let user = {};
        user._id = jsonResponse.data.client._id;
        user.name = jsonResponse.data.client.name;
        user.token = jsonResponse.data.token;
        user.email = jsonResponse.data.client.email;
        props.loginUser(user);
        history.push("/home");
      } else if(jsonResponse.message === "Email not been verified"){
        setPrompt("Please verify your email to proceed");
        setNeedToVerify(true);
        setPassword("");
      }  else {
        setPrompt("The email or password your entered is incorrect.");
        setPassword("");
      }
    }catch(err){
      setPrompt("Oops, something went wrong...");
    }
  }

   const enterPressed = (event) => {
      let code = event.keyCode || event.which;
      if(code === 13) { //13 is the enter keycode
         login();
      } 
  }


  const sendVerification = async() => {
    if(verificationEmailSent){
      return;
    }
    props.sendVerificationEmail(email);
    setVerificationEmailSent(true);
    setNeedToVerify(false);
    setPrompt("An email was sent to " + email);
  }

  return (
    <div id="holder">
      <div id="login-navbar">
        <img src={logo} id="login-logo" alt="logo of weely"></img>
        <div id="login-holder">
          <div>
            <h2>{texts.header}</h2>
            <label>
              {texts.inputLabelEmail}
              <input
                type="text"
                className="text-input"
                placeholder="john.smith@mail.com"
                onChange={(e) => {
                  clearPrompt();
                  setEmail(e.target.value);
                }}
                name="email"
                value={email}
              ></input>
            </label>
            <label>
             {texts.inputLabelPassword}
              <input
                type="password"
                className="text-input"
                placeholder="•••••••"
                name="password"
                onChange={(e) => {
                  clearPrompt();
                  setPassword(e.target.value);
                }}
                value={password}
                onKeyPress={enterPressed.bind(this)}
              ></input>
            </label>
            
            <button className="primary-button" id="login" onClick={login}>
            {texts.buttonLabelLogin}
            </button>
            <div id="login-prompt">
              {prompt}
            </div>
            {needToVerify ? 
                <div className="send-verification-again"> Didn't receive an email? <div onClick={sendVerification} id="send-again-button">Send Again</div> </div>
               :
               null
              }
          </div>
            
              <h5 className="sublogin-link">
              {texts.newToWeely} <Link to="create-account">{texts.linkSignUp}</Link> | <Link to="reset-password">{texts.linkForgotPassword}</Link>
              </h5>
          </div>          
        </div>
       
      <div id="page-holder">
        <img id="login-print" src={print} alt="illustration of user"></img>
      </div>
    </div>
  );
}

export default Login;
