const englishEU = {
    dateFormat: "dd/mm/yy",
    units: {
        day: "Tag",
        days: "Tage",
        hour: "Stunde",
        hours: "Stunden",
        minutes: "Minuten"
    },
    links:{
        tc: "https://weely.io/tc-de/",
        rules: "https://weely.io/rules-de/"
    },
    texts: {
        homeScreen:{
            title: "Glück gehabt? Gewinnen Sie einen Preis bei ",
            spinButton: "Drehe das Rad",
            rules: "Spielregeln",
            wheelLost: "Verloren"
        },
        emailScreen:{
            emailHeader: "Sie erhalten Ihren Gewinn per E-Mail",
            termsAndConditions1: "Ich akzeptiere die",
            termsAndConditions2: "allgemeinen Geschäftsbedingungen",
            subscribeClient: "Ich möchte Aktionen und Neuigkeiten erhalten von ",
            subscribeWeely: "Ich möchte Angebote von Weely erhalten",
            errorPromptTC: "Bitte akzeptieren Sie",
            errorPromptEmail: "Bitte geben Sie eine gültige Email-Adresse ein",
            button: "Bestätigen"
        },
        actionScreen: {
            actionHeader1: "Bevor Sie teilnehmen, ",
            actionHeader2: " braucht Sie!",
            subHeader: "Wählen Sie eine der folgenden Methoden aus. Es braucht zwei Klicks.",
            instagram: "Folgen Sie auf Instagram",
            google: "Google-Bewertung hinterlassen",
            facebook: "Folgen Sie auf Facebook",
            question: "Beantworte eine Frage",
            spinButton: "Drehe das Rad",
            actionHeaderAfter1: "",
            actionHeaderAfter2: " dankt Ihnen für Ihre Unterstützung!"
        },
        questionScreen: {
            questionHeader: "Ihre Meinung interessiert uns",
            addComment: "Kommentar hinzufügen (optional)"
        },
        losingScreen: {
            header: "Schade!",
            subHeader: "Versuchen Sie es beim nächsten Mal erneut",
            quotes: ["“Die größte Ehre ist nicht, nie zu fallen, sondern nach jedem Fall wieder aufzustehen”", "“Der Erfolg ist nicht endgültig. Ein Fehlschlag ist nicht tödlich. Der Mut zum Weitermachen zählt”", "“Das Leben ist so, als würde man einem Pony in Sanskrit vorlesen”", "“Der einzige Reiz der Vergangenheit ist, dass sie die vergangen ist”"],
            followUs: "Hat Ihnen Weely gefallen ? Folgen Sie uns auf Instagram oder kontaktieren Sie uns auf unserer Seite!"
        },
        winningScreen: {
            header: "Glückwunsch!",
            subHeader: "Ihr Preis wurde Ihnen an folgende E-Mail-Adresse gesendet:",
            NB1: "Hinweis: ",
            NB2: "Wenn Sie eine E-Mail nicht finden können, überprüfen Sie bitte Ihr Spam-Postfach.",
            followUs: "Hat Ihnen Weely gefallen ? Folgen Sie uns auf Instagram oder kontaktieren Sie uns auf unserer Seite!"
        },
        displayPrizeScreen: {
            header: "Ihr Preis bei ",
            expiryDate: "Gültig bis "
        },
        validatePrizeScreen: {
            headerValid: "Gültiger Code",
            headerInvalid: "Ungültiger Code",
            errorTooEarly1: "Ihr Gewinn ist nur ",
            errorTooEarly2: " nach dem Drehen des Rades gültig",
            errorClaimed: "Ihr Preis wurde bereits in Anspruch genommen",
            errorExpired: "Ihr Preis ist verfallen",
            subHeader: "Preis validieren?",
            validateButton: "Validieren",
            codeValidated: "Code Validiert!"
        }
    }
}

export default englishEU;