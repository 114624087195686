import "./Analytics.css";
import { Component } from "react";
import QuestionsAnalytics from "./QuestionsAnalytics";
import CommentsAnalytics from "./CommentsAnalytics";

import ActionsBreakdown from "./ActionsBreakdown";

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      horizon: "7days",
      spins: [],
      claimedSpins: [],
      prizes: [],
      questions: [],
      subscriberCount: "n/a",
      comments: null
    };
    this.url = props.url;
    this.user = props.user;
    this.wheel = window.location.href.split("/wheel/")[1];
  }

  horizonChange =  (e) => {
    this.setState(
      {
        horizon: e.target.value,
      },
      () => { this.getDataBatch()}
    );
  };

  getDataBatch = async (e) => {
    this.getSpins();
    this.getQuestions();
    this.getSubscriberCount();
    //    let comments = await this.getComments();
    await this.getComments();
  };

  //TODO
  async getSubscriberCount(){
    let t = 1;
    switch (this.state.horizon) {
      case "today":
        t = 1;
        break;
      case "7days":
        t = 7;
        break;
      case "30days":
        t = 30;
        break;
      case "alltime":
        t = "all";
        break;
      default:
    }
    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.user.token,
      }
    };
    let response = await fetch(this.url + "/wheel/" + this.wheel + "/subscribers?from=" + t , request);
    let jsonResponse = await response.json();
    let subscriberCount = jsonResponse.data === "" ? 0 : jsonResponse.data.split(',').length;
    if (jsonResponse.success) {
        this.setState({
          subscriberCount: subscriberCount
        });
    } else {
      alert("Something went wrong while connecting to server..")
    }
  }

  getSpins = async () => {
    let t = 1;
    switch (this.state.horizon) {
      case "today":
        t = 1;
        break;
      case "7days":
        t = 7;
        break;
      case "30days":
        t = 30;
        break;
      case "alltime":
        t = "all";
        break;
      default:
    }

    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.user.token,
      },
    };

    let response = await fetch(
      this.url +
        "/client/spins/" +
        this.user._id +
        "/" +
        this.wheel +
        "?from=" +
        t,
      request
    );
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log("HERE")
      console.log(jsonResponse.data.claimedSpins)
      let prizes = new Set(
        jsonResponse.data.spins
          .filter((spin) => !spin.is_lost)
          .map((spin) => spin.name)
      );
      let p = [];
      for (let prize of prizes) {
        let stat = {};
        stat.name = prize;
        stat.wins = jsonResponse.data.spins.filter(
          (spin) => spin.name === prize
        ).length;
        stat.claims = jsonResponse.data.claimedSpins
          .filter((spin) => spin.name === prize)
          .filter((spin) => spin.is_claimed).length;
        p.push(stat);
        stat.conversion = ((stat.claims / stat.wins) * 100).toFixed(1);
      }      
      setTimeout(()=>{     this.setState({
        spins: jsonResponse.data.spins,
        claimedSpins: jsonResponse.data.claimedSpins,
        prizes: p
      });
    }, 100)
    }
  };

  getQuestions = async () => {
    let t = 1;
    switch (this.state.horizon) {
      case "today":
        t = 1;
        break;
      case "7days":
        t = 7;
        break;
      case "30days":
        t = 30;
        break;
      case "alltime":
        t = "all";
        break;
      default:
    }
    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.user.token,
      },
    };

    let response = await fetch(
      this.url +
        "/client/answers/" +
        this.user._id +
        "/" +
        this.wheel +
        "?from=" +
        t,
      request
    );
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      let questions = new Set(
        jsonResponse.data.map((question) => question._question.name)
      );
      let q = [];
      for (let question of questions) {
        let stat = {};
        stat.name = question;
        stat.type = jsonResponse.data.filter(
          (qst) => qst._question.name === question
        )[0]._question.type;
        stat.answers = jsonResponse.data
          .filter((qst) => qst._question.name === question)
          .map((f) => {
            let res = {};
            res.value = f.value;
            res.date = f.created_at;
            return res;
          });
        console.log(stat)
        q.push(stat);
      }

      this.setState({
        spins: jsonResponse.data,
        questions: q,
      });
    }
  };

  getComments = async () => {
    let t = 1;
    switch (this.state.horizon) {
      case "today":
        t = 1;
        break;
      case "7days":
        t = 7;
        break;
      case "30days":
        t = 30;
        break;
      case "alltime":
        t = "all";
        break;
      default:
    }
    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.user.token,
      },
    };

    let response = await fetch(
      this.url +
        "/client/comments/" +
        this.user._id +
        "/" +
        this.wheel +
        "?from=" +
        t,
      request
    );
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
        console.log("Comments")
      this.setState({
        comments: jsonResponse.data
      });
    }
  };

  componentDidMount() {
    this.getComments();
    this.getSpins();
    this.getSubscriberCount();
    this.getQuestions();
  }

  createPrizeLine = (
    name,
    wins,
    claims,
    conversion,
    key
  ) => {
    let rowClass = "prize-line" + (key+2)%2;
    return (
      <tr className={rowClass} key={key}>
        <td className="prize-name">{name}</td>
        <td>{wins}</td>
        <td>{claims}</td>
        <td>{conversion} %</td>
      </tr>
    );
  };

  render() {
    return (
      <div id="analytics-holder">
        <select
          value={this.state.horizon}
          id="period-selector"
          onChange={this.horizonChange}
        >
          <option value="today">{this.props.texts.horizonToday}</option>
          <option value="7days">{this.props.texts.horizon7}</option>
          <option value="30days">{this.props.texts.horizon30}</option>
          <option value="alltime">{this.props.texts.horizonAll}</option>
        </select>
        <div id="widget-holder">
          <div id="overview">
            <h4 className="widget-title">{this.props.texts.overview}</h4>
            <div id="widget-card-holder">
              <div className="metric-card yellow">
                <div className="icon-circle" id="icon-circle-first"></div>
                <h4 className="cards-text">{this.props.texts.overviewSpins}</h4>
                <div className="count">{this.state.spins.length}</div>
              </div>
              <div className="metric-card green">
                <div className="icon-circle" id="icon-circle-second"></div>
                <h4 className="cards-text">{this.props.texts.overviewPrizesWon}</h4>
                <div className="count">
                  {this.state.spins.filter((spin) => !spin.is_lost).length}
                </div>
              </div>
              <div className="metric-card red">
                <div className="icon-circle" id="icon-circle-third"></div>
                <h4 className="cards-text">{this.props.texts.overviewPrizesClaimed}</h4>
                <div className="count">
                  {this.state.claimedSpins.length}
                </div>
              </div>
              <div className="metric-card purple">
                <div className="icon-circle" id="icon-circle-fourth"></div>
                <h4 className="cards-text">{this.props.texts.overviewSubscribers}</h4>
                <div className="count">
                  {this.state.subscriberCount}
                </div>
              </div>
            </div>
          </div>
          <div id="actions">
            <h4 className="widget-title">{this.props.texts.actions}</h4>
            <div id="actions-visuals">
              <ActionsBreakdown spins={this.state.spins} textOops={this.props.texts.oops} textNoActions={this.props.texts.noActions}/>
            </div>
          </div>
        </div>
        <div id="prize-table-holder">
          <h4 className="widget-title">{this.props.texts.prizesDetail}</h4>
          <table id="prize-table">
            <thead>
              <tr>
                <th className="col1">{this.props.texts.prizeName}</th>
                <th className="others">{this.props.texts.wins}</th>
                <th className="others">{this.props.texts.claims}</th>
                <th className="others">{this.props.texts.conversion}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.prizes.length === 0 ?
                <>
                  <tr className="prize-line0" key="0">
                    <td className="prize-name"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="prize-line1" key="1">
                    <td className="prize-name"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="prize-line0" key="2">
                    <td className="prize-name"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="prize-line1" key="3">
                    <td className="prize-name"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="prize-line0" key="4">
                    <td className="prize-name"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </>
                
              : 
                this.state.prizes.map((p, index) => {
                  return this.createPrizeLine(
                    p.name,
                    p.wins,
                    p.claims,
                    p.conversion,
                    index
                  );
                }) 
              }
            </tbody>
          </table>
        </div>
        <div id="question-analytics-holder">
          <h4 className="widget-title">{this.props.texts.questions}</h4>
          <QuestionsAnalytics questions={this.state.questions} textOops={this.props.texts.oops} textNoQuestions={this.props.texts.noQuestions} textNumberAnswers={this.props.texts.numberOfAnswers} textRating={this.props.texts.averageRating}/>
        </div>
        <div id="question-analytics-holder">
          <h4 className="widget-title">{this.props.texts.comments}</h4>
          {this.state.comments !== null ?
             <CommentsAnalytics
             comments={this.state.comments}
           />
          : null}
       
        </div>
      </div>
    );
  }
}

export default Analytics;
