import "./Navbar.css";
import logo from "../images/weely_logo.png";
import { Link } from "react-router-dom";
import { useState } from "react";

import iconSupport from "../images/question-circle.png";
// import iconSupportWhite from "../images/question-circleWhite.png";
import iconAccount from "../images/cogs.png";
import iconAccountWhite from "../images/cogsWhite.png";
import iconWheels from "../images/circle-notch.png";
import iconWheelsWhite from "../images/circle-notchWhite.png";

// import iconSupportPhone from "../images/supportPhone.png";
import iconSupportEmail from "../images/supportEmail.png";

import * as languages from "../languages";





function Navbar(props) {
  const [texts] = useState(languages[props.language].texts.nav)
  const [open, setOpen] = useState(false);
  return (
    <div className="admin-nav">
      <div className="nav-wrapper">
        <img className="nav-logo" src={logo} alt="weely logo"></img>
        <ul className="nav-items">
        
          <li id={props.active === "wheels" ? "active" : ""}>
            <Link to="/home"><img className="navbar-icon" src={props.active === "wheels"? iconWheelsWhite : iconWheels} alt="wheels-icon"/>{texts.wheels} {props.active === "wheels" ? "•" : ""}</Link>
          </li>
      
              <li id={props.active === "account" ? "active" : ""}>
            <Link to="/account"><img className="navbar-icon" src={props.active === "account"? iconAccountWhite : iconAccount} alt="account-icon" />{texts.account} {props.active === "account" ? "•" : ""}</Link>
          </li>
          
        
          <li
            id="support"
            onClick={() => {
              setOpen(true);
            }}
          ><img className="navbar-icon" src={iconSupport} alt="support-icon"/>
            {texts.support}
          </li>
        </ul>
        <div id="support-info" className={open ? "" : "hidden"}>
          <button className="navbar-close-support"
            onClick={() => {
              setOpen(false);
            }}
          >
            X
          </button>
          <div className="support-row">
            <img className="support-icon" src={iconSupportEmail} alt="support-email-icon"/>
            <h6>support@weely.io</h6>
          </div>
          <div className="support-row">
            {/* <img className="support-icon" src={iconSupportPhone} alt="support-phone-icon"/>
            <h6>+41 79 448 5377</h6> */}
          </div>
        </div>
        <div className="nav-footer">
          <div className="nav-languages-container">
                <div className={"nav-language" + (props.language === "englishEU" ? " language-selected" : "")} onClick={() => {props.setLanguage("englishEU")}}>
                  English
                </div>
                <div className="nav-seperator">
                  |
                </div>
                <div className={"nav-language" + (props.language === "french" ? " language-selected" : "")} onClick={() => {props.setLanguage("french")}}>
                  Français
                </div>
            </div>
          <h6 id="logout" onClick={() => {props.logout()}}>
                {texts.logout}
          </h6>
        </div>
        
        
      </div>
      
    </div>
  );
}

export default Navbar;
