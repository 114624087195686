import "./Settings.css";
import { Component } from "react";
import WheelMock from "./WheelMock";
import UploadLogo from "./UploadLogo";
import FeatureLock from "./FeatureLock";

// import ColorInput from "./ColorInput.js";

import { withRouter } from "react-router-dom";


// import weelyLogoSVG from "../images/weelyLogoWhite.svg";
import promoIcon from "../images/promoIcon.svg";
import trashIcon from "../images/trashIcon.png";
import instaIcon from "../images/instaSettings.png";
import fbIcon from "../images/facebookSettings.png";
import googleIcon from "../images/googleSettings.png";
import customIcon from "../images/customSettings.png";





class Settings extends Component {
  constructor(props) {
    super(props);
    this.url = props.url;
    this.user = props.user;
    this.wheel = window.location.href.split("/wheel/")[1];
    this.state = {
      color_a1: "#440063",
      color_a2: "#440063",
      color_a: "#440063",
      color_b1: "#440063",
      color_b2: "#440063",
      color_b: "#440063",
      button_color: "#440063",
      color_bg: "#440063",
      font_color_a: "#440063",
      font_color_b: "#440063",
      header_color: "#440063",
      title_color: "#440063",
      color_c: "#440063",
      instagram: false,
      facebook: false,
      google: false,
      custom: false,
      instagram_url: "",
      facebook_url: "",
      google_url: "",
      custom_url: "",
      custom_text: "",
      imageUrl: "",
      changed: [],
      updated: false,
      prizes: [],
      clientPrizes: [],
      selectedPrize: "",
      prizeSettings: "default",
      newPrizeTitle: "",
      newPrizeDescription: "",
      newPrizeIsPromo: false,
      newPromoType: "multi",
      newPromoCodes: "",
      newPromoPrizeId: "",
      prompt: "",
      questionPrompt: "",
      questions: [],
      clientQuestions: [],
      questionCreation: false,
      newQuestionText: "",
      newQuestionType: "",
      newQuestionTags: [],
      newQuestionTag: "",
      yOffset: 0,
      name: "",
      _id: "",
      tagsCharLength: 0,
      disabled: false,
      displayDeleteWheelPrompt: false,
      deleteValidationInput: ""
    };
    this.getWheelSettings = this.getWheelSettings.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.setState({ yOffset: window.scrollY });
  };

  changeEvent(attribute) {
    this.setState({
      changed: new Set([...this.state.changed, attribute]),
      updated: true,
    });
  }

  logPrompt = (text) => {
    this.setState({
      prompt: text,
    });
    setTimeout(this.clearPrompt, 2000);
  };

  clearPrompt = () => {
    this.setState({
      prompt: "",
    });
  };

  logQuestionPrompt = (text) => {
    this.setState({
      questionPrompt: text,
    });
    setTimeout(this.clearQuestionPrompt, 2000);
  };

  clearQuestionPrompt = () => {
    this.setState({
      questionPrompt: "",
    });
  };

  saveChanges = async () => {
    let json = {};
    json.styling = {};

    for (let atr of this.state.changed) {
      if (atr === "facebook" || atr === "instagram" || atr === "google" ) {
        if (this.state[atr]) {
          json[atr + "_url"] = this.state[atr + "_url"];
        } else {
          json[atr + "_url"] = "";
        }
      }  else if (atr === "custom") {
        if (this.state.custom) {
          json.custom_action_text = this.state.custom_text;
          json.custom_action_url = this.state.custom_url;
        } else {
          json.custom_action_text = "";
          json.custom_action_url = "";
        }
      }else if (atr === "prizes") {
        const prizeModif = this.state.prizes.map((p) => {
          let res = {};
          res.prize_id = p.id;
          res.probability = p.probability * 10;
          return res;
        });
        json.prizes = {};
        json.prizes = prizeModif;
      } else if (atr === "questions") {
        const questionModif = this.state.questions.map((q) => {
          return {
            _id: q.id,
          };
        });
        json.questions = {};
        json.questions = questionModif;
      } else if (atr === "name" || atr === "language" || atr === "timeToSpin" || atr === "timeToClaim" || atr === "timeToExpire" || atr === "isActive") {
        json[atr] = this.state[atr];
      } else {
        json.styling[atr] = this.state[atr];
      }
    }

    console.log(json);
    const request = {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.user.token,
      },
      body: JSON.stringify(json),
    };
    let response = await fetch(this.url + "/wheel/" + this.wheel, request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log(jsonResponse);
      this.getWheelSettings();
      this.setState({
        updated: false,
      });
    }
  };

  componentDidMount() {
    this.getQuestionList();
    this.getPrizeList();
    this.getWheelSettings();
    window.addEventListener("scroll", this.handleScroll);
  }

  createNewPrize = async () => {
    if (this.state.newPrizeTitle.length < 4) {
      this.logPrompt(this.props.texts.error4char);
      return;
    }
    if (this.state.newPrizeTitle.length > 40) {
      this.logPrompt(this.props.texts.error40char);
      return;
    }
    if (this.state.newPrizeDescription.length < 10) {
      this.logPrompt(this.props.texts.error10char);
      return;
    }

    const request = {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.user.token,
      },
      body: JSON.stringify({
        name: this.state.newPrizeTitle,
        description: this.state.newPrizeDescription,
        isPromo: this.state.newPrizeIsPromo
      }),
    };
    let response = await fetch(
      this.url + "/" + this.user._id + "/prize",
      request
    );
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log(jsonResponse);
      this.getPrizeList();
      this.setState({
        newPrizeTitle: "",
        newPrizeDescription: "",
        newPrizeIsPromo: false,
        prizeSettings: "default",
      });
    }
  };

  

  editPromo = async (id) => {
    let promoCodes = this.state.newPromoCodes.split(',');
    if (promoCodes.length > 10000){
      this.logPrompt("5000 Codes Maximum");
      return;
    }
    

    // if (this.state.newPrizeTitle.length < 4) {
    //   this.logPrompt("Veuillez entrer un titre de plus de 3 lettres");
    //   return;
    // }
    // if (this.state.newPrizeTitle.length > 40) {
    //   this.logPrompt("Veuillez entrer un titre de moins de 45 lettres");
    //   return;
    // }
    // if (this.state.newPrizeDescription.length < 10) {
    //   this.logPrompt("Veuillez entrer une description pour vos clients");
    //   return;
    // }

    const request = {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.user.token,
      },
      body: JSON.stringify({
        promoType: this.state.newPromoType,
        promoCodes: promoCodes,
      }),
    };
    let response = await fetch(
      this.url + "/prize/promo/" + id ,
      request
    );
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log(jsonResponse);
      this.getPrizeList();
      this.setState({
        newPromoType: "multi",
        newPromoCodes: "",
        newPromoPrizeId: "",
        prizeSettings: "default",
      });
    }
  };

  createNewQuestion = async () => {
    if (
      this.state.newQuestionType === "" ||
      this.state.newQuestionType === "-"
    ) {
      this.logQuestionPrompt(this.props.texts.errQType);
      return;
    }
    if (this.state.newQuestionText.length < 10) {
      this.logQuestionPrompt(this.props.texts.errQValid);
      return;
    }

    if (
      this.state.newQuestionType === "tags" &&
      this.state.newQuestionTags.length < 2
    ) {
      this.logQuestionPrompt(this.props.texts.errQTags);
      return;
    }

    const qst = {};
    qst.name = this.state.newQuestionText;
    qst.type = this.state.newQuestionType;
    if (qst.type === "tags") {
      qst.tags = this.state.newQuestionTags;
    }

    const request = {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.user.token,
      },
      body: JSON.stringify(qst),
    };
    let response = await fetch(
      this.url + "/" + this.user._id + "/question",
      request
    );
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log(jsonResponse);
      this.getQuestionList();
      this.setState({
        newQuestionTag: "",
        newQuestionTags: [],
        newQuestionType: "",
        newQuestionText: "",
        questionCreation: false,
      });
    }
  };

  onDeleteQuestion = (e) => {
    // if (this.state.questions.length < 3) {
    //   return;
    // }
    this.changeEvent("questions");
    const newQuestions = this.state.questions.filter(
      (p, index) => index !== parseInt(e.target.getAttribute("deleteid"))
    );
    this.setState({
      questions: newQuestions,
    });
  };

  async getWheelSettings() {
    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.user.token,
      },
    };
    let response = await fetch(this.url + "/wheel/" + this.wheel, request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      const wheel = jsonResponse.data;
      let modifs = {};
      if (typeof wheel.facebook_url !== "undefined" && wheel.facebook_url !== "") {
        modifs.facebook = true;
        modifs.facebook_url = wheel.facebook_url;
      }
      if (typeof wheel.instagram_url !== "undefined" && wheel.instagram_url !== "") {
        modifs.instagram = true;
        modifs.instagram_url = wheel.instagram_url;
      }
      if (typeof wheel.google_url !== "undefined" && wheel.google_url !== "") {
        console.log(wheel.google_url);
        modifs.google = true;
        modifs.google_url = wheel.google_url;
      }

      if (typeof wheel.custom_action_url !== "undefined" && wheel.custom_action_url !== "") {
        modifs.custom = true;
        modifs.custom_url = wheel.custom_action_url;
      }

      if (typeof wheel.custom_action_text !== "undefined" && wheel.custom_action_text !== "") {
        modifs.custom = true;
        modifs.custom_text = wheel.custom_action_text;
      }

      modifs.button_color = wheel.styling.button_color;
      modifs.title_color = wheel.styling.title_color;
      modifs.font_color_a = wheel.styling.font_color_a;
      modifs.font_color_b = wheel.styling.font_color_b;
      modifs.header_color = wheel.styling.header_color;
      modifs.color_c = wheel.styling.color_c;
      modifs.color_bg = wheel.styling.color_bg;

      const splitsA = wheel.styling.color_a.split("#");
      modifs.color_a1 = "#" + splitsA[1].slice(0, 6);
      modifs.color_a2 = "#" + splitsA[2].slice(0, 6);

      const splitsB = wheel.styling.color_b.split("#");
      modifs.color_b1 = "#" + splitsB[1].slice(0, 6);
      modifs.color_b2 = "#" + splitsB[2].slice(0, 6);

      const prizes = wheel.prizes.map((p) => {
        return {
          id: p.prize_id._id,
          name: p.prize_id.name,
          description: p.prize_id.description,
          probability: p.probability / 10,
        };
      });
      modifs.prizes = prizes;

      const questions = wheel.questions.map((q) => {
        let qst = {};
        qst.id = q._id;
        qst.text = q.name;
        qst.type = q.type;
        if (q.type === "tags") {
          qst.tags = q.tags;
        }
        return qst;
      });
      modifs.questions = questions;

      modifs.name = wheel.name;
      modifs._id = wheel._id;
      modifs.language = wheel.language;
      modifs.timeToSpin = wheel.time_to_spin;
      modifs.timeToClaim = wheel.time_to_claim;
      modifs.timeToExpire = wheel.time_to_expire;
      modifs.isActive = wheel.is_active;
      modifs.imageUrl = wheel.image_url;




      // Clear Change Log
      modifs.changed = [];
      // console.log(wheel);
      this.setState(modifs);
    }
  }

  generatePrizeArray(prizes) {
    let prizeArray = [];
    let lostIndexes = [];
    switch (prizes.length) {
      case 0:
        lostIndexes = [0, 1, 2, 3, 4, 5, 6, 7];
        break;
      case 1:
        lostIndexes = [1, 2, 3, 4, 5, 6, 7];
        break;
      case 2:
        lostIndexes = [1, 2, 3, 5, 6, 7];
        break;
      case 3:
        lostIndexes = [1, 2, 4, 5, 7];
        break;
      case 4:
        lostIndexes = [1, 3, 5, 7];
        break;
      case 5:
        lostIndexes = [2, 5, 7];
        break;
      case 6:
        lostIndexes = [1, 5];
        break;
      case 7:
        lostIndexes = [4];
        break;
      case 8:
        lostIndexes = [];
        break;
      default:
        break;
    }
    let pIndex = 0;
    for (let i = 0; i < 8; i++) {
      let isLost = false;
      lostIndexes.forEach((lostIndex) => {
        if (lostIndex === i) {
          isLost = true;
        }
      });
      if (isLost) {
        prizeArray.push(this.props.texts.lost);
      } else {
        prizeArray.push(prizes[pIndex]);
        pIndex++;
      }
    }
    return prizeArray;
  }

  async getPrizeList() {
    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.user.token,
      },
    };
    let response = await fetch(
      this.url + "/client/prizes/" + this.user._id,
      request
    );
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      const clientPrizes = jsonResponse.data.map((p) => {
        return {
          id: p._id,
          name: p.name,
          description: p.description,
          isPromo: p.isPromo,
          promoType: p.promoType,
          promoCodes: p.promoCodes
        };
      });

      this.setState({
        clientPrizes: clientPrizes,
      });
    }
  }

  async getQuestionList() {
    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.user.token,
      },
    };
    let response = await fetch(
      this.url + "/client/questions/" + this.user._id,
      request
    );
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log(jsonResponse.data);
      const clientQuestions = jsonResponse.data.map((q) => {
        let qst = {};
        qst.id = q._id;
        qst.text = q.name;
        qst.type = q.type;
        if (q.type === "tags") {
          qst.tags = q.tags;
        }
        return qst;
      });

      this.setState({
        clientQuestions: clientQuestions,
      });
    }
  }

  onDeletePrize = (e) => {
    this.changeEvent("prizes");
    const newPrizes = this.state.prizes.filter(
      (p) => p.id !== e.target.getAttribute("prizeid")
    );
    this.setState({
      prizes: newPrizes,
    });
  };

  async deletePrize(prizeId) {
    var result = window.confirm(this.props.texts.deletePrizeConfirm);
    if (result) {
      try{
        const request = {
          method: "delete",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.user.token,
          },
        };
        let response = await fetch(
          this.url + "/prize/" + prizeId,
          request
        );
        let jsonResponse = await response.json();
        if (jsonResponse.success) {
          this.getPrizeList();
        }
      } catch(err){
        console.log(err)
      }
      
    } else { 
      return;
    }
  }

  async deleteQuestion(questionId) {
    var result = window.confirm(this.props.texts.deleteQuestionConfirm);
    if (result) {
      try{
        const request = {
          method: "delete",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.user.token,
          },
        };
        let response = await fetch(
          this.url + "/question/" + questionId,
          request
        );
        let jsonResponse = await response.json();
        if (jsonResponse.success) {
          this.getQuestionList();
        }
      } catch(err){
        console.log(err)
      }
      
    } else { 
      return;
    }
  }

  onChangeProb = (e) => {
    this.changeEvent("prizes");
    const newPrizes = this.state.prizes.map((p) => {
      if (p.id === e.target.getAttribute("prizeid")) {
        p.probability = parseInt(e.target.value);
        if(p.probability > 100){
          p.probability = 100;
        }else if(p.probability < 0){
          p.probability = 0;
        }
      }
      return p;
    });

    this.setState({
      prizes: newPrizes,
    });
  };

  changeProbInc = (id, isIncreasing) => {
    this.changeEvent("prizes");
    const newPrizes = this.state.prizes.map((p) => {
      if (p.id === id) {
        p.probability = isIncreasing ? p.probability + 2.5 : p.probability - 2.5;
        if(p.probability > 100){
          p.probability = 100;
        }else if(p.probability < 0){
          p.probability = 0;
        }
      }
      return p;
    });
    this.setState({
      prizes: newPrizes,
    });
  }

  onChangeName = (e) => {
    this.changeEvent("name");
    this.setState({
      prizes: e.target.value,
    });
  };

  onChangeLanguage = (e) => {
    this.changeEvent("language");
    this.setState({
      language: e.target.value,
    });
  };

  onChangeTimeToSpin = (e) => {
    this.changeEvent("timeToSpin");
    this.setState({
      timeToSpin: e.target.value,
    });
  };

  onChangeTimeToClaim = (e) => {
    this.changeEvent("timeToClaim");
    this.setState({
      timeToClaim: e.target.value,
    });
  };

  onChangeTimeToExpire = (e) => {
    this.changeEvent("timeToExpire");
    this.setState({
      timeToExpire: e.target.value,
    });
  };

  onDisableWheel = (e) => {
    this.changeEvent("isActive");
    this.setState({
      isActive: !e.target.value,
    });
  };

  displayDeleteWheelPrompt = () => {
    this.setState({
      displayDeleteWheelPrompt: !this.state.displayDeleteWheelPrompt,
      deleteValidationInput: ""
    })
  }

  setPrizeHover = (mouseEntering, prizeIndex) => {
    this.setState({
      selectedPrize: mouseEntering ? prizeIndex : ""
    })
  }

  deleteWheel = async() => {
    if(this.state.deleteValidationInput !== this.state.name){
      return;
    }
    try{
      const request = {
        method: "delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.user.token,
        },
      };
      let response = await fetch(
        this.url + "/wheel/" + this.user._id + "/" + this.state._id,
        request
      );
      let jsonResponse = await response.json();
      if (jsonResponse.success) {
        this.displayDeleteWheelPrompt();
        this.props.history.push('/wheels');
        return;
      }
    }catch(err){
      console.log(err);
    } 
  }

  

  onAddToWheel = (e) => {
    console.log("Adding: " + e.target.getAttribute("prizeid"));
    this.changeEvent("prizes");
    const toAdd = this.state.clientPrizes.filter(
      (p) => p.id === e.target.getAttribute("prizeid")
    )[0];
    this.setState({
      prizes: [
        ...this.state.prizes,
        {
          id: toAdd.id,
          name: toAdd.name,
          description: toAdd.description,
          probability: 10,
        },
      ],
    });
  };

  onAddQuestionToWheel = (e) => {
    const toAddID = e.target.getAttribute("questionid");
    console.log("Adding: " + toAddID);
    if (
      this.state.questions.filter((q) => q.id === toAddID).length > 0 ||
      this.state.questions.length > 3
    ) {
      return;
    }
    this.changeEvent("questions");
    const toAdd = this.state.clientQuestions.filter((q) => q.id === toAddID)[0];
    let qst = {};
    qst.id = toAdd.id;
    qst.text = toAdd.text;
    qst.type = toAdd.type;
    if (qst.type === "tags") {
      qst.tags = toAdd.tags;
    }
    this.setState({
      questions: [...this.state.questions, qst],
    });
  };

  disableWheel = (e) => {
    this.setState({disabled: !this.state.disabled})
  }

  removeLogo = () => {
    this.setState({
      imageUrl: ""
    })
  }


  render() {
    return (
      <div id="settings-holder">
        {this.state.displayDeleteWheelPrompt?
            <div className="delete-wheel-prompt">
              <div className="background-overlay"/>
              <div className="delete-wheel-prompt-window">
                <div>
                  <h4 className="delete-wheel-header">{this.props.texts.deletePromptHeader}</h4>
                  <div className="delete-wheel-text">{this.props.texts.deleteText1}<b>{this.state.name}</b>{this.props.texts.deleteText2}</div>
                  <input className="delete-confirmation-input" type="text" name="deleteConfirmInput" value={this.state.deleteValidationInput} onChange={(e) => {this.setState({deleteValidationInput: e.target.value})}} placeholder={this.props.texts.wheelName}></input>
                </div>
                <div className="delete-buttons-container">
                  <div onClick={this.displayDeleteWheelPrompt} className="delete-cancel-button">{this.props.texts.deleteCancel}</div>
                  <div onClick={this.deleteWheel} className="delete-confirm-button">{this.props.texts.deleteWheelConfirm}</div>
                </div>
              </div>
            </div>
          :
          ""
        }
     
        <div id="save-holder">
          <button
            onClick={this.saveChanges}
            disabled={this.state.updated ? "" : "disabled"}
          >
            {this.props.texts.saveSettings}
          </button>
        </div>
        <div id="top-settings">
          <div id="left-panel">
            <h4>{this.props.texts.simulator}</h4>
            <div id="wheel-holder">
              <div
                id="wheel-preview"
                style={{
                  backgroundColor: this.state.color_bg,
                  marginTop: Math.max(window.scrollY - 250, 0),
                }}
              >
                <div className="wheel-logo-container">
                  <img src={this.state.imageUrl} className="wheel-logo-preview" alt="wheel-logo-visualiser"/>
                </div>
                <h2 id="header-preview" style={{ color: "#4A4A4A" }}>
                 {this.props.texts.tryYourLuck}
                  <span
                    id="title-preview"
                    style={{ color: this.state.title_color }}
                  >
                    {this.state.name}.
                  </span>
                </h2>
                <div id="wheelMockContainer">
                  <WheelMock
                    prizes={this.generatePrizeArray(
                      this.state.prizes.map((p) => p.name)
                    )}
                    color_c={this.state.color_c}
                    color_bg={this.state.color_bg}
                    color_a={
                      "linear-gradient(-45deg, " +
                      this.state.color_a1 +
                      " 0%, " +
                      this.state.color_a2 +
                      " 100%)"
                    }
                    color_b={
                      "linear-gradient(-45deg, " +
                      this.state.color_b1 +
                      " 0%, " +
                      this.state.color_b2 +
                      " 100%)"
                    }
                    color
                    font_color_a={this.state.font_color_a}
                    font_color_b={this.state.font_color_b}
                  ></WheelMock>
                </div>
                <button
                  className="primary"
                  style={{
                    backgroundColor: this.state.button_color,
                  }}
                >
                  {this.props.texts.spinWheel}
                </button>
              </div>
            </div>
          </div>

          <div id="setting-widgets">
            <div id="widget-top">
              <h4 className="settings-feature">{this.props.texts.colorSettings}<FeatureLock subscriptionType={this.props.subscriptionType}/></h4>
              <div className={"widget-holder" + (this.props.subscriptionType.includes("Free") || this.props.subscriptionType.includes("Growth") ? " settings-locked" : "")} id="color-widget">
                <div id="left-color-column">
                  <div className="color-line">
                    <label className="color-line-label" htmlFor="color_a1">{this.props.texts.dialA1}</label>
                    <div className="color-input-container-settings">
                      <div style={{"background": this.state.color_a1}} className="color-input-standin">
                        <input className="color-input-picker" type="color" name="color_a1" value={this.state.color_a1} onChange={(e) => {
                          this.changeEvent("color_a");
                          this.setState({
                            color_a1: e.target.value,
                            color_a:
                              "linear-gradient(-45deg, " +
                              e.target.value +
                              " 0%, " +
                              this.state.color_a2 +
                              " 100%)",
                          });
                        }}/>
                      </div>
                      <input className="color-input-text" type="text" name="color_a1" value={this.state.color_a1} onChange={(e) => {
                        this.changeEvent("color_a");
                        this.setState({
                          color_a1: e.target.value,
                          color_a:
                            "linear-gradient(-45deg, " +
                            e.target.value +
                            " 0%, " +
                            this.state.color_a2 +
                            " 100%)",
                        });
                      }}/>
                    </div>
                  
                              

                  </div>
                  <div className="color-line">
                    <label className="color-line-label" htmlFor="color_b1">{this.props.texts.dialB1}</label>
                    <div className="color-input-container-settings">
                      <div style={{"background": this.state.color_b1}} className="color-input-standin">
                        <input className="color-input-picker" type="color" name="color_b1" value={this.state.color_b1} onChange={(e) => {
                          this.changeEvent("color_b");
                          this.setState({
                            color_b1: e.target.value,
                            color_b:
                              "linear-gradient(-45deg, " +
                              e.target.value +
                              " 0%, " +
                              this.state.color_b2 +
                              " 100%)",
                          });
                        }}/>
                      </div>
                      <input className="color-input-text" type="text" name="color_b1" value={this.state.color_b1} onChange={(e) => {
                        this.changeEvent("color_b");
                        this.setState({
                          color_b1: e.target.value,
                          color_b:
                            "linear-gradient(-45deg, " +
                            e.target.value +
                            " 0%, " +
                            this.state.color_b2 +
                            " 100%)",
                        });
                      }}/>
                    </div>
                  </div>
                  <div className="color-line">
                    <label className="color-line-label" htmlFor="color_bg">{this.props.texts.background}</label>
                    <div className="color-input-container-settings">
                      <div style={{"background": this.state.color_bg}} className="color-input-standin">
                        <input className="color-input-picker" type="color" name="color_bg" value={this.state.color_bg} onChange={(e) => {
                        this.changeEvent("color_bg");
                        this.setState({ color_bg: e.target.value });
                      }}/>
                      </div>
                      <input className="color-input-text" type="text" name="color_bg" value={this.state.color_bg} onChange={(e) => {
                        this.changeEvent("color_bg");
                        this.setState({ color_bg: e.target.value });
                      }}/>
                    </div>
                  </div>
                  <div className="color-line">
                    <label className="color-line-label" htmlFor="font_color_a">{this.props.texts.fontA}</label>
                    <div className="color-input-container-settings">
                      <div style={{"background": this.state.font_color_a}} className="color-input-standin">
                        <input className="color-input-picker" type="color" name="font_color_a" value={this.state.font_color_a} onChange={(e) => {
                          this.changeEvent("font_color_a");
                          this.setState({ font_color_a: e.target.value });
                        }}/>
                      </div>
                      <input className="color-input-text" type="text" name="font_color_a" value={this.state.font_color_a} onChange={(e) => {
                        this.changeEvent("font_color_a");
                        this.setState({ font_color_a: e.target.value });
                      }}/>
                    </div>
                  </div>
                  <div className="color-line">
                    <label className="color-line-label" htmlFor="header_color">{this.props.texts.header}</label>
                    <div className="color-input-container-settings">
                      <div style={{"background": this.state.header_color}} className="color-input-standin">
                        <input className="color-input-picker" type="color" name="header_color" value={this.state.header_color} onChange={(e) => {
                        this.changeEvent("header_color");
                        this.setState({ header_color: e.target.value });
                      }}/>
                      </div>
                      <input className="color-input-text" type="text" name="header_color" value={this.state.header_color} onChange={(e) => {
                        this.changeEvent("header_color");
                        this.setState({ header_color: e.target.value });
                      }}/>
                    </div>
                  </div>
                  <div className="color-line">
                    <label className="color-line-label" htmlFor="color_c">{this.props.texts.wheelRim}</label>
                    <div className="color-input-container-settings">
                      <div style={{"background": this.state.color_c}} className="color-input-standin">
                        <input className="color-input-picker" type="color" name="color_c" value={this.state.color_c} onChange={(e) => {
                        this.changeEvent("color_c");
                        this.setState({ color_c: e.target.value });
                      }}/>
                      </div>
                      <input className="color-input-text" type="text" name="color_c" value={this.state.color_c} onChange={(e) => {
                        this.changeEvent("color_c");
                        this.setState({ color_c: e.target.value });
                      }}/>
                    </div>
                  </div>
                </div>
                <div id="right-color-column">
                  <div className="color-line">
                    <label className="color-line-label" htmlFor="color_a2">{this.props.texts.dialA2}</label>
                    <div className="color-input-container-settings">
                      <div style={{"background": this.state.color_a2}} className="color-input-standin">
                        <input className="color-input-picker" type="color" name="color_a2" value={this.state.color_a2} onChange={(e) => {
                        this.changeEvent("color_a");
                        this.setState({
                          color_a2: e.target.value,
                          color_a:
                            "linear-gradient(-45deg, " +
                            this.state.color_a1 +
                            " 0%, " +
                            e.target.value +
                            " 100%)",
                        });
                      }}/>
                      </div>
                      <input className="color-input-text" type="text" name="color_a2" value={this.state.color_a2} onChange={(e) => {
                        this.changeEvent("color_a");
                        this.setState({
                          color_a2: e.target.value,
                          color_a:
                            "linear-gradient(-45deg, " +
                            this.state.color_a1 +
                            " 0%, " +
                            e.target.value +
                            " 100%)",
                        });
                      }}/>
                    </div>
                  </div>
                  <div className="color-line">
                    <label className="color-line-label" htmlFor="color_b2">{this.props.texts.dialB2}</label>
                    <div className="color-input-container-settings">
                      <div style={{"background": this.state.color_b2}} className="color-input-standin">
                        <input className="color-input-picker" type="color" name="color_b2" value={this.state.color_b2} onChange={(e) => {
                        this.changeEvent("color_b");
                        this.setState({
                          color_b2: e.target.value,
                          color_b:
                            "linear-gradient(-45deg, " +
                            this.state.color_b1 +
                            " 0%, " +
                            e.target.value +
                            " 100%)",
                        });
                      }}/>
                      </div>
                      <input className="color-input-text" type="text" name="color_b2" value={this.state.color_b2} onChange={(e) => {
                        this.changeEvent("color_b");
                        this.setState({
                          color_b2: e.target.value,
                          color_b:
                            "linear-gradient(-45deg, " +
                            this.state.color_b1 +
                            " 0%, " +
                            e.target.value +
                            " 100%)",
                        });
                      }}/>
                    </div>
                  </div>
                  <div className="color-line">
                    <label className="color-line-label" htmlFor="button_color">{this.props.texts.button}</label>
                    <div className="color-input-container-settings">
                      <div style={{"background": this.state.button_color}} className="color-input-standin">
                        <input className="color-input-picker" type="color" name="button_color" value={this.state.button_color} onChange={(e) => {
                        this.changeEvent("button_color");
                        this.setState({ button_color: e.target.value });
                      }}/>
                      </div>
                      <input className="color-input-text" type="text" name="button_color" value={this.state.button_color} onChange={(e) => {
                        this.changeEvent("button_color");
                        this.setState({ button_color: e.target.value });
                      }}/>
                    </div>
                  </div>
                  <div className="color-line">
                    <label className="color-line-label" htmlFor="head">{this.props.texts.fontB}</label>
                    <div className="color-input-container-settings">
                      <div style={{"background": this.state.font_color_b}} className="color-input-standin">
                        <input className="color-input-picker" type="color" name="font_color_b" value={this.state.font_color_b} onChange={(e) => {
                        this.changeEvent("font_color_b");
                        this.setState({ font_color_b: e.target.value });
                      }}/>
                      </div>
                      <input className="color-input-text" type="text" name="font_color_b" value={this.state.font_color_b} onChange={(e) => {
                        this.changeEvent("font_color_b");
                        this.setState({ font_color_b: e.target.value });
                      }}/>
                    </div>
                  </div>
                  <div className="color-line">
                    <label className="color-line-label" htmlFor="title_color">{this.props.texts.name}</label>
                    <div className="color-input-container-settings">
                      <div style={{"background": this.state.title_color}} className="color-input-standin">
                        <input className="color-input-picker" type="color" name="title_color" value={this.state.title_color} onChange={(e) => {
                        this.changeEvent("title_color");
                        this.setState({ title_color: e.target.value });
                      }}/>
                      </div>
                      <input className="color-input-text" type="text" name="title_color" value={this.state.title_color} onChange={(e) => {
                        this.changeEvent("title_color");
                        this.setState({ title_color: e.target.value });
                      }}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="widget-superbottom">
              <h4>{this.props.texts.prizeSettings}</h4>
              <div className="widget-holder" id="prize-widget">
                <div id="prize-list">
                  <h6 className="prize-list">{this.props.texts.wheelPrizes}</h6>

                  <ul>
                    {this.state.prizes.map((prize, index) => {
                      return (
                        <li key={index} onMouseEnter={() => this.setPrizeHover(true, index)} onMouseLeave={() => this.setPrizeHover(false, index)}>
                          <h5>{prize.name}</h5>
                          <div className="input-number-container">
                            {this.state.selectedPrize === index ? <div className="input-arrow-button input-arrow-up" onClick={() => this.changeProbInc(prize.id, true)}/> : ""}
                            <input
                              type="number"
                              step="5"
                              min="0"
                              value={prize.probability}
                              prizeid={prize.id}
                              onChange={this.onChangeProb}
                            />
                            {this.state.selectedPrize === index ? <div className="input-arrow-button input-arrow-down" onClick={() => this.changeProbInc(prize.id, false)}/> : ""}
                          </div>
                          <div className="input-number-percent">
                            %
                          </div> 
                          <button
                            onClick={this.onDeletePrize}
                            prizeid={prize.id}
                            className="prize-remove-button"
                          >
                            ×
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div id="prize-selection">
                  <div id="prize-selection-header">
                    <h6 className="prize-list">{this.props.texts.existingPrizes}</h6>
                    <button
                      onClick={() => {
                        if (this.state.prizeSettings === "default") {
                          this.setState({ prizeSettings: "create" });
                        } else {
                          this.setState({ prizeSettings: "default" });
                        }
                      }}
                    >
                      {this.state.prizeSettings === "default" ? this.props.texts.newPrize : this.props.texts.cancel}
                    </button>
                  </div>
                  
                  {this.state.prizeSettings === "default" ? (
                    <ul>
                      {this.state.clientPrizes.map((p, index) => {
                        return (
                          <li key={index} className="prize-li">
                            <div className="prize-top-row">
                              <div className="prize-add-prize-button" prizeid={p.id} onClick={this.onAddToWheel}>
                                + {" "}{this.props.texts.addPrize}
                              </div>
                              {
                                typeof p.isPromo !== 'undefined' && p.isPromo ?  (
                                  <div className="prize-promo-button" onClick={() => {this.setState({ newPromoPrizeId: p.id, prizeSettings: "promo", newPromoType: typeof p.promoType !== 'undefined' ? p.promoType : "multi", newPromoCodes: typeof p.promoCodes !== 'undefined' ? p.promoCodes.join() : ""} )}}>
                                    <img className="promo-icon" alt="promo" src={promoIcon}/>
                                    <div className="promo-codes-number">{p.promoCodes.length}</div>
                                  </div>
                               
                                ) : ""
                              }
                              
                              <div className="prize-delete-button" onClick={() => {this.deletePrize(p.id)}}>
                                <img className="trash-icon" alt="trash" src={trashIcon}/>
                              </div>
                              
                              
                              
                            { /* <button
                                prizeid={p.id}
                                onClick={this.onAddToWheel}
                              >+
                            </button>*/}
                            </div>
                            <div className="prize-existing-bottom-container">
                              <div className="prize-existing-name">
                                {p.name}
                              </div>
                              <div className="prize-existing-line"/>
                              <div className="prize-existing-description">
                              {p.description}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : ""}




                  {this.state.prizeSettings === "create" ? (
                    <div id="prize-create">
                      <label for="prize-title">{this.props.texts.prizeName}</label>
                      <input
                        type="text"
                        name="prize-title"
                        id="title"
                        value={this.state.newPrizeTitle}
                        onChange={(e) => {
                          this.setState({
                            newPrizeTitle: e.target.value,
                          });
                        }}
                      ></input>
                      <label for="prize-description">{this.props.texts.prizeDescription}</label>
                      <textarea
                        name="prize-description"
                        id="description"
                        value={this.state.newPrizeDescription}
                        onChange={(e) => {
                          this.setState({
                            newPrizeDescription: e.target.value,
                          });
                        }}
                      ></textarea>
                      <div className="checkbox-promo-container">
                        <div className="checkbox-promo">
                          <input
                            type="checkbox"
                            name="promo"
                            checked={this.state.newPrizeIsPromo}
                            onChange={(e) => {
                              this.setState({
                                newPrizeIsPromo: e.target.checked,
                              });
                            }}/>
                        </div>
                        <label for="promo">{this.props.texts.promoCode}</label>
                      </div>
                      
                      <h5 id="prompt">{this.state.prompt}</h5>
                      <button onClick={this.createNewPrize}>
                      {this.props.texts.createPrize}
                      </button>
                    </div>
                  ) : "" }

                  {this.state.prizeSettings === "promo" ? (
                    <div id="prize-create">
                      <label for="prize-title">{this.props.texts.promoType}</label>
                      <select
                        value={this.state.newPromoType}
                        id="promo-selector"
                        onChange={(e) => {
                          this.setState({
                            newPromoType: e.target.value,
                          });
                        }}
                      >
                        <option value="multi">{this.props.texts.promoMulti}</option>
                        <option value="single">{this.props.texts.promoSingle}</option>
                      </select>
                      <label for="promoCodes">{this.state.newPromoType === "multi" ? this.props.texts.promoMultiLabel: this.props.texts.promoSingleLabel}</label>
                      <textarea
                        className={this.state.newPromoType === "single" ? "" : "single-text-area"}
                        name="prize-description"
                        id="description"
                        value={this.state.newPromoCodes}
                        onChange={(e) => {
                          this.setState({
                            newPromoCodes: e.target.value,
                          });
                        }}
                        
                      ></textarea>
                      
                      <h5 id="prompt">{this.state.prompt}</h5>
                      <button onClick={() => {this.editPromo(this.state.newPromoPrizeId)}}>
                        {this.state.newPromotype === "single" ? "Ajouter Codes" : "Ajouter Code"}
                      </button>
                    </div>
                  ) : "" }
                </div>
              </div>
            </div>

            <div id="widget-superbottom">
              <h4>{this.props.texts.questionSettings}</h4>
              <div className="widget-holder" id="question-widget">
                <div id="question-list">
                  <h6 className="question-list">{this.props.texts.wheelQuestions}</h6>
                  <ul>
                    {this.state.questions.map((question, index) => {
                      return (
                        <li key={index}>
                          <div>
                            <h6
                              className="q-type"
                              id={
                                question.type === "tags"
                                  ? "tag-question"
                                  : "smile-question"
                              }
                            >
                              {question.type === "tags" ? "TAGS" : "SMILE"}
                            </h6>

                            <h5>{question.text}</h5>
                            
                          </div>
                          <button
                            deleteid={index}
                            onClick={this.onDeleteQuestion}
                          >
                            ×
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div id="question-selection">
                  <div id="question-selection-header">
                    <h6 className="prize-list">{this.props.texts.existingQuestions}</h6>
                    <button
                      onClick={() => {
                        if (this.state.questionCreation) {
                          this.setState({ questionCreation: false });
                        } else {
                          this.setState({ questionCreation: true });
                        }
                      }}
                    >
                      {this.state.questionCreation
                        ? this.props.texts.deleteCancel
                        : this.props.texts.newQuestion}
                    </button>
                  </div>
                  {this.state.questionCreation ? (
                    <div id="question-create">
                      <label for="question-type">{this.props.texts.questionType}</label>
                      <select
                        name="question-type"
                        id="question-type"
                        value={this.state.newQuestionType}
                        onChange={(e) => {
                          this.setState({
                            newQuestionType: e.target.value,
                          });
                        }}
                      >
                        <option value="-">{this.props.texts.selectType}</option>
                        <option value="smiley">{this.props.texts.smiley}</option>
                        <option value="tags">{this.props.texts.tags}</option>
                      </select>
                      <label for="question-title">{this.props.texts.questionText}</label>
                      <input
                        type="text"
                        name="question-title"
                        id="text"
                        value={this.state.newQuestionText}
                        onChange={(e) => {
                          if(e.target.value.length < 71){
                            this.setState({
                              newQuestionText: e.target.value,
                            });
                          }
                        
                        }}
                      >
                      </input>
                      <div id="maxCharQuestionName">
                          {this.state.newQuestionText.length} / 70
                      </div>
                      {this.state.newQuestionType === "tags" ? (
                        <div>
                          <label for="question-tags">{this.props.texts.questionTags}</label>
                          <div id="tag-factory">
                            <input
                              type="text"
                              name="question-tags"
                              id="question-tags"
                              value={this.state.newQuestionTag}
                              onChange={(e) => {
                                let totalChars = 0;
                                this.state.newQuestionTags.forEach((tag)=>{
                                  totalChars = totalChars + tag.length;
                                })
                                totalChars = totalChars + this.state.newQuestionTag.length;
                                console.log(totalChars)
                                if(totalChars < 61){
                                  this.setState({
                                    newQuestionTag: e.target.value,
                                    tagsCharLength: totalChars
                                  });
                                }
                                
                              }}
                            ></input>
                            
                            <button
                              onClick={() => {
                                if (this.state.newQuestionTag.length > 0) {
                                  const toAdd = this.state.newQuestionTag;
                                  this.setState({
                                    newQuestionTags: this.state.newQuestionTags.concat(
                                      [toAdd]
                                    ),
                                    newQuestionTag: "",
                                  });
                                }
                              }}
                            >
                              {this.props.texts.addTag}
                            </button>
                          </div>
                          <div id="maxCharQuestionName">
                              {this.state.tagsCharLength} / 60
                          </div>
                          <ul id="tag-list">
                            {this.state.newQuestionTags.map((t, index) => {
                              return <li key={index}>{t}</li>;
                            })}
                            {this.state.newQuestionTags.length > 0 ? (
                              <li
                                id="correction"
                                onClick={() => {
                                  this.setState({
                                    newQuestionTags: this.state.newQuestionTags.slice(
                                      0,
                                      this.state.newQuestionTags.length - 1
                                    ),
                                  });
                                }}
                              >
                                ⌫ {" "}{this.props.texts.removeTag}
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}

                      <h5 id="prompt">{this.state.questionPrompt}</h5>
                      <button onClick={this.createNewQuestion}>
                      {this.props.texts.createQuestion}
                      </button>
                    </div>
                  ) : (
                    <ul>
                      {this.state.clientQuestions.map((q, index) => {
                        return (
                          <li key={index} className="question-li">
                            <h6
                              className="q-type"
                              id={
                                q.type === "tags"
                                  ? "tag-question"
                                  : "smile-question"
                              }
                            >
                              {q.type === "tags" ? "TAGS" : "SMILE"}
                            </h6>
                            <div className="question-button-container">
                                <button
                                  questionid={q.id}
                                  onClick={this.onAddQuestionToWheel}
                                  className="question-add-button"
                                >
                                  + {" "}{this.props.texts.addQuestion}
                                </button>
                                <div className="trash-icon-question-container" onClick={() => {this.deleteQuestion(q.id)}}>
                                  <img className="trash-icon-question" alt="trash" src={trashIcon}/>
                                </div>
                              </div>
                            <div>
                              <h5>{q.text}</h5>
                              
                              
                            </div>
                            {q.type === "tags" ? (
                              <ul id="display-tag-list">
                                {q.tags.map((t, index) => (
                                  <li key={index}>{t}</li>
                                ))}
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div id="widgets-bottom">
              <h4>{this.props.texts.actionSettings}</h4>
              <div className="widget-holder" id="social-widget">
                <div className="actions-separator-line"/>
                <div className="social-row">
                  <div className="action-label-icon-container" >
                    <div className="action-label-icon">
                      <img alt="insta" src={instaIcon} className={this.state.instagram ? "action-icon" : "action-icon action-disabled"}/>
                      <div className={this.state.instagram ? "action-label" : "action-label action-disabled"}>
                        {this.props.texts.insta}
                      </div>
                    </div> 
                    <label class="switchAction" >
                      <input className="sliderAction" type="checkbox" checked={this.state.instagram}  onChange={() => {
                          let modifs = {};
                          modifs.instagram = !this.state.instagram;
                          this.changeEvent("instagram");
                          this.setState(modifs);
                      }}/>
                      <span className="slider round sliderActionScale"></span>
                    </label>
                    
                  </div>

                  <div className="action-input-label-container">
                    <div className={this.state.instagram ? "" : "action-disabled"}>
                    {this.props.texts.instaUrl}
                    </div>
                    <input
                      type="text"
                      className="social-url"
                      disabled={this.state.instagram ? "" : "disabled"}
                      value={this.state.instagram ? this.state.instagram_url : ""}
                      onChange={(e) => {
                        this.changeEvent("instagram");
                        this.setState({ instagram_url: e.target.value });
                      }}
                    ></input>
                  </div>
                </div>
                <div className="social-row">
                <div className="action-label-icon-container" >
                    <div className="action-label-icon">
                      <img alt="fb" src={fbIcon} className={this.state.facebook ? "action-icon" : "action-icon action-disabled"}/>
                      <div className={this.state.facebook ? "action-label" : "action-label action-disabled"}>
                        {this.props.texts.facebook}
                      </div>
                    </div> 
                    <label class="switchAction" >
                      <input className="sliderAction" type="checkbox" checked={this.state.facebook}  onChange={() => {
                          let modifs = {};
                          modifs.facebook = !this.state.facebook;
                          this.changeEvent("facebook");
                          this.setState(modifs);
                      }}/>
                      <span className="slider round sliderActionScale"></span>
                    </label>
                    
                  </div>

                  <div className="action-input-label-container">
                    <div className={this.state.facebook ? "" : "action-disabled"}>
                    {this.props.texts.facebookUrl}
                    </div>
                    <input
                      type="text"
                      className="social-url"
                      disabled={this.state.facebook ? "" : "disabled"}
                      value={this.state.facebook ? this.state.facebook_url : ""}
                      onChange={(e) => {
                        this.changeEvent("facebook");
                        this.setState({ facebook_url: e.target.value });
                      }}
                    ></input>
                  </div>
                </div>
                <div className="social-row">
                  <div className="action-label-icon-container" >
                    <div className="action-label-icon">
                      <img alt="insta" src={googleIcon} className={this.state.google ? "action-icon" : "action-icon action-disabled"}/>
                      <div className={this.state.google ? "action-label" : "action-label action-disabled"}>
                        {this.props.texts.google}
                      </div>
                    </div> 
                    <label class="switchAction" >
                      <input className="sliderAction" type="checkbox" checked={this.state.google}  onChange={() => {
                          let modifs = {};
                          modifs.google = !this.state.google;
                          this.changeEvent("google");
                          this.setState(modifs);
                      }}/>
                      <span className="slider round sliderActionScale"></span>
                    </label>
                    
                  </div>

                  <div className="action-input-label-container">
                    <div className={this.state.google ? "" : "action-disabled"}>
                    {this.props.texts.googleUrl}
                    </div>
                    <input
                      type="text"
                      className="social-url"
                      disabled={this.state.google ? "" : "disabled"}
                      value={this.state.google ? this.state.google_url : ""}
                      onChange={(e) => {
                        this.changeEvent("google");
                        this.setState({ google_url: e.target.value });
                      }}
                    ></input>
                  </div>
                </div>
                <div className="social-row">
                  <div className="action-label-icon-container" >
                    <div className="action-label-icon">
                      <img alt="insta" src={customIcon} className={this.state.custom ? "action-icon" : "action-icon action-disabled"}/>
                      <div className={this.state.custom ? "action-label" : "action-label action-disabled"}>
                        {this.props.texts.customAction}
                      </div>
                    </div> 
                    <label class="switchAction" >
                      <input className="sliderAction" type="checkbox" checked={this.state.custom}  onChange={() => {
                          let modifs = {};
                          modifs.custom = !this.state.custom;
                          this.changeEvent("custom");
                          this.setState(modifs);
                      }}/>
                      <span className="slider round sliderActionScale"></span>
                    </label>
                    
                  </div>

                  <div className="action-input-label-container">
                    <div className={this.state.custom ? "" : "action-disabled"}>
                    {this.props.texts.customText}
                    </div>
                    <input
                      type="text"
                      className="social-url"
                      disabled={this.state.custom ? "" : "disabled"}
                      value={this.state.custom ? this.state.custom_text : ""}
                      onChange={(e) => {
                        this.changeEvent("custom");
                        this.setState({ custom_text: e.target.value });
                      }}
                    ></input>
                  </div>
                </div>
                <div className="social-row custom-extra-row">
                  <div className="action-input-label-container">
                    <div className={this.state.custom ? "" : "action-disabled"}>
                    {this.props.texts.customUrl}
                    </div>
                    <input
                      type="text"
                      className="social-url"
                      disabled={this.state.custom ? "" : "disabled"}
                      value={this.state.custom ? this.state.custom_url : ""}
                      onChange={(e) => {
                        this.changeEvent("custom");
                        this.setState({ custom_url: e.target.value });
                      }}
                    ></input>
                  </div>
                </div>
          
              </div>
            </div>
            <div id="widgets-bottom">
              <h4>{this.props.texts.generalSettings}</h4>
              <div className="widget-holder" id="general-widget">
                <div className="settings-column-left">
                  <div className="general-row">
                    <div id="general-name">
                      {this.props.texts.wheelName}
                    </div>
                    <div className="general-row-value">
                      <input
                        type="text"
                        className="wheel-name"
                        value={this.state.name ? this.state.name : ""}
                        onChange={(e) => {
                          this.changeEvent("name");
                          this.setState({ name: e.target.value });
                        }}
                      ></input>
                    </div>
                  </div>
                  <div className="general-row">
                    <div id="general-name">
                      {this.props.texts.language}
                    </div>
                    <div className="general-row-value">  
                      <select
                        name="wheel-language"
                        id="language-select"
                        value={this.state.language}
                        onChange={(e) => {
                          this.changeEvent("language");
                          this.setState({ language: e.target.value });
                        }}
                      >
                        <option value="englishEU">English</option>
                        <option value="french">Français</option>
                        <option value="german">Deutsch</option>

                      </select>
                    </div>
                  </div>
                  <div className="general-row logo-upload">
                    <div id="general-name">
                      {this.props.texts.wheelLogo}
                    </div>
                    <div className="general-row-value">
                      <UploadLogo removeLogo={this.removeLogo} imageUrl={this.state.imageUrl} user={this.user} wheel={this.wheel} url={this.url}/>
                    </div>
                  </div>
                  <div className="general-row row-switch">
                    <div id="general-name">
                      {this.props.texts.disableWheel}
                    </div>
                    <div className="general-row-value">
                      <label className="switch">
                        <input id="slider" type="checkbox" checked={!this.state.isActive}  onChange={(e) => {
                          this.changeEvent("isActive");
                          this.setState({ isActive: !this.state.isActive });
                        }}/>
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="settings-column-right">
                  <div className="general-row">
                    <div id="general-name">
                        {this.props.texts.timeBetween}
                      </div>
                      <div className="general-row-value">

                        <div className="input-days-container">
                          <input className="input-days"
                            type="number"
                            step="0.5"
                            min="0"
                            value={this.state.timeToSpin}
                            onChange={(e) => {
                              this.changeEvent("timeToSpin");
                              this.setState({ timeToSpin: e.target.value });
                            }}
                          /> 
                          <div className="input-days-text">
                          </div>
                        </div>
                      </div>
                  </div>
                  <div className="general-row">
                    <div id="general-name">
                    {this.props.texts.timeToClaim}
                      </div>
                      <div className="general-row-value">
                        <div className="input-days-container">
                          <input className="input-days"
                            type="number"
                            step="0.1"
                            min="0"
                            value={this.state.timeToClaim}
                            onChange={(e) => {
                              this.changeEvent("timeToClaim");
                              this.setState({ timeToClaim: e.target.value });
                            }}
                          />
                          <div className="input-days-text">
                          </div>
                      </div>
                      </div>
                  </div>
                  <div className="general-row">
                    <div id="general-name">
                      {this.props.texts.timeToExpire}
                    </div>
                    <div className="general-row-value">

                      <div className="input-days-container">
                        <input className="input-days"
                          type="number"timeToExpire
                          step="1"
                          min="1"
                          value={this.state.timeToExpire}
                          onChange={(e) => {
                            this.changeEvent("timeToExpire");
                            this.setState({ timeToExpire: e.target.value });
                          }}
                        />
                        <div className="input-days-text">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="general-row">
                    <div id="general-name" onClick={this.displayDeleteWheelPrompt}>
                      {this.props.texts.deleteWheel}
                    </div>
                    <div className="general-row-value">
                      <button onClick={this.displayDeleteWheelPrompt} id="delete-wheel-button">
                        {this.props.texts.deleteWheelButton}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Settings);
