import "./Wheels.css";
import "./Account.css";
import "./Global.css";

import * as languages from "./languages";

import { Component } from "react";
import Navbar from "./components/Navbar";
import ChangePlan from "./components/ChangePlan";
import UpdatePaymentDetails from "./components/UpdatePaymentDetails";


import { withRouter } from "react-router-dom";


import countries from "./Countries";
// import editIcon from "./images/editInfo.png";
import weelyIcon from "./images/weelyIcon.png";
import addOnsIcon from "./images/addOnsIcon.png";
// import paymentDetailsIcon from "./images/paymentDetailsIcon.png";
import receiptsIcon from "./images/receiptsIcon.png";

import helpIcon from "./images/helpIcon.png";
// import extraWheelIcon from "./images/wheelIcon.png";
// import extraSpinsIcon from "./images/extraSpinsIcon.png";
// import selectorArrow from "./images/selectorArrow.png";

import amex from "./images/paymentMethods/amex.png";
import apple from "./images/paymentMethods/apple.png";
import diners from "./images/paymentMethods/diners.png";
import discover from "./images/paymentMethods/discover.png";
import jcb from "./images/paymentMethods/jcb.png";
import mada from "./images/paymentMethods/mada.png";
import maestro from "./images/paymentMethods/maestro.png";
import mastercard from "./images/paymentMethods/mastercard.png";
import paypal from "./images/paymentMethods/paypal.png";
import union from "./images/paymentMethods/union.png";
import visa from "./images/paymentMethods/visa.png";







import loader from "./images/loading.gif";

// import { thisTypeAnnotation } from "@babel/types";



const paymentMethods = {
  amex: amex,
  apple: apple,
  diners: diners,
  discover: discover,
  jcb: jcb,
  mada: mada,
  maestro: maestro,
  mastercard: mastercard,
  paypal: paypal,
  union: union,
  visa: visa
}



class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      businessName: "",
      email: "",
      country: "",
      language: "",
      spinCount: 0,
      editing: false,
      overlay: "none",
      planName: "",
      spinPercent: 0,
      planCancelStatus: "",
      requestPending: false,
      displayCancelPrompt: false,
      paymentDetails: {
        paymentType: "n/a", //card, paypal, n/a
        cardType: "n/a",
        cardDigits: "n/a",
        expiryDate: "n/a"
      },
      receipts: [],
      texts: languages[props.language].texts.account
    }

  }
  async componentDidMount() {
    this.getClientInfo();
    let userInfo = {};
     userInfo.payment_information = {
      payment_method: "paypal",
      card_type: "lol",
      last_four_digits: "2345",
      expiry_date: "02/2024"
    };
    this.setPaymentDetails(userInfo);
  }

  toggleInfoEdit  = () =>{
    this.setState({editing: true})
  }

  cancelProfileChanges  = () =>{
    this.setState({
      editing: false,
      firstName: this.state.client.first_name,
      lastName: this.state.client.last_name,
      businessName: this.state.client.name,
      email: this.state.client.email,
      country: this.state.client.country,
      language: this.state.client.language
    })
  }

  openCancelPrompt = () => {
    this.setState({
      displayCancelPrompt: true
    })
  }

  closeCancelPrompt = () => {
    if(this.state.planUpdateStatus === "cancelled"){
      this.props.history.push('/wheels');
    }
    this.setState({
      displayCancelPrompt: false,
    })
  }

  saveProfileChanges  = async () =>{
    this.setState({
      editing: false
    })

    const request = {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.user.token,
      },
      body: JSON.stringify({
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        name: this.state.businessName,
        email: this.state.email,
        country: this.state.country,
        language: this.state.language,
      })
    };
    let response = await fetch(this.props.url + "/client/" + this.props.user._id, request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      this.getClientInfo();
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    this.setState({
      [name]: value,
      editing: true
    });
  }

  setReceiptsFromTransactions = (transactions) => {
    let receipts = [];
    console.log(transactions);
    transactions.forEach((transaction)=> {
      if(transaction.status === "completed" && typeof transaction.payments[0] !== "undefined"){
        let receipt = {};
        receipt.date = transaction.billed_at;
        receipt.amount = parseInt(transaction.payments[0].amount)/100;
        receipt.currency = transaction.currency_code;
        switch(transaction.items[0].price.id){
          case "pri_01hc2fazzys01ycm5aax2xe3ne":
            receipt.product = "Weely Boost Yearly";
            break;
          case "pri_01hc2fa8ck3mn488yckxna1krr":
            receipt.product = "Weely Boost Monthly";
            break;
          case "pri_01hc2f61mx71b741kj3f2g41h3":
            receipt.product = "Weely Growth Yearly";
            break;
          case "pri_01hc2f51e9vx5z0hq8bve7ey4n":
            receipt.product = "Weely Growth Monthly";
            break;
          default:
            receipt.product = "N/A";
            break;
        }
        receipt.transactionId = transaction.id;
        receipts.push(receipt);
      }
    })
    this.setState({
      receipts: receipts
    })
    
  }

  openInvoiceUrl = async (transactionId) => {
 
     const request = {
       method: "get",
       headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
         Authorization: "Bearer " + this.props.user.token,
       },
     };
     let response = await fetch(this.props.url + "/client/" + this.props.user._id + "/invoice/" + transactionId , request);
     let jsonResponse = await response.json();
     if (jsonResponse.success) {
       window.open(jsonResponse.data.url.data.url, "_blank");
     } else{
       console.log("Something went wrong while retrieving client info")
     }
   }

  getClientInfo = async () => {
   console.log(this.props.user.token)

    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.user.token,
      },
    };
    let response = await fetch(this.props.url + "/client/" + this.props.user._id, request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      let str = "";
      switch(jsonResponse.data.client.subscription.name){
        case "Weely Free":
          str = "Weely Free";
          break;
        case "Weely Growth Monthly":
          str = "Weely Growth Monthly";
          break;
        case "Weely Growth Yearly":
          str = "Weely Growth Yearly";
          break;
        case "Weely Boost Monthly":
          str = "Weely Boost Monthly";
          break;
        case "Weely Boost Yearly":
          str = "Weely Boost Yearly";
          break;
        case "Weely Custom":
          str = "Weely Custom Plan";
          break;
        default:
          str = "n/a";
          break;
      }
      if(jsonResponse.data.clientSubscription !== "none"){
        // this.setPaymentDetails(jsonResponse.data.clientSubscription);   HERE ADD PAYMENT DETAILS STUFF
      }
      if(jsonResponse.data.transactions !== null){
        // this.setPaymentDetails(jsonResponse.data.clientSubscription);   HERE ADD PAYMENT DETAILS STUFF
        this.setReceiptsFromTransactions(jsonResponse.data.transactions.data)

        console.log(jsonResponse.data.transactions)
      }else{
        console.log(jsonResponse.data.transactions)
        console.log("NOT HEEREEE")
      }
    
      this.setState({
        client: jsonResponse.data.client,
        firstName: jsonResponse.data.client.first_name,
        lastName: jsonResponse.data.client.last_name,
        businessName: jsonResponse.data.client.name,
        email: jsonResponse.data.client.email,
        country: jsonResponse.data.client.country,
        language: jsonResponse.data.client.language,
        subscription: jsonResponse.data.client.subscription,
        planName: str,
      })
      setTimeout(()=>{
        this.initializeSpinPercent();
      }, 100)
    } else{
      console.log("Something went wrong while retrieving client info")
    }
  }

  displayPlanChange  = () => {
    if(this.state.email === ""){
      return;
    }
    this.setState({
      overlay: "planChange"
   })
  }

  displayUpdateDetails  = () => {
    this.setState({
      overlay: "paymentDetailsUpdate"
   })
  }


  getDate = (fullDate) => {
    let date = fullDate.split("T")[0];
    let ymd = date.split("-");
    return ymd[2] + "/" + ymd[1] + "/" + ymd[0];
  }


  renderReceipts = () => {
    if(typeof this.state.subscription === "undefined" || typeof this.state.receipts === "undefined" || this.state.receipts.length === 0){
      return(
        <div className="account-plan-receipts-empty">
          No Receipts To Display ...
        </div>
      )
    } else{
      return(
        <div className="account-plan-receipts">
          {this.state.receipts.map((receipt, index) => {
            let str = receipt.date.split("-");
            let date = str[2].substring(0, 2) + "/" + str[1] + "/" + str[0];
            return(  
              <div className="receipt-tile" onClick={() => {this.openInvoiceUrl(receipt.transactionId)}}>
                <div className="receipt-header">
                  <div >{date}</div>
                  <div>{receipt.amount} {receipt.currency}</div>
                </div>
                <div className="receipt-product-name">
                  {receipt.product}
                </div>
              </div>
            )
          })}
        </div>
      )
      
    }
  }


  returnToAccountScreen = async() => {
    this.setState({
      overlay: "none",
      spinPercent: 0
    })
    this.closeCancelPrompt();

    this.getClientInfo();
  }


  initializeSpinPercent = () => {
    if(this.state.subscription === undefined || this.state.subscription.spin_counter === 0){
      return;
    }
    let that = this;
    let i = 0;
    let intervalID = setInterval(function () {
      i++;
      that.setState({spinPercent:  i * that.state.subscription.spin_counter/that.state.subscription.max_spins})
      if (i === 100) {
          window.clearInterval(intervalID);
      }
   }, 15);
  }


  cancelPlan = async() => {
    if(this.state.requestPending){
      return;
    }
    this.setState({requestPending: true});
    const request = {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.user.token,
      }
    };
    let response = await fetch(this.props.url + "/paddle/subscription-cancel/" + this.props.user._id, request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      setTimeout(() => {
        this.getClientInfo();
        this.setState({
          requestPending: false,
          planCancelStatus: "cancelled"
        });
      }, 1000)
      
    } else {
      this.setState({
        planCancelStatus: "cancel failed",
        requestPending: false
      })
    }
  }

  setPaymentDetails = (userInfo) => {
    if(typeof(userInfo) === "undefined" || typeof(userInfo.payment_information) === "undefined" ){
      return;
    }
    const paymentInfo = userInfo.payment_information;
    console.log(paymentInfo)
    let paymentDetails = {
      paymentType: "n/a",
      cardType: "n/a",
      cardDigits: "n/a",
      expiryDate: "n/a"
    };
    if(paymentInfo.payment_method === "card"){
      paymentDetails = {
        paymentType: "card",
        cardType: typeof(paymentInfo.card_type) === "string" && paymentInfo.card_type !== "" ? paymentInfo.card_type : "n/a",
        cardDigits: typeof(paymentInfo.last_four_digits) === "string" && paymentInfo.last_four_digits !== "" ? paymentInfo.last_four_digits : "n/a",
        expiryDate: typeof(paymentInfo.expiry_date) === "string" && paymentInfo.expiry_date !== "" ? paymentInfo.expiry_date : "n/a"
      }
    }
    if(paymentInfo.payment_method === "paypal"){
      paymentDetails.paymentType = "paypal"
    }
    this.setState({
      paymentDetails: paymentDetails
    })
  }

  renderPaymentDetails = () => {
    switch(this.state.paymentDetails.paymentType){
      case "card":
        return(
          <div className="payment-details-container"> 
            <div className="payment-details-left-column">
              <div className="payment-details-card-number">
              •••• •••• •••• {this.state.paymentDetails.cardDigits === "n/a" ? "????" : this.state.paymentDetails.cardDigits} 
              </div>
              <div className="payment-details-card-expiration">
                Expires on {this.state.paymentDetails.expiryDate === "n/a" ? "????" : this.state.paymentDetails.expiryDate}
              </div>
            </div>
            {typeof paymentMethods[this.state.paymentDetails.cardType] !== "undefined" ? <img src={paymentMethods[this.state.paymentDetails.cardType]} className="payment-details-card" alt="payment-card"/> : <div className="payment-details-card-undefined">Card Type Unknown</div> }
            
          </div>
        )
      case "paypal":
        return(
          <div className="payment-details-container"> 
            <img src={paymentMethods.paypal} className="payment-details-paypal" alt="payment-paypal"/>
          </div>
        )
      default:
        return(
          <div>n/a</div>
        )
    }
  }
  render(){
    return (
      <div id="holder">
        <Navbar active="account" logout={this.props.logout} setLanguage={this.props.setLanguage} language={this.props.language}/>
        {this.state.displayCancelPrompt ? 
          <div className="delete-wheel-prompt">
            <div className="background-overlay"/>
              <div className="delete-wheel-prompt-window">
              {this.state.planCancelStatus === "cancelled" ?
                <div className="overlay-content-container">
                  <div className="cancel-plan-container">
                    <h4 className="cancel-plan-header">{this.state.texts.planSuccessfullyCancelled}</h4>
                    <div className="cancel-plan-text">{this.state.texts.planSuccessfullyCancelledText}<b>{this.getDate(this.state.subscription.valid_to)}</b>.</div>
                  </div>
                  <div className="delete-buttons-container">
                    <div onClick={() => {this.closeCancelPrompt()}}className="delete-cancel-button">{this.state.texts.back}</div>
                  </div>
                </div>
                :
                <div className="overlay-content-container">
                  <div className="cancel-plan-container">
                    <h4 className="cancel-plan-header">{this.state.planCancelStatus !== "cancel failed" ? this.state.texts.cancelHeader : "Something went wrong ..."}</h4>
                    <div className="cancel-plan-text">{this.state.planCancelStatus !== "cancel failed" ? this.state.texts.cancelPrompt : "An error occured while trying to cancel your subscription. Try again, or contact us for assistance."}</div>
                  </div>
                  {this.state.requestPending ?
                    <div className="loading-icon-container">
                      <img src={loader} className="loading-icon" alt="loading-icon"/>
                    </div>
                    :
                    <div className="delete-buttons-container">
                      <div onClick={() => {this.cancelPlan()}}className="delete-cancel-button">{this.state.planCancelStatus === "cancel failed" ? this.state.texts.tryAgain : this.state.texts.confirm}</div>
                      <div onClick={() => {this.closeCancelPrompt()}} className="delete-confirm-button">{this.state.texts.back}</div>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
         : ""
        }
        
        {
        this.state.overlay === "planChange" ? 
          <div className="admin-body">
            <ChangePlan id="change-plan" url={this.props.url} user={this.props.user} subscription={this.state.subscription} country={this.state.country} email={this.state.email} planName={this.state.planName} returnToAccountScreen={this.returnToAccountScreen} language={this.props.language}/>
          </div>
          : ""
        }
       
         {
        this.state.overlay === "paymentDetailsUpdate" ? 
          <div className="admin-body">
            <UpdatePaymentDetails className="account-overlay" url={this.props.url} user={this.props.user} subscription={this.state.subscription} country={this.state.country} email={this.state.email} planName={this.state.planName} returnToAccountScreen={this.returnToAccountScreen}/>
          </div>
          : ""
        }
        {this.state.overlay === "none" ?
          <div className="admin-body">
          
          <div className="profile-plan-container">
            <h1 >{this.state.texts.header}</h1>
            <div className="account-plan-tiles">
              <div className="account-plan-column">
                <div className="account-plan-details">
                  <div className="account-plan-settings-header">
                    <img src={weelyIcon} className="account-plan-settings-icon" alt="weely-icon"/>
                    {this.state.texts.planDetails}
                  </div>
                  <div className="spin-gauge-container">
                    <div className="spin-gauge-bg" style={{"background": "conic-gradient(#670FF7 0.00% " + this.state.spinPercent + "%, #fff 0.00% 0.55%)"}}>
                      <div className="spin-gauge-middle">
                        <div><b>{this.state.texts.monthlySpins}</b></div>
                        <div>{typeof(this.state.subscription) !== "undefined" ? this.state.subscription.spin_counter : ""}/{typeof(this.state.subscription) !== "undefined" ? this.state.subscription.max_spins : ""}</div>
                      </div>
                    </div>
                  </div>
                  <div className="account-plan-details-content">
                    <div className="account-plan-details-row">
                      <div className="account-plan-details-text-left">{this.state.texts.currentPlan}</div>
                      <div className="account-plan-details-text-right">{typeof(this.state.subscription) !== "undefined" ? this.state.subscription.name : ""}</div>
                    </div>
                    <div className="account-plan-details-row">
                      <div className="account-plan-details-text-left">{this.state.texts.maxSpins}</div>
                      <div className="account-plan-details-text-right">{typeof(this.state.subscription) !== "undefined" ? <div>{this.state.subscription.max_spins}</div> : ""}</div>
                    </div>
                    <div className="account-plan-details-row">
                      <div className="account-plan-details-text-left">{this.state.texts.maxWheels}</div>
                      <div className="account-plan-details-text-right">{typeof(this.state.subscription) !== "undefined" ? <div>{this.state.subscription.max_wheels}</div> : ""}</div>
                    </div>
                    <div className="account-plan-details-row">
                      <div className="account-plan-details-line"/>
                    </div>
                    <div className="account-plan-details-row">
                      <div className="account-plan-details-text-left">{this.state.texts.planStatus}</div>
                      <div className="account-plan-details-text-right">{typeof(this.state.subscription) !== "undefined" ? this.state.subscription.status : ""}</div>
                    </div>
                    <div className="account-plan-details-row">
                      <div className="account-plan-details-text-left">{this.state.texts.nextBilling}</div>
                      <div className="account-plan-details-text-right">{typeof(this.state.subscription) !== "undefined" && this.state.subscription.status !== "cancelled" ? this.getDate(this.state.subscription.valid_to) : "n/a"}</div>
                    </div>
                    <div className="account-plan-details-row">
                      <div className="account-plan-details-text-left">{this.state.texts.validTo}</div>
                      <div className="account-plan-details-text-right">{typeof(this.state.subscription) !== "undefined" ? this.getDate(this.state.subscription.valid_to) : "n/a"}</div>
                    </div>
                  </div>
                  <div className="account-plan-buttons-row">
                      <div className="button-account-plan" onClick={this.displayPlanChange}>{this.state.texts.changePlan}</div>
                      <div className={typeof(this.state.subscription) !== "undefined" && this.state.subscription.status === "active" ? "button-account-plan cancel-changes" : "button-account-plan cancel-changes button-account-disabled"} onClick={this.openCancelPrompt}>{this.state.texts.cancelPlan}</div>
                    </div>
                </div>
              </div>
              <div className="account-plan-column">
                <div className="account-plan-addOns disabled-trial">
                  <div className="account-plan-settings-header">
                    <img src={addOnsIcon} className="account-plan-settings-icon" alt="add-ons-icon"/>
                    {this.state.texts.accountDetails}
                    <img src={helpIcon} className="account-plan-help-icon" alt="help-icon"/>
                  </div>
                  <div className="account-plan-addOns-content">
            {/* {this.state.editing ? "" : <img onClick={() => {this.toggleInfoEdit()}} id="edit-profile-icon"src={editIcon} alt="profile-edit-icon"/>} */}
            <div className="profile-info-row">
              <div className="profile-info-input-label">
                <div className="profile-info-label">{this.state.texts.firstName}</div>
                <input
                      type="text"
                      name="firstName"
                      className="text-input profile-info-input"
                      disabled={""}
                      value={this.state.firstName}
                      onChange={this.handleInputChange}
                   />
              </div>
              <div className="profile-info-input-label">
                <div className="profile-info-label">{this.state.texts.lastName}</div>
                <input
                      type="text"
                      name="lastName"
                      className="text-input profile-info-input"
                      disabled={""}
                      value={this.state.lastName}
                      onChange={this.handleInputChange}
                   />
              </div>
            </div>
            <div className="profile-info-row">
              <div className="profile-info-input-label">
                <div className="profile-info-label">{this.state.texts.businessName}</div>
                <input
                      type="text"
                      name="businessName"
                      className="text-input profile-info-input"
                      disabled={""}
                      value={this.state.businessName}
                      onChange={this.handleInputChange}
                   />
              </div>
              <div className="profile-info-input-label">
                <div className="profile-info-label">{this.state.texts.email}</div>
                <input
                      type="text"
                      name="email"
                      className="text-input profile-info-input"
                      disabled={""}
                      value={this.state.email}
                      onChange={this.handleInputChange}
                   />
              </div>
            </div>
            <div className="profile-info-row">
              <div className="profile-info-input-label">
                <div className="profile-info-label">{this.state.texts.country}</div>
                <select disabled={""} className={this.state.country === "" ? "form-select form-select-placeholder" : "form-select"} name="country" value={this.state.country} onChange={this.handleInputChange} placeHolder="John">
                        <option value="" disabled selected hidden>{this.state.texts.selectCountry}</option>
                        {countries.countryList.map((country)=>{
                          return(<option value={country}>{country}</option>)
                        })}
                </select>
              </div>
              <div className="profile-info-input-label">
                <div className="profile-info-label">{this.state.texts.language}</div>
                <select disabled={""} className={this.state.language === "" ? "form-select form-select-placeholder" : "form-select"}  name="language" value={this.state.language} onChange={this.handleInputChange} placeHolder="John">
                  <option value="" disabled selected hidden>{this.state.texts.selectLanguage}</option>
                  <option value="english">English</option> 
                  {/* <option value="spanish">Español</option>  */}
                  <option value="french">Français</option> 
                  {/* <option value="german">Deutsch</option>  */}
                </select>
              </div>
            </div>
              <div className="profile-info-row">
                <div onClick={this.state.editing ? this.saveProfileChanges : null} className={"button-account " + (this.state.editing? "" : "button-account-disabled")}>{this.state.texts.saveChanges}</div>
                <div onClick={this.state.editing ? this.cancelProfileChanges : null} className={"button-account cancel-changes " + (this.state.editing? "" : "button-account-disabled")}>{this.state.texts.cancel}</div>
              </div> 
                </div>

              </div>
              
            </div>
              

            </div>
            <div className="account-plan-receipts-container">
                <div className="account-plan-settings-header">
                    <img src={receiptsIcon} className="account-plan-settings-icon" alt="receipts-icon"/>
                    {this.state.texts.receipts}
                </div>
                {this.renderReceipts()}
            </div>
          </div>
        </div>
        : ""
        }
        
      </div>
    );
  }
}

export default withRouter(Account);
