import "./CommentsAnalytics.css";
import { Component } from "react";

// import placeholderQuestion from "../images/placeholderQuestion.png";
import searchMagnifyingGlass from "../images/search-magnifying-glass.png";



class CommentsAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: undefined,
      searchTerm: "",
      filteredData: props.comments
    };
  }

  // https://uber.github.io/react-vis/documentation/general-principles/animation


  // selectComment= (e) => {
  //   console.log(this.props.comments)
  //   this.setState({
  //     selected: parseInt(e.target.getAttribute("commentpos")),
  //     isChanging: true
  //   });
  //   setTimeout(() => {
  //     this.setState({
  //       isChanging: false
  //     });
  //   },200)
  // };

   componentWillReceiveProps = (props) => {
      this.setState({
        filteredData: props.comments
      });   
  }

   handleInputChange = (event) => {
    const newSearchTerm = event.target.value;
    let filteredData = this.props.comments.filter(item =>
      item.text.toLowerCase().includes(newSearchTerm.toLowerCase())
    );

    console.log(filteredData);
    this.setState({
      searchTerm: newSearchTerm,
      filteredData: filteredData
    });
    // onFilter(filterData(newSearchTerm));
  };

  //  filterData = (term) => {
  //   return data.filter(item =>
  //     item.text.toLowerCase().includes(term.toLowerCase())
  //   );
  // };

  render() {
    return (
      <div key={this.props.comments} id="comment-analytics-section">
        <div id="comments-searchbar-row">
          <input
            id="comments-searchbar"
            type="text"
            placeholder="Search..."
            value={this.state.searchTerm}
            onChange={this.handleInputChange}
          />
          {this.state.searchTerm === "" ? <img src={searchMagnifyingGlass} id="comments-searchbar-magnifying-glass" alt="search-icon"/> : null}
        </div>
        <ul id="comment-list">
          {
            this.state.filteredData.length === 0 ?
            <li className="placeholder-question-list">
              <div className="placeholder-question-list-line"/>
              <div className="placeholder-question-list-line"/>
              <div className="placeholder-question-list-line"/>

            </li>
            :
            this.state.filteredData.map((c, index) => (
              <div className="comment-container">
                 <li 
                key={index}
                commentpos={index}
                className={this.state.selected === index ? "selected" : ""}
                onClick={this.selectComment}
                >
                  <div className="comment-header-row">
                    <div>
                      {c.date}
                    </div>
                    <div>
                      {c.email}
                    </div>
                  </div>
                  {c.text}
                </li>
                <div className="comment-bubble-triangle"/>
              </div>
            ))
          }
        </ul>

        {/* <div id="data-holder">
          <div id="commentVisuals">
            {
                this.props.comments.length > 0 ? 
                <>{this.state.selected !== undefined ?
                   "hello" 
                   : 
                   ""}</>
                :  
                <div className="placeholder-comments-container">
                  <img  alt="placeholderQuestions" className="placeholder-questions-image" src={placeholderQuestion}></img>
                  <div className="placeholder-questions-label-title"> Oops...</div>
                  <div className="placeholder-questions-label">No comment data retrieved for the given period</div>
                </div>
              }
          </div>
        </div> */}
      </div>
    );
  
  }
}

export default CommentsAnalytics;
