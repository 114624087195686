import "./NewWheel.css";
import { Component } from "react";
import * as languages from "./languages";


class NewWheel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      texts: languages[props.language].texts.newWheel,
      selectedThemeIndex: 0,
      styling: {
        header_color:"#5F29B7",
        title_color:"#FEB910",
        button_color:"#CB4FFF",
        font_color_b:"#FFC22D",
        font_color_a:"#4D10B1",
        color_c:"#670FF7",
        color_b:"linear-gradient(180deg, #a022ff 0%, #670ff7 100%)",
        color_a:"linear-gradient(180deg, #ffdd25 0%, #feb910 100%)",
        color_bg:"#F4EEFF"
      },
      themes: [
        {
          name: "Weely",
          styling: {
            header_color:"#5F29B7",
            title_color:"#FEB910",
            button_color:"#CB4FFF",
            font_color_b:"#FFC22D",
            font_color_a:"#4D10B1",
            color_c:"#670FF7",
            color_b:"linear-gradient(180deg, #a022ff 0%, #670ff7 100%)",
            color_a:"linear-gradient(180deg, #ffdd25 0%, #feb910 100%)",
            color_bg:"#F4EEFF"
          }
        },
        {
          name: "Edamame",
          styling: {
            header_color:"#138602",
            title_color:"#5BCB4A",
            button_color:"#17B625",
            font_color_b:"#309A2B",
            font_color_a:"#ffffff",
            color_a:"linear-gradient(180deg, #00E30A 0%, #299737 100%)",
            color_b:"linear-gradient(180deg, #FFFCF0 0%, #ffffff 100%)",
            color_c:"#309A2B",
            color_bg:"#F1FFEA"
          }
        },
        {
          name: "Fishtick",
          styling: {
            header_color:"#5D50E3",
            title_color:"#3232BD",
            button_color:"#3232BD",
            font_color_b:"#5D50E3",
            font_color_a:"#ffffff",
            color_a: "linear-gradient(180deg, #46E7FF 0%, #3927AF 100%)",
            color_b: "linear-gradient(180deg, #ADF4FF 0%, #ffffff 100%)",
            color_c:"#2240AA",
            color_bg:"#EEF6FF"
          }
        },
        {
          name: "Mono",
          styling: {
            header_color:"#1f1f1f",
            title_color:"#000000",
            button_color:"#212121",
            font_color_b:"#ffffff",
            font_color_a:"#242424",
            color_a: "linear-gradient(-45deg, #ffffff 0%, #f5f5f5 100%)",
            color_b: "linear-gradient(-45deg, #757575 0%, #000000 100%)",
            color_c:"#262626",
            color_bg:"#ffffff"
          }
        },
        {
          name: "Hamburger",
          styling: {
            header_color:"#ff1f1f",
            title_color:"#ff1f1f",
            button_color:"#ff4242",
            font_color_b:"#ffffff",
            font_color_a:"#ff1f1f",
            color_a: "linear-gradient(-45deg, #fcff5c 0%, #fff71a 100%)",
            color_b: "linear-gradient(-45deg, #ff1f1f 0%, #f77950 100%)",
            color_c:"#fd4e4e",
            color_bg:"#fffae0"
          }
        }
      ]

    }
  }

//

  UNSAFE_componentWillMount(){
    this.addExistingWheelsToTemplate();
  }

  // CHANGE LAYOUT FOR THIS. MAYBE MOVE TO WHEEL STYLING OR HAVE A SELECTOR
  addExistingWheelsToTemplate = () => {
    return;
    // this.props.wheels.forEach((wheel) => {
    //   let themes = this.state.themes;
    //   themes.push({
    //     name: wheel.name,
    //     styling: wheel.styling
    //   })
    //   this.setState({
    //     themes: themes
    //   })
    // })
  }

  extractGradientColors = (gradient) => {
    let strings  = gradient.split("#");
    return ["#" + strings[1].substring(0,6), "#" + strings[2].substring(0,6)]
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  renderButtons = () => {
    return(
      <div>
        {
          this.state.themes.map((theme, i) => {
            const buttonClass = "new-wheel-template-button" + (this.state.selectedThemeIndex === i ? " new-wheel-template-button-selected": "");
            return <div onMouseEnter={() => {this.selectTheme(i)}} onMouseLeave={() => {this.selectTheme(0)}} className={buttonClass} style={{"background": theme.styling.button_color}}>{theme.name}</div>
          })
        }
      </div>
    )
  }

  selectTheme = (index) => {
    this.setState({
      styling: this.state.themes[index].styling,
      selectedThemeIndex: index
    })
  }

  createWheel = async() => {
    if(this.state.name === ""){
      return;
    }
    const request = {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.user.token,
      },
      body: JSON.stringify({
        name: this.state.name,
        styling: this.state.styling
      })
    };
    let response = await fetch(this.props.url + "/" + this.props.user._id+  "/wheel", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log("HEHE")
      this.props.closeWheelScreen()
    } else {
      alert("Something went wrong...")
    }
  }

  componentDidMount(){
    setTimeout(() => {
    this.setState({
      styling: this.state.themes[0].styling
    })
    },300)
  }

  render(){
    let colorA = this.extractGradientColors(this.state.styling.color_a)
    let colorB = this.extractGradientColors(this.state.styling.color_b)

    const borderColor = "0px solid " + this.state.styling.font_color_a
    return(
      <div id="new-wheel-main-container">
        <div className="wheel-color-transition" style={{"background": this.state.styling.color_bg, "border":  borderColor}} id="new-wheel-container">
          <div style={{color: this.state.styling.header_color}} onClick={this.props.closeWheelScreen} id="back-to-wheels"> 
            {"<  Back"}
          </div>
          <div id="new-wheel-left-column">
            <div id="new-wheel-title" style={{color: this.state.styling.header_color}}>
              {this.state.texts.header}
            </div>
            <div id="new-wheel-wheel-container" style={{color: this.state.styling.button_color}}>
              <svg id="new-wheel-svg" width="429px" height="431px" viewBox="0 0 429 431" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                  
                  <desc>Created with Sketch.</desc>
                  <defs>
                      <filter x="-1.6%" y="-1.1%" width="103.1%" height="103.1%" filterUnits="objectBoundingBox" id="filter-1">
                          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                          <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                          <feMerge>
                              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                              <feMergeNode in="SourceGraphic"></feMergeNode>
                          </feMerge>
                      </filter>
                      <ellipse id="path-2" cx="222" cy="221.443238" rx="194.676923" ry="195.314993"></ellipse>
                      <filter x="-8.3%" y="-7.0%" width="116.7%" height="116.6%" filterUnits="objectBoundingBox" id="filter-3">
                          <feOffset dx="0" dy="5" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                          <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.216655344 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                      </filter>
                      <ellipse id="path-4" cx="222" cy="222.58543" rx="183.292308" ry="183.893063"></ellipse>
                      <filter x="-9.7%" y="-8.3%" width="119.4%" height="119.3%" filterUnits="objectBoundingBox" id="filter-5">
                          <feMorphology radius="3" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
                          <feOffset dx="0" dy="5" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                          <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                          <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
                          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.216655344 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                      </filter>
                      <linearGradient x1="94.0438688%" y1="25.9061801%" x2="25.3068266%" y2="43.7293424%" id="linearGradient-6">
                          <stop className="wheel-color-transition" stop-color={colorA[0]} offset="0%"></stop>
                          <stop className="wheel-color-transition" stop-color={colorA[1]}  offset="100%"></stop>
                      </linearGradient>
                      <linearGradient x1="50%" y1="0%" x2="50%" y2="96.5317919%" id="linearGradient-7">
                          <stop className="wheel-color-transition" stop-color={colorB[0]}  offset="0%"></stop>
                          <stop className="wheel-color-transition" stop-color={colorB[1]}  offset="100%"></stop>
                      </linearGradient>
                      <ellipse id="path-8" cx="222.569231" cy="222.014334" rx="22.2" ry="22.2727623"></ellipse>
                      <filter x="-40.5%" y="-26.9%" width="181.1%" height="180.8%" filterUnits="objectBoundingBox" id="filter-9">
                          <feOffset dx="0" dy="6" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                          <feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.104279891 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                      </filter>
                      <circle id="path-10" cx="222.5" cy="221.947014" r="20"></circle>
                      <filter x="-45.0%" y="-30.0%" width="190.0%" height="190.0%" filterUnits="objectBoundingBox" id="filter-11">
                          <feOffset dx="0" dy="6" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                          <feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.104279891 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                      </filter>
                  </defs>
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Artboard-2" transform="translate(-190.000000, -179.000000)">
                          <g id="Group" filter="url(#filter-1)" transform="translate(182.000000, 167.000000)">
                              <g id="final-wheel">
                                  <g id="purple-circle">
                                      <use fill="black" fill-opacity="1" filter="url(#filter-3)" xlinkHref="#path-2"></use>
                                      <use className="wheel-color-transition" fill={this.state.styling.color_c} fill-rule="evenodd" xlinkHref="#path-2"></use>
                                  </g>
                                  <g id="white-circle">
                                      <use fill="black" fill-opacity="1" filter="url(#filter-5)" xlinkHref="#path-4"></use>
                                      <use stroke="#FFFFFF" stroke-width="6" fill="#D8D8D8" fill-rule="evenodd" xlinkHref="#path-4"></use>
                                  </g>
                                  <g id="white-triangles" transform="translate(30.738462, 29.554824)" fill="#FFFFFF">
                                      <g id="wheel-tri" transform="translate(183.292308, 0.000000)">
                                          <polygon id="Triangle-3" points="8 0 16 7 0 7"></polygon>
                                      </g>
                                      <g id="wheel-tri" transform="translate(191.261538, 381.492442) scale(1, -1) translate(-191.261538, -381.492442) translate(183.292308, 378.065863)">
                                          <polygon id="Triangle-3" points="8 0 16 7 0 7"></polygon>
                                      </g>
                                      <g id="wheel-tri" transform="translate(325.600000, 59.394033) rotate(45.000000) translate(-325.600000, -59.394033) translate(317.630769, 55.967454)">
                                          <polygon id="Triangle-3" points="8 0 16 7 0 7"></polygon>
                                      </g>
                                      <g id="wheel-tri" transform="translate(324.461538, 326.667181) scale(1, -1) rotate(45.000000) translate(-324.461538, -326.667181) translate(316.492308, 323.240602)">
                                          <polygon id="Triangle-3" points="8 0 16 7 0 7"></polygon>
                                      </g>
                                      <g id="wheel-tri" transform="translate(58.061538, 326.667181) scale(-1, -1) rotate(45.000000) translate(-58.061538, -326.667181) translate(50.092308, 323.240602)">
                                          <polygon id="Triangle-3" points="8 0 16 7 0 7"></polygon>
                                      </g>
                                      <g id="wheel-tri" transform="translate(56.923077, 59.394033) scale(-1, 1) rotate(45.000000) translate(-56.923077, -59.394033) translate(48.953846, 55.967454)">
                                          <polygon id="Triangle-3" points="8 0 16 7 0 7"></polygon>
                                      </g>
                                      <g id="wheel-tri" transform="translate(380.246154, 193.030607) rotate(90.000000) translate(-380.246154, -193.030607) translate(372.276923, 189.604028)">
                                          <polygon id="Triangle-3" points="8 0 16 7 0 7"></polygon>
                                      </g>
                                      <g id="wheel-tri" transform="translate(3.415385, 193.030607) scale(-1, 1) rotate(90.000000) translate(-3.415385, -193.030607) translate(-4.553846, 189.604028)">
                                          <polygon id="Triangle-3" points="8 0 16 7 0 7"></polygon>
                                      </g>
                                  </g>
                                  <g id="colors">
                                      <g id="quadrant-purple" transform="translate(241.002220, 112.081303) rotate(-45.000000) translate(-241.002220, -112.081303) translate(149.002220, 46.581303)" fill="url(#linearGradient-6)">
                                          <g id="Group-2">
                                              <path d="M53.685074,0.177963061 C36.197962,17.7223906 22.2279011,38.7953966 12.926951,62.2411457 C4.58594958,83.2670649 0,106.201222 0,130.209995 C81.4632479,130.209995 142.560684,130.209995 183.292308,130.209995 L53.685074,0.177963061 Z" id="Oval-2" transform="translate(91.646154, 65.193979) scale(-1, 1) translate(-91.646154, -65.193979) "></path>
                                          </g>
                                      </g>
                                      <g id="quadrant-purple" transform="translate(332.906679, 242.075523) rotate(45.000000) translate(-332.906679, -242.075523) translate(240.906679, 176.575523)" fill="url(#linearGradient-6)">
                                          <g id="Group-2">
                                              <path d="M53.685074,0.177963061 C36.197962,17.7223906 22.2279011,38.7953966 12.926951,62.2411457 C4.58594958,83.2670649 0,106.201222 0,130.209995 C81.4632479,130.209995 142.560684,130.209995 183.292308,130.209995 L53.685074,0.177963061 Z" id="Oval-2" transform="translate(91.646154, 65.193979) scale(-1, 1) translate(-91.646154, -65.193979) "></path>
                                          </g>
                                      </g>
                                      <g id="quadrant-purple" transform="translate(202.349365, 332.932818) rotate(135.000000) translate(-202.349365, -332.932818) translate(110.349365, 267.432818)" fill="url(#linearGradient-6)">
                                          <g id="Group-2">
                                              <path d="M53.685074,0.177963061 C36.197962,17.7223906 22.2279011,38.7953966 12.926951,62.2411457 C4.58594958,83.2670649 0,106.201222 0,130.209995 C81.4632479,130.209995 142.560684,130.209995 183.292308,130.209995 L53.685074,0.177963061 Z" id="Oval-2" transform="translate(91.646154, 65.193979) scale(-1, 1) translate(-91.646154, -65.193979) "></path>
                                          </g>
                                      </g>
                                      <g id="quadrant-purple" transform="translate(111.515595, 202.870801) rotate(225.000000) translate(-111.515595, -202.870801) translate(19.515595, 137.370801)" fill="url(#linearGradient-6)">
                                          <g id="Group-2">
                                              <path d="M53.685074,0.177963061 C36.197962,17.7223906 22.2279011,38.7953966 12.926951,62.2411457 C4.58594958,83.2670649 0,106.201222 0,130.209995 C81.4632479,130.209995 142.560684,130.209995 183.292308,130.209995 L53.685074,0.177963061 Z" id="Oval-2" transform="translate(91.646154, 65.193979) scale(-1, 1) translate(-91.646154, -65.193979) "></path>
                                          </g>
                                      </g>
                                      <g id="Group-2" transform="translate(222.000000, 92.375435)" fill="url(#linearGradient-7)">
                                          <path d="M53.685074,0.177963061 C20.5156903,33.4560621 0,79.429328 0,130.209995 C81.4632479,130.209995 142.560684,130.209995 183.292308,130.209995 L53.685074,0.177963061 Z" id="Oval-2" transform="translate(91.646154, 65.193979) scale(-1, 1) translate(-91.646154, -65.193979) "></path>
                                      </g>
                                      <g id="quadrant-orange" transform="translate(285.901853, 314.674392) rotate(90.000000) translate(-285.901853, -314.674392) translate(193.901853, 249.174392)" fill="url(#linearGradient-7)">
                                          <g id="Group-2">
                                              <path d="M53.685074,0.177963061 C20.5156903,33.4560621 0,79.429328 0,130.209995 C81.4632479,130.209995 142.560684,130.209995 183.292308,130.209995 L53.685074,0.177963061 Z" id="Oval-2" transform="translate(91.646154, 65.193979) scale(-1, 1) translate(-91.646154, -65.193979) "></path>
                                          </g>
                                      </g>
                                      <g id="quadrant-orange" transform="translate(157.269986, 129.948252) rotate(-90.000000) translate(-157.269986, -129.948252) translate(65.269986, 64.448252)" fill="url(#linearGradient-7)">
                                          <g id="Group-2">
                                              <path d="M53.685074,0.177963061 C20.5156903,33.4560621 0,79.429328 0,130.209995 C81.4632479,130.209995 142.560684,130.209995 183.292308,130.209995 L53.685074,0.177963061 Z" id="Oval-2" transform="translate(91.646154, 65.193979) scale(-1, 1) translate(-91.646154, -65.193979) "></path>
                                          </g>
                                      </g>
                                      <g id="quadrant-orange" transform="translate(130.000000, 287.295426) rotate(180.000000) translate(-130.000000, -287.295426) translate(38.000000, 221.795426)" fill="url(#linearGradient-7)">
                                          <g id="Group-2">
                                              <path d="M53.685074,0.177963061 C20.5156903,33.4560621 0,79.429328 0,130.209995 C81.4632479,130.209995 142.560684,130.209995 183.292308,130.209995 L53.685074,0.177963061 Z" id="Oval-2" transform="translate(91.646154, 65.193979) scale(-1, 1) translate(-91.646154, -65.193979) "></path>
                                          </g>
                                      </g>
                                  </g>
                                  <g id="center-">
                                      <use fill="black" fill-opacity="1" filter="url(#filter-9)" xlinkHref="#path-8"></use>
                                      <use fill="#FFFFFF" fill-rule="evenodd" xlinkHref="#path-8"></use>
                                  </g>
                                  <g id="center--copy">
                                      <use fill="black" fill-opacity="1" filter="url(#filter-11)" xlinkHref="#path-10"></use>
                                      <use className="wheel-color-transition" fill={this.state.styling.color_c} fill-rule="evenodd" xlinkHref="#path-10"></use>
                                  </g>
                                  <g id="wheel-circles" transform="translate(42.000000, 41.000000)" fill="#FFFFFF">
                                      <g id="wheel-circle" transform="translate(107.015385, 0.000000)">
                                          <ellipse id="Oval" cx="2.27692308" cy="2.28438588" rx="2.27692308" ry="2.28438588"></ellipse>
                                      </g>
                                      <g id="wheel-circle" transform="translate(103.600000, 354.079812)">
                                          <ellipse id="Oval" cx="2.27692308" cy="2.28438588" rx="2.27692308" ry="2.28438588"></ellipse>
                                      </g>
                                      <g id="wheel-circle" transform="translate(1.138462, 102.797365)">
                                          <ellipse id="Oval" cx="2.27692308" cy="2.28438588" rx="2.27692308" ry="2.28438588"></ellipse>
                                      </g>
                                      <g id="wheel-circle" transform="translate(251.600000, 1.142193)">
                                          <ellipse id="Oval" cx="2.27692308" cy="2.28438588" rx="2.27692308" ry="2.28438588"></ellipse>
                                      </g>
                                      <g id="wheel-circle" transform="translate(248.184615, 355.222005)">
                                          <ellipse id="Oval" cx="2.27692308" cy="2.28438588" rx="2.27692308" ry="2.28438588"></ellipse>
                                      </g>
                                      <g id="wheel-circle" transform="translate(355.200000, 107.366136)">
                                          <ellipse id="Oval" cx="2.27692308" cy="2.28438588" rx="2.27692308" ry="2.28438588"></ellipse>
                                      </g>
                                      <g id="wheel-circle" transform="translate(0.000000, 247.855868)">
                                          <ellipse id="Oval" cx="2.27692308" cy="2.28438588" rx="2.27692308" ry="2.28438588"></ellipse>
                                      </g>
                                      <g id="wheel-circle" transform="translate(352.923077, 253.566833)">
                                          <ellipse id="Oval" cx="2.27692308" cy="2.28438588" rx="2.27692308" ry="2.28438588"></ellipse>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </svg>
            </div>
            <div className="new-wheel-name-container">
              <div style={{"color": this.state.styling.title_color}} className="heel-color-transition new-wheel-minititle">
              {this.state.texts.name}
              </div>
              <div className="wheel-color-transition new-wheel-actiontitle" style={{color: this.state.styling.header_color}}>
              {this.state.texts.nameHeader} 
              </div>
              <div className="new-wheel-line"/>
              <div className="wheel-color-transition new-wheel-actiontext" style={{color: this.state.styling.header_color}}>
              {this.state.texts.nameText}
              </div>
            </div>
            <input className="wheel-color-transition text-input" style={{borderColor: this.state.styling.header_color}} type="text" name="name" value={this.state.name} onChange={this.handleInputChange} placeHolder={this.state.texts.placeholder}/>
          </div>
          <div id="new-wheel-right-column">
          <div className="new-wheel-name-container">
              <div style={{"color": this.state.styling.title_color}} className="wheel-color-transition new-wheel-minititle">
              {this.state.texts.theme}
              </div>
              <div className="wheel-color-transition new-wheel-actiontitle" style={{color: this.state.styling.header_color}}>
              {this.state.texts.themeHeader}

              </div>
              <div className="new-wheel-line"/>
              <div className="wheel-color-transition new-wheel-actiontext" style={{color: this.state.styling.header_color}}>
              {this.state.texts.themeText} 
              </div>
              <div id="new-wheel-buttons">
              {this.renderButtons()}
            </div>
            </div>
            
            <div onClick={() => {this.createWheel()}} style={{"background" : this.state.styling.button_color, "opacity": this.state.name === "" ? 0.5 : 1}} className="wheel-color-transition new-wheel-create-button">
            {this.state.texts.createWheel}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NewWheel;
