import { Component } from "react";





import "../Global.css";
import "./ChangePlan.css";




class UpdatePaymentDetails extends Component {
  constructor(props){
    super(props);
    this.state = {
      paymentDetailsTransactionId: null
    }
  }

  componentDidMount(){
    this.getUpdatePaymentDetailsTransactionId();
    // this.renderPaddle();   
  }

  async getUpdatePaymentDetailsTransactionId(){
    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.user.token,
      }
    };
    let response = await fetch(this.props.url + "/paddle/payment-details/" + this.props.user._id, request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log(jsonResponse.data)
      this.setState({
        paymentDetailsTransactionId: jsonResponse.data.transactionId
      });
      this.renderPaddle();
      
    } else {
      alert("NANI")
      this.setState({
        planUpdateStatus: "update failed",
        requestPending: false
      })
    }
  }

  renderPaddle = () => {
    if(this.state.paymentDetailsTransactionId !== null){
      const Paddle = window.Paddle;
      let passthrough = {client_id: this.props.user._id};
      console.log(passthrough);
      console.log(this.props.subscription.update_url);
      Paddle.Checkout.open({
        settings: {
          displayMode: "inline",
          theme: "light",
          allowLogout: false,
          locale: "en",
          frameTarget: "checkout-container",
          frameInitialHeight: 500,
          frameStyle: 'width:65%; min-width:500px; max-height:570px; background-color: #fff; border: none;'
        },
        transactionId: this.state.paymentDetailsTransactionId
      });
    }
    
  //   Paddle.Checkout.open({
  //     settings: {
  //       displayMode: "inline",
  //       theme: "light",
  //       locale: "en",
  //       frameTarget: "checkout-container",
  //       frameInitialHeight: "450",
  //       frameStyle: "width: 100%; min-width: 312px; background-color: transparent; border: none;"
  //     },
  //     items: [
  //       {
  //         priceId: 'pri_01h8hg09q78hk7ryqmda4w4npf',
  //         quantity: 1
  //       },
  //     ]
  // });
  }


  // closePrompt = () => {
  //   if(this.state.planUpdateStatus === "updated"){
  //     this.props.history.push('/wheels');
  //   }
  //   this.setState({
  //     displayUpdatePrompt: false
  //   })
  // }




  render(){ 
    return(
      <div id="change-plan-container">
        <div className="delete-wheel-prompt">
          <div className="background-overlay"/>
            <div className="checkout-overlay-window">
              <div onClick={this.props.returnToAccountScreen} className="back-to-plan-upgrade">{"<  Back"}</div>

              <div className="checkout-container"/>
            </div>
        </div>
      </div>
    )
  }
}
export default UpdatePaymentDetails;
