import "./Wheels.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import NewWheel from "./NewWheel";
import * as languages from "./languages";


function Wheels(props) {
  const [texts] = useState(languages[props.language].texts.wheels)
  const [wheels, setWheels] = useState([]);
  const [maxWheels, setMaxWheels] = useState([]);
  const [addNewWheel, setAddNewWheel] = useState(false);
  const [lockedWheel, setLockedWheel] = useState(false);


  const createCard = (
    name,
    id,
    type,
    spins,
    spinChange,
    prizes,
    prizesChange,
    isLocked
  ) => {
    console.log(isLocked)
    return (
      <div className={("wheel-card" + (isLocked ? " card-locked" : "" ))} key={name}>
        <h5 className="card-link">
          <Link to={"/wheel/" + id}>{texts.more} ›</Link>
        </h5>
        <div className="card-top">
          <div className="top-left">
            <h4 className="wheeltype">{type}</h4>
            <h3 className="wheel-name">{name}</h3>
          </div>
          <div className="top-right"></div>
        </div>
        <h4 className="period-title">{texts.lastWeek}</h4>
        <div className="card-bottom">
          <div className="bottom-section">
            <h6 id="top" className={spinChange > 0 ? "up" : "down"}>
              {spinChange > 0 ? "▲" : "▼"} {spinChange.toFixed(1)}%
            </h6>
            <h5>{spins}</h5>
            <h6 id="bottom">{texts.spins}</h6>
          </div>
          <div className="bottom-section">
            <h6 id="top" className={prizesChange > 0 ? "up" : "down"}>
              {prizesChange > 0 ? "▲" : "▼"} {prizesChange.toFixed(1)}%
            </h6>
            <h5>{prizes}</h5>
            <h6 id="bottom">{texts.prizes}</h6>
          </div>
        </div>
      </div>
    );
  };


  /*
   name,
    id,
    type,
    spins,
    spinChange,
    prizes,
    prizesChange,
    isLocked
  */
  const renderCards = () => {
    return (
      <div className="card-container">
        {wheels.map((wheel) => 
          createCard(
            wheel.name,
            wheel.id,
            "WEELY BASIC",
            wheel.spins,
            wheel.spinChange,
            wheel.claimed,
            wheel.resChange,
            wheel.isLocked
          )
        )}
      </div>
    );
  };

  const getWheels = async () => {

    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.user.token,
      },
    };

    let response = await fetch(
      props.url + "/client/wheels/" + props.user._id,
      request
    );
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      const newWheels = await Promise.all(
        jsonResponse.data.wheels.map(async (wheel) => {
          let res = {};
          res.name = wheel.name;
          res.id = wheel._id;
          res.styling = wheel.styling;
          res.isLocked = wheel.is_locked;
          if (wheel.is_locked){
            setLockedWheel(true);
          }

          // fetch spins for last 14 days

          const request = {
            method: "get",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + props.user.token,
            },
          };

          let response = await fetch(
            props.url +
              "/client/spins/" +
              props.user._id +
              "/" +
              wheel._id +
              "?from=14",
            request
          );
          let jsonResponse = await response.json();
          if (jsonResponse.success) {
            console.log(jsonResponse.data);

            let cutOff = new Date();
            cutOff.setDate(cutOff.getDate() - 7);
            const thisWeekSpins = jsonResponse.data.spins.filter(
              (spin) => new Date(spin.created_at) > cutOff
            );
            const lastWeekSpins = jsonResponse.data.spins.filter(
              (spin) => new Date(spin.created_at) < cutOff
            );
            res.spins = thisWeekSpins.length;
            res.spinChange =
              (thisWeekSpins.length / lastWeekSpins.length - 1) * 100;

            const thisWeekClaims = jsonResponse.data.claimedSpins
              .filter((spin) => spin.is_claimed)
              .filter((s) => s.claimed_at > cutOff);
            const lastWeekClaims = jsonResponse.data.claimedSpins
              .filter((spin) => spin.is_claimed)
              .filter((s) => s.claimed_at < cutOff);
            res.claimed = thisWeekClaims.length;
            res.resChange =
              (thisWeekClaims.length / lastWeekClaims.length - 1) * 100
                ? (thisWeekClaims.length / lastWeekClaims.length - 1) * 100
                : 0;
            
                
            return res;
          }
        })
      );
      setMaxWheels(jsonResponse.data.maxWheels);
      setWheels(newWheels);
    }
  }
 
  useEffect(() => {
    console.log("Calling getWheels")
    getWheels();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const newWheelScreen = () => {
    getWheels();
    setAddNewWheel(!addNewWheel)
  };

  return (
    <div id="holder">
      <Navbar active="wheels" logout={props.logout} setLanguage={props.setLanguage} language={props.language}/>
        {addNewWheel ? 
          <NewWheel wheels={wheels} closeWheelScreen={newWheelScreen} user={props.user} url={props.url} language={props.language}/> 
        :
          <div className="admin-body">
            <div>
              <h1>{texts.header}</h1>
              <h4>{texts.subHeader}</h4>
              {lockedWheel ? 
                <div className="locked-banner">
                  Some of your wheels are locked, as you appear to be over your wheel limit. Delete some wheels or upgrade your subscription to Boost. Contact us for more details.
                </div>
              
              : null
              }
              {wheels.length < maxWheels ? 
                  <button id="new-wheel-button" onClick={() => {newWheelScreen()}}>+</button>
                : ""
              }
              <div id="card-holder">{renderCards()}</div>
            </div>
          </div>
        }
    </div>
  );
}

export default Wheels;
