import React from "react";
import "./WheelMock.css";

class WheelMock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.wheelRef = React.createRef();
    this.scale = "scale(" + (620 + (0.3 *  window.innerHeight))  / window.innerWidth + ")"; //
  }

  render() {
    return (
      <div style={{ transform: "scale(1)" }} className="wheel">
        <div id="left">
          <svg
            className="prizePicker"
            width="74px"
            height="71px"
            viewBox="0 0 74 71"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <desc>Created with Sketch.</desc>
            <defs>
              <path
                d="M46.199937,18.2991121 C49.4884971,18.2991121 63.6612917,49.1738377 62.6492958,51.1978296 C61.6372999,53.2218214 30.7426104,53.1818939 29.7505783,51.1978296 C28.7585461,49.2137653 42.911377,18.2991121 46.199937,18.2991121 Z"
                id="path-1"
              ></path>
              <filter
                x="-110.6%"
                y="-135.2%"
                width="321.2%"
                height="312.2%"
                filterUnits="objectBoundingBox"
                id="filter-2"
              >
                <feOffset
                  dx="0"
                  dy="-10"
                  in="SourceAlpha"
                  result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                  stdDeviation="10.5"
                  in="shadowOffsetOuter1"
                  result="shadowBlurOuter1"
                ></feGaussianBlur>
                <feColorMatrix
                  values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.186594203 0"
                  type="matrix"
                  in="shadowBlurOuter1"
                ></feColorMatrix>
              </filter>
            </defs>
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Triangle-2"
                transform="translate(46.200888, 35.500000) rotate(270.000000) translate(-46.200888, -35.500000) "
              >
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
            </g>
          </svg>
          <div id="mainbox" className="mainbox">
            <div
              style={{ borderColor: this.props.color_c }}
              id="circleBack"
            ></div>

            <div
              style={{ color: this.props.font_color_b }}
              id="prizeLabel1"
              className="prizeLabel"
            >
              <div>{this.props.prizes[0]}</div>
            </div>
            <div
              style={{ color: this.props.font_color_b }}
              id="prizeLabel2"
              className="prizeLabel"
            >
              <div>{this.props.prizes[4]}</div>
            </div>
            <div
              style={{ color: this.props.font_color_b }}
              id="prizeLabel3"
              className="prizeLabel"
            >
              <div>{this.props.prizes[6]}</div>
            </div>
            <div
              style={{ color: this.props.font_color_b }}
              id="prizeLabel4"
              className="prizeLabel"
            >
              <div>{this.props.prizes[2]}</div>
            </div>
            <div
              style={{ color: this.props.font_color_a }}
              id="prizeLabel5"
              className="prizeLabel"
            >
              <div>{this.props.prizes[3]}</div>
            </div>
            <div
              style={{ color: this.props.font_color_a }}
              id="prizeLabel6"
              className="prizeLabel"
            >
              <div>{this.props.prizes[1]}</div>
            </div>
            <div
              style={{ color: this.props.font_color_a }}
              id="prizeLabel7"
              className="prizeLabel"
            >
              <div>{this.props.prizes[7]}</div>
            </div>
            <div
              style={{ color: this.props.font_color_a }}
              id="prizeLabel8"
              className="prizeLabel"
            >
              <div>{this.props.prizes[5]}</div>
            </div>

            <div id="triangle1" className="triangle"></div>
            <div id="triangle2" className="triangle"></div>
            <div id="triangle3" className="triangle"></div>
            <div id="triangle4" className="triangle"></div>
            <div id="triangle5" className="triangle"></div>
            <div id="triangle6" className="triangle"></div>
            <div id="triangle7" className="triangle"></div>
            <div id="triangle8" className="triangle"></div>
            <div
              style={{ backgroundColor: this.props.color_b }}
              id="circle1"
              className="circle"
            ></div>
            <div
              style={{ backgroundColor: this.props.color_b }}
              id="circle2"
              className="circle"
            ></div>
            <div
              style={{ backgroundColor: this.props.color_b }}
              id="circle3"
              className="circle"
            ></div>
            <div
              style={{ backgroundColor: this.props.color_b }}
              id="circle4"
              className="circle"
            ></div>
            <div
              style={{ backgroundColor: this.props.color_b }}
              id="circle5"
              className="circle"
            ></div>
            <div
              style={{ backgroundColor: this.props.color_b }}
              id="circle6"
              className="circle"
            ></div>
            <div
              style={{ backgroundColor: this.props.color_b }}
              id="circle7"
              className="circle"
            ></div>
            <div
              style={{ backgroundColor: this.props.color_b }}
              id="circle8"
              className="circle"
            ></div>

            <div id="box" className="box">
              <div className="box1">
                <span
                  style={{
                    background: this.props.color_b,
                  }}
                  className="span1"
                ></span>
                <span
                  style={{
                    background: this.props.color_b,
                  }}
                  className="span2"
                ></span>
                <span
                  style={{
                    background: this.props.color_b,
                  }}
                  className="span3"
                ></span>
                <span
                  style={{
                    background: this.props.color_b,
                  }}
                  className="span4"
                ></span>
              </div>
              <div className="box2">
                <span
                  style={{
                    background: this.props.color_a,
                  }}
                  className="span5"
                ></span>
                <span
                  style={{
                    background: this.props.color_a,
                  }}
                  className="span6"
                ></span>
                <span
                  style={{
                    background: this.props.color_a,
                  }}
                  className="span7"
                ></span>
                <span
                  style={{
                    background: this.props.color_a,
                  }}
                  className="span8"
                ></span>
              </div>
            </div>

            <div
              style={{ background: this.props.color_c }}
              className="center"
            ></div>
          </div>
        </div>
      </div>
    );
  }
}
export default WheelMock;
