import "./Wheel.css";
import { useState, useEffect } from "react";
import Analytics from "./components/Analytics";
import Settings from "./components/Settings";
import QRCode from "./components/QRCode";
import MailingList from "./components/MailingList";

import Navbar from "./components/Navbar";
import externalLink from "./images/externalLink.svg";

import * as languages from "./languages";


function Wheel(props) {
  const [texts] = useState(languages[props.language].texts);
  const [section, setSection] = useState("analytics");
  const [wheel, setWheel] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("Weely Free");

  useEffect(() => {
    const wheelId = window.location.href.split("/wheel/")[1];

    async function fetchData() {
      const request = {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + props.user.token,
        },
      };
      let response = await fetch(props.url + "/wheel/" + wheelId, request);
      let jsonResponse = await response.json();
      if (jsonResponse.success) {
        console.log(jsonResponse.data)
        setWheel(jsonResponse.data);
        setSubscriptionType(jsonResponse.data._client.subscription.name)
      }
    }
    fetchData();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  //[props.url, props.user.token, wheel]);



  const renderSection = () => {
    if (section === "analytics") {
      return <Analytics url={props.url} user={props.user} texts={texts.wheel.analytics} subscriptionType={subscriptionType}/>;
    }

    if (section === "settings") {
      return <Settings url={props.url} user={props.user} texts={texts.wheel.settings} subscriptionType={subscriptionType}/>;
    }

    if (section === "qrcode") {
      // TODO
      return <QRCode url={props.url} qrCode={wheel._qr} wheelLogo={wheel.image_url} user={props.user} texts={texts.wheel.qr} subscriptionType={subscriptionType}/>;
    }
    if (section === "mailingList") {
      // TODO
      return <MailingList url={props.url}  user={props.user} wheelId={wheel._id} texts={texts.wheel.mailing} subscriptionType={subscriptionType}/>;
    }
  };
  return (
    <div id="holder">
      <Navbar active="wheels" logout={props.logout} setLanguage={props.setLanguage} language={props.language}/>
      <div className="admin-body">
        <div className="header-container">
          <h1>{wheel.name}</h1>
          <img className="external-link" alt="link-to-wheel" src={externalLink} onClick={() => {window.open("https://app.weely.ch/?id=" + wheel._qr)}} />
        </div>

        <ul id="section-selector">
          <li
            className={section === "analytics" ? "selected" : ""}
            onClick={() => setSection("analytics")}
          >
            {texts.wheel.nav.analytics}
          </li>
          <li
            className={section === "settings" ? "selected" : ""}
            onClick={() => setSection("settings")}
          >
            {texts.wheel.nav.settings}
          </li>
          <li
            className={section === "qrcode" ? "selected" : ""}
            onClick={() => setSection("qrcode")}
          >
            {texts.wheel.nav.qr}
          </li>
          <li
            className={section === "mailingList" ? "selected" : ""}
            onClick={() => setSection("mailingList")}
          >
            {texts.wheel.nav.mailing}
          </li>
        </ul>
        {renderSection()}
      </div>
    </div>
  );
}

export default Wheel;
