import "./MailingList.css";
import mailingListIcon from "../images/mailingList.png";
import React from "react";


class MailingList extends React.Component {
  constructor(props){
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      loaded: false
    }
  }

  async componentDidMount(){
    console.log(this.props)
    let t = "all";

    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.user.token,
      }
    };
    let response = await fetch(this.props.url + "/wheel/" + this.props.wheelId + "/subscribers?from=" + t , request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      if(jsonResponse.data.qrStyling !== null){
        console.log(jsonResponse.data);
        this.setState({
          loaded: true,
          mailingList: jsonResponse.data
        });
      } else{
        this.setState({
          loaded: true
        });
      }
    } else {
      alert("Something went wrong while connecting to server..")
    }
  }

   handleDownload = () => {
    const blob = new Blob([this.state.mailingList], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = this.props.wheelId + "-mailing_list";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  render(){
    return (
      <div className="mailing-list-container">
        <img src={mailingListIcon} className="mailing-list-icon" alt="mailing-list-icon"/>
        <div className="mailing-list-header">
          {this.props.texts.header}
        </div> 
        <div className="mailing-list-text">
          {this.props.texts.paragraph}
        </div> 
        <div onClick={this.handleDownload} className="mailing-list-button">
          {this.props.texts.button}
        </div>
      </div>
    );
  }
}

export default MailingList;
