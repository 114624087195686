import "./QuestionsAnalytics.css";
import { Component } from "react";
import {
  LineMarkSeries,
  XAxis,
  YAxis,
  VerticalBarSeries,
  FlexibleXYPlot,
  LabelSeries
} from "react-vis";
import "../../node_modules/react-vis/dist/style.css";
import placeholderQuestion from "../images/placeholderQuestion.png";


class QuestionsAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: undefined,
      answersMaxValue: 0,
      isChanging: false
    };
    console.log(props);
  }
  // https://uber.github.io/react-vis/documentation/general-principles/animation

  selectQuestion = (e) => {
    this.setState({
      selected: parseInt(e.target.getAttribute("questionpos")),
      answersMaxValue: 0,
      isChanging: true
    });
    setTimeout(() => {
      this.setState({
        isChanging: false
      });
    },200)
  };

  getDailySmile = () => {
    let data = [];
    const ans = this.props.questions[this.state.selected].answers;
    let dates = [];
    console.log(ans)
    for (let a of ans) {
      const dte = new Date(a.date);
      const d = dte.getDay() + "-" + dte.getMonth() + "-" + dte.getFullYear();
      dates.push(d);
    }
    const uniques = new Set(dates);

    for (let u of uniques) {
      let matchings = ans.filter((a) => {
        let d = new Date(a.date);
        return (
          d.getDay() === parseInt(u.split("-")[0]) &&
          d.getMonth() === parseInt(u.split("-")[1]) &&
          d.getFullYear() === parseInt(u.split("-")[2])
        );
      });
      let res = {};
      res.x = new Date();
      res.x.setHours(0);
      res.x.setDate(parseInt(u.split("-")[0]));
      res.x.setMonth(parseInt(u.split("-")[1]));
      res.x.setFullYear(parseInt(u.split("-")[2]));
      res.y =
        matchings.reduce((acc, m) => parseInt(m.value) + acc, 0) /
        matchings.length;
      res.count = matchings.length;
      data.push(res);
    }
    console.log("DATA")
    console.log(data);
    return data;
  };

  getTagData = () => {
    console.log(this.props.questions[this.state.selected])
    const ans = this.props.questions[this.state.selected].answers;
    // let tags = [this.props.questions[this.state.selected]];
    let tags = [];

      for (let a of ans) {
        console.log(a.value);
        if(this.props.questions[this.state.selected].type === "tags"){
          tags = tags.concat(a.value.split(","));
        }else{
          tags = tags.concat(a.value.toString());
        }
      }
    
    console.log(tags);

    const uniques = new Set(tags);
    let data = [];
    console.log(uniques);
    for (let u of uniques) {
      let res = {};
      res.x = u;
      res.y = ans.filter((a) => a.value.toString().includes(u)).length;
      if(res.y > this.state.answersMaxValue){
        this.setState({
          answersMaxValue: res.y
        })
      }
      data.push(res);
    }

    if(this.props.questions[this.state.selected].type === "smiley"){
      let missingSmileys = ["1","2","3","4","5"]
      data.forEach((d) => {
        missingSmileys = missingSmileys.filter((value) => value !== d.x)
        console.log(missingSmileys);
      })
      missingSmileys.forEach((smiley) => {
        let res = {};
        res.x = smiley;
        res.y = 0;
        data.push(res);
      })
      data.sort((a,b) => a.x - b.x);
      data.forEach((a, index) => {
        switch(a.x){
          case "1": 
            data[index].x = "😥";
            break;
          case "2": 
            data[index].x = "🙁";
            break;
          case "3": 
            data[index].x = "😐";
            break;
          case "4": 
            data[index].x = "🙂";
            break;
          case "5": 
            data[index].x = "😊";
            break;
          default:
            break;
        }
      })
    }
    console.log("AAAYA")
    console.log(data);
    console.log("AAAVYA")

    return data;
  };

  generateVis = () => {
    console.log(this.props.questions);
    console.log("Herrr e");
    console.log(this.state.selected);
    if (this.props.questions !== undefined) { 
      console.log(this.props.questions[this.state.selected].type)
      if (this.props.questions[this.state.selected].type === "smileyY") {
        console.log(":))))")
        console.log(this.getDailySmile());
        return (
          <FlexibleXYPlot className="yukulele" xType="time">
            <XAxis
              attr="x"
              attrAxis="y"
              orientation="bottom"
              tickTotal={this.getDailySmile().length}
              tickFormat={function tickFormat(d) {
                return d.toLocaleDateString();
              }}
            />
            <YAxis attr="y" attrAxis="x" orientation="left" />
            <LineMarkSeries
              data={this.getDailySmile()}
              curve={"curveMonotoneX"}
              animation
              color="#670ff7"
            ></LineMarkSeries>
          </FlexibleXYPlot>
        );
      } else {
        return (
          <FlexibleXYPlot xType="ordinal" className="yukulele" yDomain={[0, this.state.answersMaxValue * 1.25]}>
            <XAxis attr="x" attrAxis="y" orientation="bottom" className={this.props.questions[this.state.selected].type === "smiley" ? "chart-x-axis" : "chart-x-axis"} style={{fontSize: this.props.questions[this.state.selected].type === "smiley" ?'29px' : "12px"}}/>
            <VerticalBarSeries
              data={this.getTagData()}
              color="#670ff7"
              style={{"text":"white"}}
            />
            <LabelSeries
              data={this.getTagData().map((obj) => {
                return { ...obj, label: obj.y.toString() };
              })}
              labelAnchorX="middle"
              labelAnchorY="text-after-edge"
              style={{fill:'#670ff7'}}
              stroke="#670ff7"
              color="white"
              className={this.state.isChanging ? "chart-labels" : "chart-labels chart-labels-fade"}

            />
          </FlexibleXYPlot>
        );
      }
    }
  };

  getStat = () => {
    if (this.state.selected !== undefined) {
      console.log(this.props.questions[this.state.selected].type)
      if (this.props.questions[this.state.selected].type === "smiley") {
        const answers = this.props.questions[this.state.selected];
        const sum = answers.answers.reduce(
          (acc, a) => parseInt(a.value) + acc,
          0
        );
        const average = sum / answers.answers.length;
        return this.props.textRating + " " + average.toFixed(1);
      } else {
        console.log("HEEERE")
        const tagData = this.getTagData();
        let max = 0;
        for (let d of tagData) {
          if (d.y > max) {
            max = d.y;
          }
        }
        let popular = [];
        for (let d of tagData) {
          if (d.y === max) {
            popular.push(d.x);
          }
        }

        console.log(popular.join("-"));
        return (
          "Most popular answer" +
          (popular.length > 1 ? "s" : "") +
          ": " +
          popular.join(", ")
        );
      }
    }
  };

  render() {
    return (
      <div id="question-analytics-section">
        <ul id="question-list">
          {
            this.props.questions.length === 0 ?
            <li className="placeholder-question-list">
              <div className="placeholder-question-list-line"/>
              <div className="placeholder-question-list-line"/>
              <div className="placeholder-question-list-line"/>

            </li>
            :
            this.props.questions.map((q, index) => (
              <li 
                key={index}
                questionpos={index}
                className={this.state.selected === index ? "selected" : ""}
                onClick={this.selectQuestion}
              >
                {q.name}
              </li>
            ))
          }
        </ul>

        <div id="data-holder">
          <div id="visuals">
                
            {
                this.props.questions.length > 0 ? 
                <>{this.state.selected !== undefined ? this.generateVis() : ""}</>
                :  
                <div className="placeholder-questions-container">
                  <img  alt="placeholderQuestions" className="placeholder-questions-image" src={placeholderQuestion}></img>
                  <div className="placeholder-questions-label-title">{this.props.textOops}</div>
                  <div className="placeholder-questions-label">{this.props.textNoQuestions}</div>
                </div>
              }
          </div>

         
          <div id="metrics">
            
            <div>
              {" "}
              {this.state.selected !== undefined && this.props.questions.length > 0
                ? this.props.textNumberAnswers +" " +
                  this.props.questions[this.state.selected].answers.length
                : ""}
            </div>
            <div>{this.state.selected !== undefined && this.props.questions.length > 0 ? this.getStat() : ""}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionsAnalytics;


