import "./QRCode.css";
import React from "react";
import QR from "qrcode.react";
import QRBackground from "./QRBackground.js";
import QRLogo from "./QRLogo.js";
import ColorInput from "./ColorInput.js";
import qrStep1Icon from "../images/qrStep1Icon.png";
import qrStep2Icon from "../images/qrStep2Icon.png";
import qrStep3Icon from "../images/qrStep3Icon.png";

import qrOnly from "../images/qrOnly.png";
import qrSticker from "../images/qrSticker.png";


import { exportComponentAsPNG } from 'react-component-export-image';
// import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';

const params = {
  html2CanvasOptions: {
      backgroundColor: null,
      scale: 10
    }
};

class QRCode extends React.Component {
  constructor(props){
    super(props);
    this.componentRef = React.createRef();
    this.qrRef = React.createRef();
    this.state = {
      colorA: "#EF2B7A",
      colorB: "#FEB910",
      colorC: "#8D00FF",
      colorD: "#6FB8AF",
      colorBG: "#F4EEFF",
      colorLogo: "#8D00FF",
      colorSteps: "#D33CFF",
      colorLabels: "#3F0F56",
      text1: "SCANNEZ LE CODE QR",
      text2: "TOURNEZ LA ROUE",
      text3: "GAGNEZ UN PRIX",
      language: "fr",
      disablePatterns: false,
      hideLogo: false,
      modified: false,
      loaded: false
    }
  }

  async componentDidMount(){
    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.user.token,
      }
    };
    let response = await fetch(this.props.url + "/client/qr-styling/" + this.props.user._id, request);
    let jsonResponse = await response.json();
    console.log(jsonResponse);
    if (jsonResponse.success) {
      console.log(jsonResponse.data.qrStyling)
      if(jsonResponse.data.qrStyling !== null){
        this.setState({
          colorA: jsonResponse.data.qrStyling.color_a,
          colorB: jsonResponse.data.qrStyling.color_b,
          colorC: jsonResponse.data.qrStyling.color_c,
          colorD: jsonResponse.data.qrStyling.color_d,
          colorBG: jsonResponse.data.qrStyling.color_bg,
          colorLogo: jsonResponse.data.qrStyling.color_logo,
          colorSteps: jsonResponse.data.qrStyling.color_steps,
          colorLabels: jsonResponse.data.qrStyling.color_labels,
          language: jsonResponse.data.qrStyling.language,
          disablePatterns: jsonResponse.data.qrStyling.disable_patterns,
          hideLogo: jsonResponse.data.qrStyling.hide_logo,
          loaded: true
        });
        this.setLanguage(jsonResponse.data.qrStyling.language)
      } else{
        this.setState({
          loaded: true
        });
      }
    } else {
      alert("Something went wrong while connecting to server..")
    }
  }

  saveQr = async() => {
    if(!this.state.modified){
      return;
    }
    let qrStyling = {
      color_a: this.state.colorA,
      color_b: this.state.colorB,
      color_c: this.state.colorC,
      color_d: this.state.colorD,
      color_bg: this.state.colorBG,
      color_logo: this.state.colorLogo,
      color_steps: this.state.colorSteps,
      color_labels: this.state.colorLabels,
      language: this.state.language,
      disable_patterns: this.state.disablePatterns,
      hide_logo: this.state.hideLogo
    }
    const request = {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.user.token,
      },
      body: JSON.stringify({
        qrStyling: qrStyling
      })
    };
    let response = await fetch(this.props.url + "/client/qr-styling/" + this.props.user._id, request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      this.setState({
        modified: false
      });

    } else {
      alert("Something went wrong while saving QR..")
    }
  }

  resetQr = () => {
    this.setState({
      colorA: "#EF2B7A",
      colorB: "#FEB910",
      colorC: "#8D00FF",
      colorD: "#6FB8AF",
      colorBG: "#F4EEFF",
      colorLogo: "#8D00FF",
      colorSteps: "#D33CFF",
      colorLabels: "#3F0F56",
      disablePatterns: false,
      hideLogo: false,
      modified: true
    })
  }

  setLanguage = (lang) => {
    let text1 = "";
    let text2 = "";
    let text3 = "";
    switch(lang){
      case "fr":
        text1 = "SCANNEZ LE CODE QR";
        text2 = "TOURNEZ LA ROUE";
        text3 = "GAGNEZ UN PRIX";
        break;
      case "en":
        text1 = "SCAN QR CODE";
        text2 = "SPIN WHEEL";
        text3 = "WIN A PRIZE";
        break;
      case "de":
        text1 = "QR-CODE SCANNEN";
        text2 = "RAD DREHEN";
        text3 = "PREIS GEWINNEN";
        break;
      case "es":
        text1 = "ESCANEAR CÓDIGO QR";
        text2 = "RUEDA GIRATORIA";
        text3 = "GANAR PREMIO";
        break;
      default:
        text1 = "SCAN QR CODE";
        text2 = "SPIN WHEEL";
        text3 = "WIN A PRIZE";
        break;
    }
    this.setState({
      text1: text1,
      text2: text2,
      text3: text3
    })
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      modified: true
    })

 
    if(target.name === "language"){
      this.setLanguage(target.value)
    } 

    this.setState({
      [name]: value
    });
  }

  render(){
    return (
      <div className="qr-generator-content">
        <div className="qr-generator-column">
         
          <div id="qr-holder">
            {
              this.state.loaded ? 
                <div className="qr-export-container" ref={this.componentRef}>

                  <div id="qr-code-container" ref={this.qrRef}>
                    
                    <QR
                        value={"https://app.weely.ch/?id=" + this.props.qrCode}
                        size={256}
                        renderAs={"svg"}
                        className="qr-code"
                      />
                  </div>
                  <QRBackground disablePatterns={this.state.disablePatterns} colorA={this.state.colorA} colorB={this.state.colorB} colorC={this.state.colorC} colorD={this.state.colorD} colorBG={this.state.colorBG} colorSteps={this.state.colorSteps}/>
                  <img src={qrStep1Icon} className="step-icon step-icon-1" alt="qr-step-1"/>
                  <img src={qrStep2Icon} className="step-icon step-icon-2" alt="qr-step-2"/>
                  <img src={qrStep3Icon} className="step-icon step-icon-3" alt="qr-step-3"/>
                  <div style={{"color": this.state.colorLabels}} className="step-text step-text-1">{this.state.text1}</div>
                  <div style={{"color": this.state.colorLabels}} className="step-text step-text-2">{this.state.text2}</div>
                  <div style={{"color": this.state.colorLabels}} className="step-text step-text-3">{this.state.text3}</div>
                  {this.state.hideLogo ? <img className="qr-wheel-logo" src={this.props.wheelLogo} alt="wheel-logo"/>: <QRLogo colorLogo={this.state.colorLogo}/>}
                  
                </div>
              : ""
            }
            

          </div>
          <div className="download-options-container">
            <div className="download-options-header">
              {this.props.texts.downloadHeader}
            </div>
            <div className="download-options-row">
              <div className="download-option-container">
                <img src={qrOnly} className="download-option-icon" alt="qr-only"/>
                <div className="download-option-header">
                {this.props.texts.qrOnly}
                </div>
                <div className="download-option-text">
                  {this.props.texts.qrText}
                </div>
                <div className="download-qr-button" onClick={() => exportComponentAsPNG(this.qrRef, params)}>
                {this.props.texts.download}
                </div>
              </div>
              <div className="download-option-container">
                <img src={qrSticker} className="download-option-icon" alt="full-sticker"/>
                <div className="download-option-header">
                 {this.props.texts.fullSticker}
                </div>
                <div className="download-option-text">
                  {this.props.texts.stickerText}
                </div>
                <div className="download-qr-button" onClick={() => exportComponentAsPNG(this.componentRef, params)}>
                  {this.props.texts.download}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="qr-options-wrapper">
          <div className="qr-options-title">{this.props.texts.header}</div>
          <div className="qr-options-container">
            <div className="qr-options-row">
              <div className="qr-color-input-label">{this.props.texts.colorA}</div> 
            <ColorInput handleInputChange={this.handleInputChange} selectedColor={this.state.colorA} name="colorA"/>
            </div>
            <div className="qr-options-row">
              <div className="qr-color-input-label">{this.props.texts.colorB}</div> 
            <ColorInput handleInputChange={this.handleInputChange} selectedColor={this.state.colorB} name="colorB"/>
            </div>
            <div className="qr-options-row">
              <div className="qr-color-input-label">{this.props.texts.colorC}</div> 
            <ColorInput handleInputChange={this.handleInputChange} selectedColor={this.state.colorC} name="colorC"/>
            </div>
            <div className="qr-options-row">
              <div className="qr-color-input-label">{this.props.texts.colorD}</div> 
            <ColorInput handleInputChange={this.handleInputChange} selectedColor={this.state.colorD} name="colorD"/>
            </div>
            <div className="qr-options-row">
              <div className="qr-color-input-label">{this.props.texts.background}</div> 
            <ColorInput handleInputChange={this.handleInputChange} selectedColor={this.state.colorBG} name="colorBG"/>
            </div>
            <div className="qr-options-row">
              <div className="qr-color-input-label">{this.props.texts.colorLogo}</div> 
            <ColorInput handleInputChange={this.handleInputChange} selectedColor={this.state.colorLogo} name="colorLogo"/>
            </div>
            <div className="qr-options-row">
              <div className="qr-color-input-label">{this.props.texts.colorNumbers}</div> 
            <ColorInput handleInputChange={this.handleInputChange} selectedColor={this.state.colorSteps} name="colorSteps"/>
            </div>
            <div className="qr-options-row">
              <div className="qr-color-input-label">{this.props.texts.colorLabels}</div> 
            <ColorInput handleInputChange={this.handleInputChange} selectedColor={this.state.colorLabels} name="colorLabels"/>
            </div>
            <div className="qr-options-underline"/>
            <div className="qr-options-row">
              <div className="qr-color-input-label">{this.props.texts.language}</div> 
              <select className="qr-language" onChange={(e)=> {this.handleInputChange(e)}} name="language" value={this.state.language}>
                <option value="en">English</option>
                <option value="fr">Français</option>
                {/* <option value="de">Deutsch</option>
                <option value="es">Español</option> */}
              </select>
            </div>
            <div className="qr-options-row">
              <div className="qr-color-input-label">{this.props.texts.disable}</div> 
              <input type="checkbox" name="disablePatterns" value={this.state.disablePatterns} onChange={(e) =>{this.handleInputChange(e)}}/>
            </div>
            <div className="qr-options-row">
              <div className="qr-color-input-label">{this.props.texts.hideLogo}</div> 
              <input type="checkbox" name="hideLogo" value={this.state.hideLogo} onChange={(e) =>{this.handleInputChange(e)}}/>
            </div>
            <div className="qr-button-row">
              <div className={this.state.modified ? "save-qr-button" : "save-qr-button save-qr-button-disabled"} onClick={() => this.saveQr()}>
                {this.props.texts.save}
              </div>
              <div className="save-qr-button save-qr-reset" onClick={() => this.resetQr()}>
                {this.props.texts.reset}
              </div>
            </div>

          </div>
        </div>
        
      </div>
    );
  }
}

export default QRCode;
