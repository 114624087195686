import "./QRCode.css";


function QRBackground(props) {
  
  return (
    <div className="qr-background-container">
      <div id="qr-background-sub-container" >
        <svg width="1139" height="1139" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnslink="http://www.w3.org/1999/xlink">
          <circle cx="569.5" cy="569.5" r="569.5" fill={props.disablePatterns ? props.colorA : props.colorB}/>
            <g id="topkek" clip-path="url(#clip0_629_1217)">
              {/* <rect width="831" height="861" fill="white"/> */}

              <path fill-rule="evenodd" clip-rule="evenodd" d="M464.913 -19.6518C254.684 -53.076 327.012 91.707 396.91 112.724C466.808 133.742 483.307 106.993 530.729 123.18C595.384 145.248 654.065 256.051 738.534 274.652C775.412 282.773 826.736 252.061 856.189 256.573C904.644 263.995 935.91 280.843 942.431 256.558C963.32 178.761 899.098 193.104 873.533 146.104C859.63 120.543 869.519 63.3233 842.056 46.7963C743.813 -12.3259 592.708 0.666335 464.913 -19.6518Z" fill={props.colorA}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M500.228 829.489C464.487 742.335 697.723 448.588 756.59 499.365C815.458 550.142 792.583 692.053 834.525 676.475C960.76 629.586 969.76 770.857 960.577 782.52C932.781 817.825 875.848 758.978 834.128 770.599C780.364 785.575 811.577 855.533 795.204 868.468C743.877 909.018 518.219 873.361 500.228 829.489Z" fill={props.colorA}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M203.407 174.903C214.858 162.318 251.077 107.062 237.332 74.7627C227.497 51.6513 169.354 53.9743 162.788 29.1644C154.367 -2.65722 277.716 -35.5524 272.582 -60.4967C264.923 -97.7087 128.849 -119.581 110.873 -112.761C58.3253 -92.8209 38.7756 9.2443 14.1344 -11.7099C-60.0309 -74.778 -34.3399 34.1219 -8.87043 52.3325C23.9523 75.8008 -14.5511 115.61 -6.62623 128.446C18.2167 168.686 180.337 200.259 203.407 174.903Z" fill={props.disablePatterns ? props.colorA : props.colorC}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M383.922 861.311C96.8554 869.994 220.638 568.601 317.943 543.54C415.247 518.478 432.264 583.556 498.642 562.238C589.141 533.174 517.437 301.644 597.039 233.953C836.624 30.2167 969.22 333.064 958.384 365.995C910.036 512.926 769.265 550.623 613.155 640.857C460.391 729.157 558.427 856.032 383.922 861.311Z" fill={props.disablePatterns ? props.colorA : props.colorD}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M107.859 -80.7307C179.59 -298.038 371.524 -123.22 363.771 -43.5331C356.018 36.1536 302.654 31.1595 300.526 86.6698C297.625 162.351 490.523 171.76 519.514 249.809C606.769 484.721 343.877 501.469 322.173 484.382C225.334 408.139 235.472 292.463 210.453 150.986C185.97 12.5402 64.2553 51.368 107.859 -80.7307Z" fill={props.disablePatterns ? props.colorA : props.colorD}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M292.278 125.891C314.461 130.727 402.475 135.715 430.629 98.283C450.774 71.499 346.781 -40.2376 372.18 -62.8118C410.071 -96.4882 582.829 -156.735 562.955 -172.956C504.855 -220.375 373.87 -174.872 381.644 -217.326C405.043 -345.105 296.343 -242.763 292.292 -201.183C287.071 -147.597 220.323 -135.785 205.835 -147.809C121.916 -217.459 68.8686 -116.384 103.353 -56.6544C122.958 -22.6977 127.605 56.3556 161.523 11.8717C191.671 -27.6664 198.588 -8.84649 206.984 17.3898C215.381 43.626 282.172 123.688 292.278 125.891Z" fill={props.colorA}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M758.184 779.097C825.328 854.371 716.293 889.11 687.583 868.588C658.873 848.066 672.541 828.796 651.82 815.75C623.569 797.963 577.464 869.524 541.282 863.389C432.378 844.925 491.079 747.994 495.23 736.361C514.983 681.011 574.027 625.378 633.547 647.008C691.792 668.174 717.368 733.338 758.184 779.097Z" fill={props.disablePatterns ? props.colorA : props.colorC}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M566.967 26.4584C484.722 -12.3408 420.77 -19.2051 371.917 46.6476C334.524 97.0543 237.212 187.611 351 249.926C418.975 287.152 422.533 272.688 443.155 305.776C463.776 338.865 447.928 381.859 460.61 408.236C497.869 485.729 567.441 551.096 617.808 522.849C685.004 485.165 656.638 68.7603 566.967 26.4584Z" fill={props.disablePatterns ? props.colorA : props.colorC}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M438.185 563.078C388.932 617.21 516.372 652.296 548.742 660.633C581.112 668.97 615.107 741.103 641.588 763.622C721.29 831.398 765.4 730.651 761.795 719.696C750.883 686.535 693.681 714.369 666.31 694.798C631.037 669.578 672.415 626.797 663.898 613.003C637.201 569.759 610.397 629.506 585.191 619.24C523.975 594.31 462.978 535.829 438.185 563.078Z" fill={props.disablePatterns ? props.colorA : props.colorB}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M-204.815 753.078C-254.068 807.21 -126.628 842.296 -94.2581 850.633C-61.8881 858.97 -27.893 931.103 -1.41228 953.622C78.2897 1021.4 122.4 920.651 118.795 909.696C107.883 876.535 50.6808 904.369 23.31 884.799C-11.9631 859.578 68.8977 821.543 32.7207 788.517C-8.36398 751.01 -32.6026 819.506 -57.8093 809.24C-119.025 784.31 -180.022 725.829 -204.815 753.078Z" fill="#FFB130"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M406.582 -121.289C303.996 -124.393 348.231 -16.6874 383.004 -7.73148C417.776 1.22442 423.857 -22.0317 447.578 -14.4136C479.918 -4.02733 454.294 78.7116 482.741 102.901C568.358 175.708 605.518 66.5601 611.87 55.7155C642.093 4.11973 675.46 -237.068 619.673 -269.314C565.081 -300.869 468.942 -119.403 406.582 -121.289Z" fill={props.disablePatterns ? props.colorA : props.colorD}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M141.681 392.437C113.264 400.21 87.7494 321.67 38.9319 287.811C-5.8432 256.756 -111.996 234.298 -92.4589 189.218C-77.5047 154.711 56.0109 108.763 105.251 140.901C154.491 173.039 116.232 238.565 133.72 237.244C185.096 233.361 235.687 182.716 271.904 237.244C300.478 280.265 205.803 374.899 141.681 392.437Z" fill={props.disablePatterns ? props.colorA : props.colorC}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M140.873 19.5598C81.3801 17.7602 107.033 80.2225 127.199 85.4164C147.365 90.6102 150.892 77.1231 164.649 81.5412C183.404 87.5646 168.544 135.548 185.041 149.577C234.694 191.8 256.244 128.501 259.928 122.212C277.455 92.2894 280.208 44.4983 247.855 25.7978C216.195 7.49805 177.039 20.6538 140.873 19.5598Z" fill={props.disablePatterns ? props.colorA : props.colorB}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1059.45 985.476C1074.57 968.858 1122.4 895.897 1104.25 853.248C1091.26 822.731 1014.49 825.798 1005.82 793.039C994.7 751.02 1157.57 707.585 1150.79 674.648C1140.68 625.512 961.004 596.63 937.269 605.637C867.883 631.966 842.069 766.736 809.532 739.067C711.602 655.79 745.525 799.585 779.156 823.63C822.496 854.619 771.655 907.184 782.119 924.133C814.923 977.266 1028.99 1018.96 1059.45 985.476Z" fill={props.colorA}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M937.675 423.881C853.108 416.103 794.795 430.505 772.958 503.898C756.243 560.075 699.771 670.625 819.53 688.965C891.071 699.922 889.573 686.092 918.238 708.542C946.903 730.992 946.728 773.784 966.282 792.88C1023.73 848.983 1105.66 884.125 1140.84 843.25C1187.77 788.717 1029.88 432.362 937.675 423.881Z" fill={props.disablePatterns ? props.colorA : props.colorC}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M338.056 872.294C188.08 752.953 362.539 673.57 421.534 704.299C480.529 735.028 465.224 770.936 507.026 790.043C564.017 816.092 699.419 757.707 764.446 800.985C792.835 819.879 793.618 884.141 814.472 901.015C848.779 928.776 882.034 935.792 863.344 962.376C803.471 1047.54 780.289 988.891 722.473 1013.18C691.03 1026.39 643.89 1085.13 613.089 1080.53C502.901 1064.07 429.225 944.839 338.056 872.294Z" fill={props.disablePatterns ? props.colorA : props.colorD}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M30.4251 234.268C67.5616 229.742 117.537 392.639 153.537 380.685C207.241 362.85 0.183484 134.657 168.302 89.8578C244.167 69.6416 262.811 441.596 308.191 457.775C444.778 506.474 285.173 554.436 242.714 535.145C187.998 510.284 139.324 568.218 142.862 588.934C163.356 708.935 36.0364 703.804 -2.45818 637.129C-24.3427 599.224 -269 572.429 -227.092 496.319C-201.244 449.377 -39.7673 545.487 -16.1982 495.62C7.371 445.753 -21.4748 240.592 30.4251 234.268Z" fill={props.colorA}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M378.102 296.466C312.221 290.406 266.792 301.626 249.78 358.802C236.759 402.567 192.765 488.689 286.062 502.978C341.796 511.513 340.629 500.739 362.96 518.229C385.291 535.718 385.154 569.055 400.388 583.932C445.143 627.639 508.97 655.015 536.375 623.172C572.937 580.689 449.931 303.073 378.102 296.466Z" fill={props.disablePatterns ? props.colorA : props.colorC}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M79.1758 515.808C70.8285 506.633 20.2965 525.792 5.67548 499.582C-11.5359 468.727 59.1523 427.9 79.1758 422.743C104.685 416.173 155.849 388.085 176.718 370.339C239.528 316.927 274.289 396.322 271.448 404.955C262.849 431.088 253.655 416.901 232.085 432.323C204.288 452.198 201.011 478.165 194.3 489.036C173.261 523.114 130.092 648.556 70.3525 635.893C19.843 625.186 98.7142 537.282 79.1758 515.808Z" fill={props.disablePatterns ? props.colorA : props.colorB}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M380.814 243.38C342.001 200.72 442.43 173.071 467.94 166.5C493.449 159.93 520.24 103.085 541.108 85.339C603.918 31.9272 638.679 111.322 635.839 119.955C627.239 146.088 582.16 124.154 560.591 139.576C532.793 159.451 565.401 193.165 558.69 204.036C537.651 238.114 516.528 191.03 496.664 199.12C448.422 218.767 400.353 264.854 380.814 243.38Z" fill={props.disablePatterns ? props.colorA : props.colorB}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M-24.9381 684.887C14.4912 666.251 93.9661 570.107 173.837 606.919C247.094 640.683 367.97 761.008 351.077 831.75C338.147 885.899 195.655 987.862 145.128 881.081C130.626 850.432 88.8766 845.453 54.6354 850.306C20.3942 855.16 0.216279 890.443 -24.9381 896.864C-98.8379 915.731 -182.922 905.727 -192.446 855.397C-205.152 788.252 -113.909 726.937 -24.9381 684.887Z" fill={props.disablePatterns ? props.colorA : props.colorC}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M523.675 772.4C538.277 756.351 584.461 685.891 566.934 644.704C554.393 615.233 480.251 618.196 471.879 586.559C461.141 545.981 618.431 504.034 611.884 472.226C602.118 424.774 428.6 396.883 405.678 405.581C338.671 431.007 313.742 561.157 282.32 534.437C187.747 454.015 220.507 592.88 252.985 616.102C294.84 646.028 245.741 696.791 255.847 713.159C287.526 764.472 494.256 804.733 523.675 772.4Z" fill={props.colorA}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M100.264 778.265C56.258 729.899 138.658 482.91 189.114 502.056C239.569 521.202 258.341 619.203 282.001 599.083C353.213 538.526 392.625 628.542 389.404 638.33C379.654 667.959 328.545 643.09 304.09 660.575C272.574 683.109 309.544 721.333 301.935 733.658C278.081 772.295 122.416 802.612 100.264 778.265Z" fill={props.disablePatterns ? props.colorA : props.colorD}/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M908.186 271.578C946.999 314.237 846.57 341.887 821.06 348.457C795.551 355.027 768.76 411.872 747.892 429.618C685.082 483.03 650.321 403.635 653.161 395.002C661.761 368.869 706.84 390.804 728.409 375.381C756.207 355.506 723.599 321.793 730.31 310.922C751.349 276.843 772.472 323.927 792.336 315.837C840.578 296.19 888.647 250.104 908.186 271.578Z" fill={props.disablePatterns ? props.colorA : props.colorB}/>
            </g>
          <mask id="a" style={{"mask-type": "Alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="1139" height="1139">
            <circle cx="569.5" cy="569.5" r="569.5" fill="#fff"/>
          </mask>
          <mask id="a" style={{"mask-type": "Alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="1139" height="1139">
            <circle cx="800.5" cy="800.5" r="800.5" fill="#fff"/>
          </mask>
          {
            !props.disablePatterns ? 
            <g mask="url(#a)">
            <path fill="url(#pattern0)" d="M-148.163-141.217h1308v1351.98h-1308z"/>
            <mask id="b" style={{"mask-type": "Alpha"}} maskUnits="userSpaceOnUse" x="793" y="349" width="475" height="360">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M838.572 607.489c80.445 2.517 92.04-206.453 175.268-238.491 250.49-96.427 266.13 188.125 247.78 211.769-81.86 105.497-204.77 95.473-355.406 123.733-147.406 27.655-126.647-98.858-67.642-97.011z" fill="#fff"/>
            </mask>
            <g mask="url(#b)" fill="#fff">
              <path opacity=".755" d="M1322.17 561.59l-4.63-8.02-447.089 258.128 4.63 8.019zM1311.76 543.546l-4.63-8.02-447.089 258.128 4.63 8.019zM1301.34 525.502l-4.63-8.02L849.621 775.61l4.63 8.019zM1290.92 507.458l-4.63-8.02-447.089 258.128 4.63 8.019zM1280.5 489.414l-4.63-8.02-447.089 258.128 4.63 8.019zM1270.09 471.37l-4.63-8.02-447.089 258.128 4.63 8.019zM1259.67 453.326l-4.63-8.02-447.089 258.128 4.63 8.019zM1249.25 435.282l-4.63-8.02L797.531 685.39l4.63 8.019zM1238.83 417.238l-4.63-8.02-447.089 258.128 4.63 8.019zM1228.42 399.194l-4.63-8.02-447.089 258.128 4.63 8.019zM1218 381.15l-4.63-8.02-447.089 258.128 4.63 8.019zM1207.58 363.106l-4.63-8.02-447.089 258.128 4.63 8.019zM1197.16 345.063l-4.63-8.02-447.089 258.128 4.63 8.019zM1186.74 327.019l-4.63-8.02-447.089 258.128 4.63 8.019zM1176.33 308.975l-4.63-8.02-447.089 258.128 4.63 8.019z"/>
            </g>
            <mask id="c" style={{"mask-type": "Alpha"}} maskUnits="userSpaceOnUse" x="145" y="-22" width="474" height="361">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M190.36 237.083C270.805 239.6 282.4 30.63 365.626-1.408 616.118-97.836 631.758 186.717 613.41 210.36c-81.864 105.497-204.774 95.472-355.408 123.733-147.406 27.654-126.646-98.858-67.642-97.011z" fill="#fff"/>
            </mask>
            <g mask="url(#c)" fill="#fff">
              <path opacity=".755" d="M673.963 191.183l-4.63-8.02-447.089 258.128 4.63 8.019zM663.546 173.139l-4.63-8.02-447.089 258.128 4.63 8.019zM653.128 155.095l-4.63-8.02-447.089 258.128 4.63 8.019zM642.71 137.052l-4.63-8.02L190.991 387.16l4.63 8.019zM632.293 119.008l-4.63-8.02-447.089 258.128 4.63 8.019zM621.875 100.964l-4.63-8.02-447.089 258.128 4.63 8.019zM611.458 82.92l-4.63-8.02-447.089 258.127 4.63 8.02zM601.04 64.876l-4.63-8.02-447.089 258.127 4.63 8.02zM590.622 46.832l-4.63-8.02L138.903 296.94l4.63 8.02zM580.204 28.788l-4.63-8.02-447.089 258.128 4.63 8.019zM569.786 10.744l-4.63-8.02-447.089 258.128 4.63 8.019zM559.369-7.3l-4.63-8.02L107.65 242.809l4.63 8.019zM548.951-25.344l-4.63-8.02L97.232 224.765l4.63 8.019zM538.533-43.388l-4.63-8.02L86.814 206.72l4.63 8.019zM528.116-61.432l-4.63-8.02L76.397 188.677l4.63 8.019z"/>
            </g>
            <mask id="d" style={{"mask-type": "Alpha"}} maskUnits="userSpaceOnUse" x="97" y="742" width="474" height="361">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M141.743 1001.05c80.446 2.51 92.041-206.457 175.266-238.495 250.492-96.427 266.132 188.125 247.785 211.769-81.865 105.496-204.775 95.476-355.408 123.736-147.406 27.65-126.647-98.86-67.643-97.01z" fill="#fff"/>
            </mask>
            <g mask="url(#d)" fill="#fff">
              <path opacity=".755" d="M625.347 955.147l-4.63-8.02-447.089 258.128 4.63 8.019zM614.929 937.103l-4.63-8.02-447.089 258.128 4.63 8.019zM604.512 919.059l-4.63-8.02-447.089 258.128 4.63 8.019zM594.094 901.015l-4.63-8.02-447.089 258.128 4.63 8.019zM583.677 882.971l-4.63-8.02-447.089 258.128 4.63 8.019zM573.259 864.927l-4.63-8.02-447.089 258.128 4.63 8.019zM562.841 846.883l-4.63-8.02-447.089 258.128 4.63 8.019zM552.424 828.839l-4.63-8.02-447.089 258.128 4.63 8.019zM542.005 810.796l-4.63-8.02-447.089 258.128 4.63 8.019zM531.588 792.751l-4.63-8.02L79.869 1042.86l4.63 8.019zM521.17 774.708l-4.63-8.02-447.089 258.128 4.63 8.019zM510.752 756.664l-4.63-8.02-447.089 258.128 4.63 8.019zM500.335 738.62l-4.63-8.02L48.616 988.729l4.63 8.019zM489.917 720.576l-4.63-8.02L38.198 970.685l4.63 8.019zM479.5 702.532l-4.63-8.02L27.781 952.64l4.63 8.019z"/>
            </g>
          </g>
          : ""
        
          }
          
          <path fill-rule="evenodd" clip-rule="evenodd" d="M560.22 116.811c3.507-.08 7.023-.121 10.548-.121 250.781 0 454.082 203.298 454.082 454.078 0 250.781-203.301 454.082-454.082 454.082-250.78 0-454.078-203.301-454.078-454.082 0-3.525.041-7.041.121-10.548-.08-3.424-.121-6.858-.121-10.302 0-239.265 193.963-433.228 433.228-433.228 3.444 0 6.878.041 10.302.121z" fill="#fff"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M559.56 142.171c3.305-.075 6.618-.113 9.94-.113 237.471 0 429.979 192.508 429.979 429.979 0 237.471-192.508 429.983-429.979 429.983-237.471 0-429.979-192.512-429.979-429.983 0-3.322.038-6.636.113-9.94a417.972 417.972 0 0 1-.113-9.71c0-226.618 183.711-410.329 410.329-410.329 3.246 0 6.483.038 9.71.113z" fill={props.colorBG}/>
          <rect x="375.439" y="395.733" width="388.122" height="388.122" rx="10" fill="#fff"/>
          <g opacity=".806">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M213.973 625.812v-30.956l-6.389 1.509v-5.477l9.389-4.19h4.389v39.114h-7.389z" fill={props.colorSteps}/>
            <path fill="url(#pattern1)" d="M232.24 576.919h46.296v65.191H232.24z"/>
          </g>
          <g opacity=".806">
            <path opacity=".788" fill="url(#pattern2)" d="M532.464 818.327h76.388v76.832h-76.388z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M505.734 871.877v-5.197a490.747 490.747 0 0 0 6.917-5.867c2.241-1.937 4.241-3.856 6-5.755 1.759-1.9 3.166-3.753 4.222-5.56 1.056-1.807 1.583-3.567 1.583-5.281 0-1.602-.435-3.017-1.305-4.246-.871-1.23-2.306-1.844-4.306-1.844-2.037 0-3.574.67-4.611 2.011-1.037 1.341-1.555 2.962-1.555 4.862h-6.889c.074-2.869.704-5.262 1.889-7.181 1.185-1.918 2.759-3.352 4.722-4.302 1.963-.95 4.167-1.425 6.611-1.425 3.963 0 7.046 1.089 9.25 3.269 2.204 2.179 3.305 5.001 3.305 8.465 0 2.161-.49 4.266-1.472 6.314-.981 2.049-2.268 4.024-3.861 5.923a51.862 51.862 0 0 1-5.167 5.337 149.953 149.953 0 0 1-5.388 4.61h16.833v5.867h-26.778z" fill={props.colorSteps}/>
          </g>
          <g opacity=".806">
            <path opacity=".902" fill="url(#pattern3)" d="M827.836 558.085h99.536v98.998h-99.536z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M827.273 627.816c-2.555 0-4.87-.451-6.944-1.354a11.348 11.348 0 0 1-5-4.172c-1.259-1.878-1.926-4.236-2-7.072h7c.037 1.878.657 3.453 1.861 4.724 1.204 1.271 2.898 1.906 5.083 1.906 2.074 0 3.667-.571 4.778-1.713 1.111-1.142 1.666-2.578 1.666-4.309 0-2.026-.731-3.564-2.194-4.614-1.463-1.05-3.342-1.575-5.639-1.575h-2.889v-5.802h2.945c1.889 0 3.463-.442 4.722-1.326 1.259-.884 1.889-2.192 1.889-3.923 0-1.436-.472-2.588-1.417-3.453-.944-.866-2.25-1.299-3.916-1.299-1.815 0-3.232.534-4.25 1.603-1.019 1.068-1.584 2.376-1.695 3.923h-6.944c.148-3.573 1.398-6.391 3.75-8.454 2.352-2.063 5.398-3.094 9.139-3.094 2.666 0 4.916.469 6.749 1.409 1.834.939 3.223 2.182 4.167 3.729.945 1.547 1.417 3.26 1.417 5.139 0 2.173-.602 4.006-1.806 5.498-1.204 1.492-2.694 2.495-4.472 3.011 2.185.442 3.963 1.501 5.333 3.177 1.371 1.676 2.056 3.785 2.056 6.327 0 2.136-.519 4.089-1.556 5.857-1.037 1.768-2.546 3.186-4.527 4.254-1.982 1.069-4.417 1.603-7.306 1.603z" fill={props.colorSteps}/>
          </g>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M357.612 858.662l-19.454-2.21 7.814-13.533 11.64 15.743z" fill="#652583"/>
          <path d="M308.062 810.345s7.363 17.791 17.967 28.45c10.604 10.659 24.448 14.187 24.448 14.187" stroke="#652583" stroke-linecap="square"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M809.195 818.171l-2.21 19.453-13.532-7.813 15.742-11.64z" fill="#652583"/>
          <path d="M760.878 867.72s17.791-7.363 28.45-17.966c10.659-10.604 14.187-24.448 14.187-24.448" stroke="#652583" stroke-linecap="square"/>
          {/* <defs>
            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref="#image0_616_1213" transform="scale(.0012)"/>
            </pattern>
            <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref="#image1_616_1213" transform="scale(.0039 .00282)"/>
            </pattern>
            <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref="#image2_616_1213" transform="scale(.00195)"/>
            </pattern>
            <pattern id="pattern3" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref="#image3_616_1213" transform="scale(.00195)"/>
            </pattern>
            </defs> */}
        </svg>
        {/* {props.disablePatterns ? <div className="qr-pattern-overlay" style={{"border-color": props.colorA}}/> : ""} */}

      </div>
    </div>
  );
}

export default QRBackground;
