import React from "react";
import lock from "../images/locked.png";
import "./FeatureLock.css";

class FeatureLock extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      showTooltip: false,
    };
  }

  handleMouseEnter = () => {
    this.setState({ showTooltip: true });
  };

  handleMouseLeave = () => {
    this.setState({ showTooltip: false });
  };

  render(){
    if(this.props.subscriptionType.includes("Free") || this.props.subscriptionType.includes("Growth")){
      return(
        <div className="lock-container">
          {"   "}<img onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} src={lock} className="lock-icon" alt="lock-icon"/>
          {this.state.showTooltip ? <div className="tooltip-text">This feature is only available for Weely Boost subscriptions</div> : ""}
        </div>
      )
    }else {
      return null;
    }
  }
}

export default FeatureLock;
